export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyBI7qQ8mRs8Kee3hmpe8SYahekFsH1nqOk",
        authDomain: "respiro-dev-72191.firebaseapp.com",
        projectId: "respiro-dev-72191",
        storageBucket: "respiro-dev-72191.appspot.com",
        messagingSenderId: "424212722290",
        appId: "1:424212722290:web:04880ccbd4ae1d5a93c8eb",
        measurementId: "G-RKVBYPCQL4"
    },
    mixpanelToken: "efd68028666100f40a64f4e70fc210d3",
    bucketName: "respiro-client-documents"
};