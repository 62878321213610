import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { Meditation } from 'src/app/models/meditation';

@Component({
  selector: 'app-meditations',
  templateUrl: './meditations.component.html',
  styleUrls: ['./meditations.component.scss']
})
export class MeditationsComponent {

  searchControl = new FormControl('');
  categoryControl = new FormControl([]);

  meditations$: Observable<Meditation[]> = this.firestoreFacade.getMeditations();
  filteredMeditations$;
  allCategories$: Observable<string[]>;

  constructor(private firestoreFacade: FirestoreFacadeService) {
    this.allCategories$ = this.meditations$.pipe(
      map(meditations =>
        meditations.reduce<string[]>((acc, meditation) =>
          [...acc, ...meditation.categories.filter(category => !acc.includes(category))], [])
      )
    );

    this.filteredMeditations$ = combineLatest([
      this.meditations$,
      this.searchControl.valueChanges.pipe(startWith(this.searchControl.value)),
      this.categoryControl.valueChanges.pipe(startWith(this.categoryControl.value))
    ]).pipe(
      map(([meditations, searchText, selectedCategories]) => {

        if (searchText != null && selectedCategories != null) {
          return meditations.filter(meditation =>
            meditation.title.toLowerCase().includes(searchText.toLowerCase()) &&
            (!selectedCategories.length ||
              selectedCategories.every(category => meditation.categories.includes(category)))
          );
        }
        return [];
      })
    );
  }


}
