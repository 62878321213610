import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InviteSentSnackbarComponent } from '../invite-sent-snackbar/invite-sent-snackbar.component';
import { DashboardStore } from '../../store/dashboard.store';
import { ClientInvitesStore } from '../../store/clientInvites.store';
import { AuthStore } from 'src/app/auth/store/auth.store';
import { take, tap } from 'rxjs';
import { EmailFacadeService } from 'src/app/facades/email-facade.service';
import { RespiroUser } from 'src/app/models/respiro-user';

@Component({
  selector: 'app-invite-client-dialog',
  templateUrl: './invite-client-dialog.component.html',
  styleUrls: ['./invite-client-dialog.component.scss']
})
export class InviteClientDialogComponent {

  email: string = '';
  durationInSeconds = 5;
  message: string = '';
  respiroUser: RespiroUser | null = null;

  constructor(private dialogRef: MatDialogRef<InviteClientDialogComponent>,
    private _snackBar: MatSnackBar, private clientInvitesStore: ClientInvitesStore,
    private authStore: AuthStore, private emailFacade: EmailFacadeService) {
      this.authStore.respiroUser$.pipe(
        take(1),
        tap(respiroUser => { 
          this.respiroUser = respiroUser;
          this.message = ``;
        })
      ).subscribe();
    }

  onInvite(): void {
    if (this.email && this.respiroUser) {
      // Implement your invite logic here, for instance, calling a service
      // to send an invitation email.

      // After invitation, clear the email input field.
      this.emailFacade.sendInviteEmail(this.email, this.respiroUser.firstName + ' ' + this.respiroUser.lastName).subscribe(response => {
        console.log('Email sent successfully:', response);
      }, error => {
        console.error('Failed to send email:', error);
      });
      this.clientInvitesStore.inviteClient({email: this.email, message: this.message});
      this.email = '';
      // this.dialogRef.close();
      this.openSnackBar();
    }
  }

  openSnackBar() {
    this._snackBar.openFromComponent(InviteSentSnackbarComponent, {
      duration: this.durationInSeconds * 1000,
    });
  }

}
