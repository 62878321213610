<div class="main-container">
    <div class="button-row">
        <button class="generate-report-button accent-button-outline" mat-button (click)="openDialog()">
            <div class="flex-container align-center">
                <span class="s-font-size">{{'clientProfile.notes.new' | translate}}</span>
            </div>
        </button>
    </div>

    <div class="notes-container" *ngIf="notes">
        <mat-card *ngFor="let note of notes" class="note-card">
            <mat-card-header>
                <mat-card-title class="note-title-container">
                    <span>{{note.date.toDate() | date:'dd MMM yyyy'}}</span>

                    <mat-icon class="edit-icon" aria-hidden="false" aria-label="Edit icon" fontIcon="edit" (click)="openDialogInEditMode(note)"></mat-icon>
                </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="preserve-newlines">{{note.note.replace('\\n', '\n')}}</mat-card-content>
        </mat-card>
    </div>
</div>
