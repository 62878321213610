<mat-dialog-content>
  <form [formGroup]="notesForm">
    <mat-form-field appearance="outline" class="notes-field">
      <mat-label>{{'addNote.note' | translate}}</mat-label>
      <textarea matInput formControlName="note" cdkTextareaAutosize cdkAutosizeMinRows="10"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{'addNote.cancel' | translate}}</button>
  <button mat-button (click)="save()">{{'addNote.save' | translate}}</button>
</mat-dialog-actions>