<div class="main-container">
    <div class="adaptive-behaviours-col">
        <div class="chart-container">
            <span class="chart-title">Categories of functional behaviours associated with positive moods</span>
            <div echarts [options]="adaptivePositiveOptions" [merge]="mergeAdaptivePositive" class="demo-chart"></div>
        </div>
        <div class="chart-container">
            <span class="chart-title">Categories of functional behaviours associated with negative moods</span>
            <div echarts [options]="adaptiveNegativeOptions" [merge]="mergeAdaptiveNegative" class="demo-chart"></div>
        </div>
    </div>
    <div class="inadaptive-behaviours-col">
        <div class="chart-container">
            <span class="chart-title">Categories of dysfunctional behaviours associated with positive moods</span>
            <div echarts [options]="inadaptivePositiveOptions" [merge]="mergeInadaptivePositive" class="demo-chart"></div>
        </div>
        <div class="chart-container">
            <span class="chart-title">Categories of dysfunctional behaviours associated with negative moods</span>
            <div echarts [options]="inadaptiveNegativeOptions" [merge]="mergeInadaptiveNegative" class="demo-chart"></div>
        </div>
    </div>
</div>
