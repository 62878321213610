<div class="main-container">
    <div class="mood-check-info-container" *ngIf="moodCheck$ | async as moodCheck">
        <div class="title-and-button-container">
            <span class="title-text" [ngStyle]="{ color: feelingToColor.get(moodCheck.feeling.feeling) }">{{ 'mood.' + moodCheck.feeling.feeling | translate }}</span>
            <!-- <div class="download-pdf-button-container">
                <button class="generate-report-button primary-button" mat-button>
                    <div class="flex-container align-center">
                        <mat-icon aria-hidden="false" aria-label="Calendar icon" fontIcon="download"></mat-icon>
                        <span class="s-font-size">Download PDF</span>
                    </div>
                </button>
            </div> -->
        </div>

        <div class="date-info-container">
            <mat-icon aria-hidden="false" aria-label="Calendar icon" fontIcon="calendar_month"></mat-icon>
            <span class="date-text">{{getDate(moodCheck.date) | date: 'dd-MM-yyyy'}}</span>
            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="access_time"></mat-icon> 
            <span class="time-text">{{getDate(moodCheck.date) | date: 'HH:mm'}}</span>
        </div>

        <div class="factors-container" *ngIf="moodCheck.factors.length > 0">
            <div class="factors-title-container">
                {{ 'mood.factors' | translate }}
            </div>
            <div class="factors-chips-container">
                <div class="factor-chip" *ngFor="let factor of moodCheck.factors">
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="{{factorsIcons.get(factor)}}" class="factor-icon"></mat-icon> 
                    <span class="factor-chip-text">{{ 'mood.' + factor | translate }}</span>
                </div>
            </div>
        </div>

        <div class="thoughts-container" *ngIf="moodCheck.thoughts.length > 0">
            <div class="thoughts-title-container">
                {{ 'mood.thoughts' | translate }}
            </div>
            <div class="thoughts-chips-container">
                <div class="thought-chip" *ngFor="let thought of moodCheck.thoughts">
                    {{ 'mood.' + thought.thought| translate }}
                </div>
            </div>
        </div>

        <div class="thoughts-container">
            <div class="thoughts-title-container">
                {{ 'mood.associatedBehaviours' | translate }}
            </div>
            <div class="thoughts-chips-container">
                <div class="thought-chip" *ngFor="let behaviour of filteredBehaviours$ | async">
                    {{behaviour.text}}
                </div>
            </div>
        </div>

        <!-- <div class="behaviour-categories-container">
            <div class="bevahiour-categories-title">
                Behaviour categories
            </div>
            <div class="behaviour-categories-info-container">
                <mat-icon aria-hidden="false" aria-label="Info icon" fontIcon="info_outline" class="info-icon"></mat-icon> 
                The client's behaviors were automatically classified into the following categories. By selecting the category, you can see the behaviours that fall into this category.
            </div>
            <div class="behaviour-categories-chips-container">
                <div [ngClass]="{'active-behaviour-chip': selectedCategory === category}" class="behaviour-category-chip" *ngFor="let category of getUniqueCategories(moodCheck.behaviours)" (click)="addCategoryFilter(category)">
                    {{category}}
                </div>
            </div>
        </div> -->
    </div>
</div>
