import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { ClientInvite, ClientInvitesState } from "./clientInvites.interface";
import { AuthStore } from "src/app/auth/store/auth.store";
import { FirestoreFacadeService } from "src/app/facades/firestore-facade.service";
import { Observable, catchError, filter, map, of, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs";
import { MixpanelService } from "src/app/core/services/mixpanel.service";



@Injectable()
export class ClientInvitesStore extends ComponentStore<ClientInvitesState> {

    constructor(
        private authStore: AuthStore,
        private firestoreFacade: FirestoreFacadeService,
        private mixpanelService: MixpanelService
    ) {
        super({ clientInvites: [],  error: null, loading: false });
    }

    readonly clientInvites$ = this.select(state => state.clientInvites)
    .pipe(
      startWith([]),  // Starts with an empty array
      map(data => data || [])
    );

    initData() {
        this.authStore.respiroUser$.pipe(
            filter(therapist => !!therapist),
            take(1),
            tap(therapist => {
                if (therapist) {
                    this.firestoreFacade.getClientInvites(therapist.id).pipe(
                        take(1),
                        tap(data => {
                            this.patchState({clientInvites: data});
                        })
                    ).subscribe();
                }
            }),
        ).subscribe();
    }

    readonly inviteClient = this.effect((invite$: Observable<{
        email: string, message: string
    }>) => {
            return invite$.pipe(
                withLatestFrom(this.authStore.respiroUser$),
                switchMap(([invite, therapist]) => {
                    if(therapist) {
                        return this.firestoreFacade.inviteClient(invite.email, therapist, invite.message).pipe(
                            tap( sentInvite => {
                                this.mixpanelService.track('invite-sent', {
                                    therapistId: therapist.id,
                                    clientEmail: invite.email
                                  });
                                this.patchState((state) => {
                                    return {
                                        ...state,
                                        clientInvites: [...state.clientInvites, sentInvite]
                                    };
                                });
                            }),
                            catchError(error => {
                                this.patchState({ error: error, loading: false });
                                return of(null);
                            })
                        );
                    }
                    return of(null);
                }),
                catchError(error => {
                    this.patchState({error: error, loading: false});
                    return of(null);
                })
            );
        }
    )

}