import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientProfile } from 'src/app/models/client-profile';
import { EditEssentialsDialogComponent } from '../edit-essentials-dialog/edit-essentials-dialog.component';
import * as echarts from 'echarts';
import { generateShadesOfGreen, generateShadesOfOrange } from 'src/app/core/utils/color-utils';
import { MoodCheck } from 'src/app/models/mood-check';
import { Observable } from 'rxjs';
import { Feeling, FeelingType } from 'src/app/core/constants/mood-check-feelings';
import { TranslateService } from '@ngx-translate/core';
import { RemoveClientDialogComponent } from '../remove-client-dialog/remove-client-dialog.component';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-essentials-tab',
  templateUrl: './essentials-tab.component.html',
  styleUrls: ['./essentials-tab.component.scss']
})
export class EssentialsTabComponent {

  @Input() clientProfile!: ClientProfile;
  @Input() moodChecks!: Observable<MoodCheck[]>

  @ViewChild('negativeChart') negativeChart!: ElementRef;
  @ViewChild('positiveChart') positiveChart!: ElementRef;

  chartOption: echarts.EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '1%',
      left: 'center'
    },
    yAxis: {
      data: [
        'Envy',
        'Fury',
        'Anxiety',
        'Concern',
        'Jealousy',
        'Others'
      ]
    },
    xAxis: {},
    series: [
      {
        name: 'Negative Emotions',
        type: 'bar',
        data: [
          1048,
          735,
          580,
          484,
          300,
          100
        ]
      }
    ],
    grid: {
      left: '10%',
      right: '10%',
      bottom: '10%',
      top: '10%',
      containLabel: true
    },
    color: "#7678ED"
  };

  chartOption2: echarts.EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '1%',
      left: 'center'
    },
    yAxis: {
      data: [
        'Envy',
        'Fury',
        'Anxiety',
        'Concern',
        'Jealousy',
        'Others'
      ]
    },
    xAxis: {},
    series: [
      {
        name: 'Positive Emotions',
        type: 'bar',
        data: [
          1048,
          735,
          580,
          484,
          300,
          100
        ]
      }
    ],
    grid: {
      left: '10%',
      right: '10%',
      bottom: '10%',
      top: '10%',
      containLabel: true
    },
    color: "#0096C7"
  };

  mergeOptionPositive: any;
  mergeOptionNegative: any;


  constructor(public dialog: MatDialog,
              public translate: TranslateService,
              public router: Router) {}

  ngOnInit() {
    this.moodChecks.subscribe((data) => {
      this.computeFeelingsData(data);
    })
  }

  openDialog() {
    const dialogRef = this.dialog.open(EditEssentialsDialogComponent, {
      width: '80vw',
      data: this.clientProfile
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openRemoveClientDialog() {
    const dialogRef = this.dialog.open(RemoveClientDialogComponent, {
      width: '80vw',
      data: this.clientProfile
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleted == true) {
        this.router.navigate(['/panel/home']);
      }
    });
  }

  computeFeelingsData(data: MoodCheck[]) {
    if (this.moodChecks) {
      let positiveFeelingsMap: Map<string, number> = new Map();
      let negativeFeelingsMap: Map<string, number> = new Map();
      for (let moodCheck of data) {
        if (moodCheck.feeling.feelingType === FeelingType.POSITIVE) {
          const currentFeelingCount = positiveFeelingsMap.get(moodCheck.feeling.feeling);
          if (!!currentFeelingCount) {
            positiveFeelingsMap.set(moodCheck.feeling.feeling, currentFeelingCount + 1);
          } else {
            positiveFeelingsMap.set(moodCheck.feeling.feeling, 1);
          }
        } else {
          const currentFeelingCount = negativeFeelingsMap.get(moodCheck.feeling.feeling);
          if (!!currentFeelingCount) {
            negativeFeelingsMap.set(moodCheck.feeling.feeling, currentFeelingCount + 1);
          } else {
            negativeFeelingsMap.set(moodCheck.feeling.feeling, 1);
          }
        }
      }

      const positiveFeelingsValues = [];
      const positiveFeelingsNames = [];
      for (let [name, value] of positiveFeelingsMap.entries()) {
        positiveFeelingsNames.push(this.translate.instant('mood.' + name));
        positiveFeelingsValues.push(value);
      }

      // Update the data property of the series array
      this.mergeOptionPositive = {
        yAxis: {
          data: positiveFeelingsNames
        },
        series: [
          {
            name: 'Stări Optime',
            type: 'bar',
            data: positiveFeelingsValues
          }
        ]
      };

      const negativeFeelingsValues = [];
      const negativeFeelingsNames = [];
      for (let [name, value] of negativeFeelingsMap.entries()) {
        
        negativeFeelingsNames.push(this.translate.instant('mood.' + name));
        negativeFeelingsValues.push(value);
      }

      // Update the data property of the series array
      this.mergeOptionNegative = {
        yAxis: {
          data: negativeFeelingsNames
        },
        series: [
          {
            name: 'Stări Dificile',
            type: 'bar',
            data: negativeFeelingsValues
          }
        ]
      };
    }
  }



}
