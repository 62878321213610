import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ConfirmPasswordValidator(passwordField: string, confirmPasswordField: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const password = control.get(passwordField);
    const confirmPassword = control.get(confirmPasswordField);
    if (password && confirmPassword && password.value !== confirmPassword.value) {
      return { 'mismatch': true };
    }
    return null;
  };
}