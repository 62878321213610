import { Component, Input } from '@angular/core';
import { ClientProfile } from 'src/app/models/client-profile';
import { HomeworkStore } from '../../store/homework.store';
import { Exercise, Homework } from 'src/app/models/homework';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { catchError, of, tap } from 'rxjs';
import { EmailFacadeService } from 'src/app/facades/email-facade.service';
import { Router } from '@angular/router';
import { Timestamp } from "@firebase/firestore";

@Component({
  selector: 'app-homework-tab',
  templateUrl: './homework-tab.component.html',
  styleUrls: ['./homework-tab.component.scss']
})
export class HomeworkTabComponent {

  @Input() clientProfile!: ClientProfile;
  @Input() homeworks: Homework[] | null = [];

  constructor(private homeworkStore: HomeworkStore, private firestoreFacade: FirestoreFacadeService,
    private emailFacade: EmailFacadeService, private router: Router) {}

  addNewHomework() {
    this.homeworkStore.createHomework(this.clientProfile.clientId);
  }

  viewHomework(homeworkId: string | undefined) {
    this.router.navigate([`panel/homework/${homeworkId}`]);
  }

  editHomework(homework: Homework) {
    this.homeworkStore.editHomework(homework);
  }


  getDate(date: any) {
    return date.toDate();
  }

  sendHomework(homework: Homework, email: string | undefined) {
    homework.isShared = true;
    this.firestoreFacade.updateHomework(homework).pipe(
      tap(data => {
        this.emailFacade.sendEmail(email, homework)
          .subscribe(response => {
            console.log('Email sent successfully:', response);
          }, error => {
            console.error('Failed to send email:', error);
          });

      }),
      catchError(error => {
        homework.isShared = false;
        return of(null);
      })
    ).subscribe();
  }

  getCompletedPercent(exercises: Exercise[]) {
    let totalExercises = exercises.length;
    if (totalExercises === 0) return 100;

    let completedExercises = 0;

    for (let exercise of exercises) {
      if (exercise.done) completedExercises++;
    }

    return Math.round((completedExercises/totalExercises) * 100);
  }

  getDeadlineStatus(deadline: Timestamp): string {
    if (deadline.toDate() < new Date()) {
      return 'Deadline Passed';
    }

    return '';
  }

}
