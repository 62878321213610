import { Component } from '@angular/core';

@Component({
  selector: 'app-activation-message',
  templateUrl: './activation-message.component.html',
  styleUrls: ['./activation-message.component.scss']
})
export class ActivationMessageComponent {

}
