<!-- 
<div *ngIf="currentStep === 1">
    <app-general-info></app-general-info>
</div>

<div *ngIf="currentStep === 2">
</div>

<div class="step-controls">
    <button *ngIf="currentStep > 1" (click)="prevStep()">Previous</button>
    <button *ngIf="currentStep < maxStep" (click)="nextStep()">Next</button>
    <button *ngIf="currentStep === maxStep" (click)="submit()">Submit</button>
</div> -->

<div class="main-container" *ngIf="!loading">
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="form-container">

        <!-- Profile Picture -->
        <div class="profile-picture-container" (click)="fileInput.click()">
            <img [src]="imagePreview" alt=" " class="profile-picture">
            <mat-icon class="edit-icon">edit</mat-icon>
            <input #fileInput accept="image/*" type="file" (change)="onFileChange($event)" style="display:none"/>

            <!-- <div class="row" style="margin-top: 15px;">
                <div class="text-center col-md-8">
                    <h5>Crop Image</h5>
                    <image-cropper 
                    [imageChangedEvent]="imgChangeEvt" 
                    [maintainAspectRatio]="true" 
                    [aspectRatio]="4 / 4"
                    [resizeToWidth]="256" 
                    format="png" 
                    (imageCropped)="cropImg($event)" 
                    (imageLoaded)="imgLoad()"
                    (cropperReady)="initCropper()" 
                    (loadImageFailed)="imgFailed()"></image-cropper>
                </div>
                <div class="text-center col-md-4">
                    <h5>Preview</h5>
                    <img [src]="cropImgPreview" />
                </div>
            </div> -->

        </div>

        <div class="name-container">
            <!-- First Name -->
            <mat-form-field appearance="outline" class="first-name-input">
                <mat-label>{{ 'firstName' | translate }}</mat-label>
                <input matInput formControlName="firstName">
            </mat-form-field>

            <!-- Last Name -->
            <mat-form-field appearance="outline" class="last-name-input">
                <mat-label>{{ 'lastName' | translate }}</mat-label>
                <input matInput formControlName="lastName">
            </mat-form-field>
        </div>


        <div class="experience-container">
            <!-- Experience -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'experience' | translate }}</mat-label>
                <input matInput type="number" formControlName="experience">
            </mat-form-field>
        </div>

        <div class="title-container">
            <!-- Title -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'profession' | translate }}</mat-label>
                <input matInput formControlName="title">
            </mat-form-field>
        </div>

        <div class="title-container">
            <!-- City -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'city' | translate }}</mat-label>
                <input matInput formControlName="city">
            </mat-form-field>
        </div>

        <div class="title-container w-80">
            <!-- City -->
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ 'description' | translate }}</mat-label>
                <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
        </div>

        <div class="buttons-container">
            <button mat-button type="submit" class="primary-button">{{ 'update' | translate }}</button>
        </div>
    </form>
</div>