import { Component, Input } from '@angular/core';
import { feelingToColor } from 'src/app/core/constants/mood-check-feelings';
import { MoodCheck } from 'src/app/models/mood-check';

@Component({
  selector: 'app-mood-checks',
  templateUrl: './mood-checks.component.html',
  styleUrls: ['./mood-checks.component.scss']
})
export class MoodChecksComponent {

  @Input() moodChecks: MoodCheck[] | null = [];
  feelingToColor = feelingToColor;


  getDate(millis: number) {
    return new Date(millis);
  }
}
