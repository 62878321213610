import { Homework } from "src/app/models/homework";

export interface HomeworkState {
    homework: Homework | null;
    status: HomeworkStatus;
}

export enum HomeworkStatus {
    DRAFT="Draft",
    SAVED="Saved"
}