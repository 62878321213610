<div class="main-container">
    <div class="filters-container">
        <!-- Search bar -->
        <div class="search-bar">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Search</mat-label>
                <input matInput [formControl]="searchControl" placeholder="Search meditations...">
            </mat-form-field>
        </div>

        <!-- Categories dropdown -->
        <div class="categories-dropdown">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Categories</mat-label>
                <mat-select [formControl]="categoryControl" multiple>
                    <mat-option *ngFor="let category of allCategories$ | async" [value]="category">
                        {{ category }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    
    <div class="meditation-container" *ngFor="let meditation of filteredBreathings$ | async">
        <div class="meditation-title">
            {{meditation.title}}
        </div>

        <div class="narrator-container">
            <!-- <div class="narrator-image">
                <img class="profile-image" [src]="meditation.">
            </div> -->
            <!-- <div class="narrator-name">
                {{meditation.narrator}}
            </div> -->
        </div>

        <div class="description">
            {{meditation.description}}
        </div>

        <div class="categories-container">
            <div class="category-chip" *ngFor="let category of meditation.categories">
                {{category}}
            </div>
        </div>

        <!-- <div>
            <audio controls *ngIf="meditation.recordingUrl">
                <source [src]="meditation.recordingUrl" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>              
        </div> -->
    </div>
</div>