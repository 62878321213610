<h2 mat-dialog-title>{{'cropImage' | translate}}</h2>
<mat-dialog-content class="image-cropper-container">
  
    <image-cropper 
        [imageChangedEvent]="imageFile" 
        [maintainAspectRatio]="true" 
        [aspectRatio]="4 / 4"
        [resizeToWidth]="256"
        format="png" 
        (imageCropped)="cropImg($event)" 
        (imageLoaded)="imgLoad()"
        (cropperReady)="initCropper()" 
        (loadImageFailed)="imgFailed()">
    </image-cropper>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'addNote.cancel' | translate}}</button>
    <button mat-button (click)="save()">{{'addNote.save' | translate}}</button>
</mat-dialog-actions>