<h2 mat-dialog-title>{{'inviteClient.title' | translate}}</h2>
<mat-dialog-content class="invite-details-container">
  <mat-form-field appearance="outline" class="mt-1">
    <mat-label>{{'inviteClient.email' | translate}}</mat-label>
    <input matInput [(ngModel)]="email">
  </mat-form-field>

  <mat-form-field appearance="outline" class="message-form-field">
    <mat-label>{{'inviteClient.message' | translate}}</mat-label>
    <textarea matInput [(ngModel)]="message"></textarea>
  </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onInvite()">{{'inviteClient.invite' | translate}}</button>
</mat-dialog-actions>
