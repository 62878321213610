import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DashboardStore } from 'src/app/dashboard/store/dashboard.store';
import { HomeworkStore } from 'src/app/dashboard/store/homework.store';
import { ClientProfile } from 'src/app/models/client-profile';

@Component({
  selector: 'app-clients-summary',
  templateUrl: './clients-summary.component.html',
  styleUrls: ['./clients-summary.component.scss']
})
export class ClientsSummaryComponent {

  @Input() clientProfiles$!: Observable<ClientProfile[]>;

  constructor(private router: Router, private homeworkStore: HomeworkStore,
    private dashboardStore: DashboardStore) {

  }

  onAddHomework(clientId: string) {
    this.homeworkStore.createHomework(clientId);
  }

  onClientCardClick(clientProfile: ClientProfile) {
    // this.dashboardStore.changeSelectedClientProfile(clientProfile);
  }

}
