export enum Factors {
    WORK = "work",
    FAMILY = "family",
    FRIENDS = "friends",
    RELATIONSHIP = "relationship",
    MONEY = "money",
    EDUCATION = "education",
    LEISURE_TIME = "leisure-time",
    SOCIALIZATION = "socialization",
    HEALTH = "health",
    REST = "rest",
    CAREER = "career",
    SOCIAL_MEDIA = "social-media",
    SELF_IMAGE = "self-image",
    FUTURE_PERSPECTIVE = "future-perspective",
}

export const factorsArray: Array<Factors> = [
    Factors.WORK,
    Factors.FAMILY,
    Factors.FRIENDS,
    Factors.RELATIONSHIP,
    Factors.MONEY,
    Factors.EDUCATION,
    Factors.LEISURE_TIME,
    Factors.SOCIALIZATION,
    Factors.HEALTH,
    Factors.REST,
    Factors.CAREER,
    Factors.SOCIAL_MEDIA,
    Factors.SELF_IMAGE,
    Factors.FUTURE_PERSPECTIVE,
    Factors.REST,
];

export const factorIcons: Map<Factors, string> = new Map([    
    [Factors.WORK, 'work'],
    [Factors.FAMILY, 'family_restroom'],
    [Factors.FRIENDS, 'people'],
    [Factors.RELATIONSHIP, 'favorite'],
    [Factors.MONEY, 'attach_money'],
    [Factors.EDUCATION, 'menu_book'],
    [Factors.LEISURE_TIME, 'access_time'],
    [Factors.SOCIALIZATION, 'message'],
    [Factors.HEALTH, 'health_and_safety'],
    [Factors.CAREER, 'business_center'],
    [Factors.SOCIAL_MEDIA, 'smartphone'],
    [Factors.SELF_IMAGE, 'person'],
    [Factors.FUTURE_PERSPECTIVE, 'wallpaper'],
    [Factors.REST, 'bed']

]);
