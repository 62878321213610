import { Component, Input } from '@angular/core';
import { ClientProfile } from 'src/app/models/client-profile';
import { MoodCheck } from 'src/app/models/mood-check';

@Component({
  selector: 'app-mood-tab',
  templateUrl: './mood-tab.component.html',
  styleUrls: ['./mood-tab.component.scss']
})
export class MoodTabComponent {

  @Input() clientProfile!: ClientProfile;
  @Input() moodChecks: MoodCheck[] | null = [];

  constructor() {}

}
