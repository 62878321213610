<div class="main-container">
    <mat-table [dataSource]="clientInvites" class="mat-elevation-z8">

        <ng-container matColumnDef="clientEmail">
            <mat-header-cell *matHeaderCellDef> {{'invites.clientEmail' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.clientEmail}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> {{'invites.status' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div [ngClass]="{
                    'pending': element.status === 'Pending',
                    'accepted': element.status === 'Accepted',
                    'rejected': element.status === 'Rejected'
                }">
                    {{element.status | translate}}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sentOn">
            <mat-header-cell *matHeaderCellDef> {{'invites.sentOn' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.sentOn.toDate() | date:'dd-MM-yyyy'}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>