import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { ClientNote } from 'src/app/models/client-note';
import { Timestamp } from '@firebase/firestore';

@Component({
  selector: 'app-add-note-dialog',
  templateUrl: './add-note-dialog.component.html',
  styleUrls: ['./add-note-dialog.component.scss']
})
export class AddNoteDialogComponent {
  notesForm: FormGroup;

  constructor(private fb: FormBuilder, private firestoreFacade: FirestoreFacadeService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddNoteDialogComponent>,) {

    if (this.data.note) {
      this.notesForm = this.fb.group({
        note: data.note.note
      });
    } else {
      this.notesForm = this.fb.group({
        note: ''
      });
    }
    
  }

  ngOnInit(): void {}

  save() {
    const note = this.notesForm.get('note')?.value;

    let clientNote: ClientNote = {
      note: note,
      date: Timestamp.fromDate(new Date()),
      id: ''
    };

    if (this.data.note) {
      clientNote = {
        note: note,
        date: this.data.note.date,
        id: this.data.note.id
      };
    }
    
    
    this.firestoreFacade.addClientNote(clientNote, this.data.clientProfileId)
      .subscribe();

    this.dialogRef.close();

    // Save the notes or handle them as you wish
    // Close the dialog after saving
  }
}
