<div class="auth-container">
  <div class="color-half">
    <img class="login-img" src="../../../assets/login-img.png">
  </div>
  <div class="login-half">
    <ng-container *ngIf="loginMode; else register">
      <app-login (loginMode)="switchMode($event)"></app-login>
    </ng-container>
    <ng-template #register>
      <app-register (loginMode)="switchMode($event)"></app-register>
    </ng-template>
  </div>
</div>