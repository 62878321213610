import { Component, Input } from '@angular/core';
import { Observable, map, take, tap } from 'rxjs';
import { HomeworkStore } from 'src/app/dashboard/store/homework.store';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { ClientProfile } from 'src/app/models/client-profile';
import { Homework } from 'src/app/models/homework';

@Component({
  selector: 'app-emotions-summary',
  templateUrl: './emotions-summary.component.html',
  styleUrls: ['./emotions-summary.component.scss']
})
export class EmotionsSummaryComponent {

  @Input() homeworks$!: Observable<Homework[]>;
  @Input() clientProfiles!: ClientProfile[] | null;

  sortedHomeworks$ !: Observable<Homework[]>;

  constructor(private firestoreFacade: FirestoreFacadeService, private homeworkStore: HomeworkStore) {}

  ngOnInit() {
    this.sortedHomeworks$ = this.homeworks$.pipe(
      map(homeworks => homeworks.sort((a: any, b: any) => b.deadline.seconds - a.deadline.seconds))
    );
  }

  getClientName(clientId: string): string | undefined {
    const clientProfile: ClientProfile | undefined = this.clientProfiles?.find(clientProfile => clientProfile.clientId === clientId);
    return clientProfile?.firstName + ' ' + clientProfile?.lastName;
  }

  onAddHomework() {
    this.homeworkStore.createHomeworkNoClient();
  }

}
