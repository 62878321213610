export type behaviouralCategory = {
    category: BehaviouralCategories;
    type: BehavioursCategoriesTypes;
  };
  ​
  export enum BehaviouralCategories {
    DEZVOLTARE_PERSONALA = "Dezvoltare personala",
    DEZVOLTARE_PROFESEIONALA = "Dezvoltare profesionala",
    RELATII_PERSONALE = "Relatii personale",
    SIGURANTA_SANATATE_SI_INGRIJIRE_PERSONALA = "Siguranta, sanatate si ingrijire personala",
    ODIHNA_SI_RELAXARE = "Odihna si relaxare",
    SPORT_SI_MISCARE = "Sport si miscare",
    EDUCATIE_SI_DEZVOLTARE_INTELECTUALA = "Educatie si dezvoltare intelectuala",
    EXPLORARE_SI_EXPERIENTE_NOI = "Explorare si experiente noi",
    SUPRAINCARCARE = "Supraincarcare",
    DETASARE_DE_MUNCA_SI_ABSENTISM = "Detasare de munca si absentism",
    EVITAREA_EXPERIENTELOR = "Evitarea experientelor",
    IZOLARE_SOCIALA_SI_SEDENTARISM = "Izolare sociala si sedentarism",
    AGRESIVITATE_SAU_COMPORTAMENTE_VIOLENTE = "Agresivitate sau comportamente violente",
    AUTOCRITICA_AUTOVATAMARE_SAU_AUTOPEDEPSIRE = "Autocritica, autovatamare sau autopedepsire",
    COMPORTAMENTE_DE_RISC = "Comportamente de risc",
    COMPORTAMENT_NESANATOS = "Comportament nesanatos",
    INVALID = "Invalid",
  }
  ​
  export const behaviouralCategoriesArray = [
    BehaviouralCategories.DEZVOLTARE_PERSONALA,
    BehaviouralCategories.DEZVOLTARE_PROFESEIONALA,
    BehaviouralCategories.RELATII_PERSONALE,
    BehaviouralCategories.SIGURANTA_SANATATE_SI_INGRIJIRE_PERSONALA,
    BehaviouralCategories.ODIHNA_SI_RELAXARE,
    BehaviouralCategories.SPORT_SI_MISCARE,
    BehaviouralCategories.EDUCATIE_SI_DEZVOLTARE_INTELECTUALA,
    BehaviouralCategories.EXPLORARE_SI_EXPERIENTE_NOI,
    BehaviouralCategories.SUPRAINCARCARE,
    BehaviouralCategories.DETASARE_DE_MUNCA_SI_ABSENTISM,
    BehaviouralCategories.EVITAREA_EXPERIENTELOR,
    BehaviouralCategories.IZOLARE_SOCIALA_SI_SEDENTARISM,
    BehaviouralCategories.AGRESIVITATE_SAU_COMPORTAMENTE_VIOLENTE,
    BehaviouralCategories.AUTOCRITICA_AUTOVATAMARE_SAU_AUTOPEDEPSIRE,
    BehaviouralCategories.COMPORTAMENTE_DE_RISC,
    BehaviouralCategories.COMPORTAMENT_NESANATOS,
    BehaviouralCategories.INVALID,
  ];
  ​
  export enum BehavioursCategoriesTypes {
    ADAPTIVE = "ADATIVE",
    MALADAPTIVE = "MALADAPTIVE",
  }
  ​
  export const behaviouralCategories: behaviouralCategory[] = [
    {
      category: BehaviouralCategories.DEZVOLTARE_PERSONALA,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    {
      category: BehaviouralCategories.DEZVOLTARE_PROFESEIONALA,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    {
      category: BehaviouralCategories.RELATII_PERSONALE,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    {
      category: BehaviouralCategories.SIGURANTA_SANATATE_SI_INGRIJIRE_PERSONALA,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    {
      category: BehaviouralCategories.ODIHNA_SI_RELAXARE,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    {
      category: BehaviouralCategories.SPORT_SI_MISCARE,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    {
      category: BehaviouralCategories.EDUCATIE_SI_DEZVOLTARE_INTELECTUALA,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    {
      category: BehaviouralCategories.EXPLORARE_SI_EXPERIENTE_NOI,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    {
      category: BehaviouralCategories.SUPRAINCARCARE,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    {
      category: BehaviouralCategories.DETASARE_DE_MUNCA_SI_ABSENTISM,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    {
      category: BehaviouralCategories.EVITAREA_EXPERIENTELOR,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    {
      category: BehaviouralCategories.IZOLARE_SOCIALA_SI_SEDENTARISM,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    {
      category: BehaviouralCategories.AGRESIVITATE_SAU_COMPORTAMENTE_VIOLENTE,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    {
      category: BehaviouralCategories.AUTOCRITICA_AUTOVATAMARE_SAU_AUTOPEDEPSIRE,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    {
      category: BehaviouralCategories.COMPORTAMENTE_DE_RISC,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    {
      category: BehaviouralCategories.COMPORTAMENT_NESANATOS,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    {
      category: BehaviouralCategories.INVALID,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
  ];
  ​
  export const behaviouralCategoriesRecord: Record<
    BehaviouralCategories,
    {
      category: BehaviouralCategories;
      type: BehavioursCategoriesTypes;
    }
  > = {
    [BehaviouralCategories.DEZVOLTARE_PERSONALA]: {
      category: BehaviouralCategories.DEZVOLTARE_PERSONALA,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    [BehaviouralCategories.DEZVOLTARE_PROFESEIONALA]: {
      category: BehaviouralCategories.DEZVOLTARE_PROFESEIONALA,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    [BehaviouralCategories.RELATII_PERSONALE]: {
      category: BehaviouralCategories.RELATII_PERSONALE,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    [BehaviouralCategories.SIGURANTA_SANATATE_SI_INGRIJIRE_PERSONALA]: {
      category: BehaviouralCategories.SIGURANTA_SANATATE_SI_INGRIJIRE_PERSONALA,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    [BehaviouralCategories.ODIHNA_SI_RELAXARE]: {
      category: BehaviouralCategories.ODIHNA_SI_RELAXARE,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    [BehaviouralCategories.SPORT_SI_MISCARE]: {
      category: BehaviouralCategories.SPORT_SI_MISCARE,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    [BehaviouralCategories.EDUCATIE_SI_DEZVOLTARE_INTELECTUALA]: {
      category: BehaviouralCategories.EDUCATIE_SI_DEZVOLTARE_INTELECTUALA,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    [BehaviouralCategories.EXPLORARE_SI_EXPERIENTE_NOI]: {
      category: BehaviouralCategories.EXPLORARE_SI_EXPERIENTE_NOI,
      type: BehavioursCategoriesTypes.ADAPTIVE,
    },
    [BehaviouralCategories.SUPRAINCARCARE]: {
      category: BehaviouralCategories.SUPRAINCARCARE,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    [BehaviouralCategories.DETASARE_DE_MUNCA_SI_ABSENTISM]: {
      category: BehaviouralCategories.DETASARE_DE_MUNCA_SI_ABSENTISM,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    [BehaviouralCategories.EVITAREA_EXPERIENTELOR]: {
      category: BehaviouralCategories.EVITAREA_EXPERIENTELOR,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    [BehaviouralCategories.IZOLARE_SOCIALA_SI_SEDENTARISM]: {
      category: BehaviouralCategories.IZOLARE_SOCIALA_SI_SEDENTARISM,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    [BehaviouralCategories.AGRESIVITATE_SAU_COMPORTAMENTE_VIOLENTE]: {
      category: BehaviouralCategories.AGRESIVITATE_SAU_COMPORTAMENTE_VIOLENTE,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    [BehaviouralCategories.AUTOCRITICA_AUTOVATAMARE_SAU_AUTOPEDEPSIRE]: {
      category: BehaviouralCategories.AUTOCRITICA_AUTOVATAMARE_SAU_AUTOPEDEPSIRE,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    [BehaviouralCategories.COMPORTAMENTE_DE_RISC]: {
      category: BehaviouralCategories.COMPORTAMENTE_DE_RISC,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    [BehaviouralCategories.COMPORTAMENT_NESANATOS]: {
      category: BehaviouralCategories.COMPORTAMENT_NESANATOS,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
    [BehaviouralCategories.INVALID]: {
      category: BehaviouralCategories.INVALID,
      type: BehavioursCategoriesTypes.MALADAPTIVE,
    },
  };
  ​
  export type Behaviour = {
    text: string;
    category: BehaviouralCategories;
    type: BehavioursCategoriesTypes;
    inAccordance: boolean;
  };