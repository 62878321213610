import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { Behaviour, BehaviouralCategories, behaviouralCategory } from 'src/app/core/constants/mood-check-behaviour';
import { factorIcons, factorsArray } from 'src/app/core/constants/mood-check-factors';
import { feelingToColor } from 'src/app/core/constants/mood-check-feelings';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { MoodCheck } from 'src/app/models/mood-check';

@Component({
  selector: 'app-mood-check-page',
  templateUrl: './mood-check-page.component.html',
  styleUrls: ['./mood-check-page.component.scss']
})
export class MoodCheckPageComponent {
  factorsArray = factorsArray;
  factorsIcons = factorIcons;
  feelingToColor = feelingToColor;
  selectedCategory: BehaviouralCategories | undefined;

  moodCheck$: Observable<MoodCheck | undefined>;
  filteredBehaviours$: Observable<Behaviour[] | undefined>;

  constructor(private route: ActivatedRoute, private firestoreFacade: FirestoreFacadeService) {
    const moodCheckId = this.route.snapshot.paramMap.get('moodCheckId');
    this.moodCheck$ = this.firestoreFacade.getMoodCheckById(moodCheckId);
    this.filteredBehaviours$ = this.moodCheck$.pipe(switchMap(data => {
      if (data) {
        return of(data.behaviours);
      } else {
        return of([]);
      }
    }));
  }


  addCategoryFilter(behaviouralCategory: BehaviouralCategories) {
    if (this.selectedCategory === behaviouralCategory ) {
      this.selectedCategory = undefined;
      this.filteredBehaviours$ = this.moodCheck$.pipe(switchMap(data => of(data?.behaviours)));
    } else {
      this.selectedCategory = behaviouralCategory;
      this.filteredBehaviours$ = this.moodCheck$.pipe(switchMap(data => {
        if (data) {
          const filteredBehaviours: Behaviour[] = [];
          if (this.selectedCategory) {
            for (let behaviour of data.behaviours) {
              if (behaviour.category === this.selectedCategory) {
                filteredBehaviours.push(behaviour);
              }
            }
            return of(filteredBehaviours);
          } else {
            return of(data.behaviours);
          }
        } else {
          return of([]);
        }
      }));
    }
  }

  getUniqueCategories(behaviours: Behaviour[]): BehaviouralCategories[] {
    const uniqueBehaviouralCategories: BehaviouralCategories[] = [];

    for (let behaviour of behaviours) {
      if (uniqueBehaviouralCategories.indexOf(behaviour.category) == -1) {
        uniqueBehaviouralCategories.push(behaviour.category);
      }
    }

    return uniqueBehaviouralCategories;
  }

  getDate(epoch: number): Date {
    return new Date(epoch);
  }
}
