import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { DashboardStore } from '../../store/dashboard.store';
import { Timestamp } from '@firebase/firestore';

@Component({
  selector: 'app-edit-essentials-dialog',
  templateUrl: './edit-essentials-dialog.component.html',
  styleUrls: ['./edit-essentials-dialog.component.scss']
})
export class EditEssentialsDialogComponent {

  editForm: FormGroup;
  objectiveControl = new FormControl('', [Validators.required]);
  symptomControl = new FormControl('', [Validators.required]);

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditEssentialsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dashboardStore: DashboardStore
  ) {
    let initialBirthday = '';
    if (data.birthday) {
      initialBirthday = data.birthday.toDate();
    }
    this.editForm = this.fb.group({
      description: [data.description || '', Validators.required],
      gender: [data.gender || '', Validators.required],
      objectives: this.fb.array(data.objectives || []),
      symptoms: this.fb.array(data.symptoms || []),
      birthday: [initialBirthday, [Validators.required, Validators.min(0), Validators.max(150)]],
      employment: [data.employment || '', Validators.required]
    });
  }

  get objectives(): FormArray {
    return this.editForm.get('objectives') as FormArray;
  }

  get symptoms(): FormArray {
    return this.editForm.get('symptoms') as FormArray;
  }

  save() {
    if (this.editForm.valid) {
      const formData = this.editForm.value;
      this.dashboardStore.updateSelectedUserProfileEffect({
        clientId: this.data.clientId,
        description: formData.description,
        objectives: this.objectives.value,
        symptoms: this.symptoms.value,
        gender: formData.gender,
        birthday: Timestamp.fromDate(formData.birthday),
        employment: formData.employment,
        id: this.data.id,
        joinedOn: this.data.joinedOn,
        firstName: this.data.firstName,
        lastName: this.data.lastName
      })
      this.dialogRef.close(this.editForm.value);
    }
  }

  addObjective() {
    if (this.objectiveControl.valid) {
      const objectiveValue = this.objectiveControl.value;
      this.objectives.push(new FormControl(objectiveValue));
      this.objectiveControl.reset();
    }
  }

  removeObjective(index: number) {
    this.objectives.removeAt(index);
  }

  addSymptom() {
    if (this.symptomControl.valid) {
      const symptomValue = this.symptomControl.value;
      this.symptoms.push(new FormControl(symptomValue));
      this.symptomControl.reset();
    }
  }

  removeSymptom(index: number) {
    this.symptoms.removeAt(index);
  }

}
