import { Component, inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-invite-sent-snackbar',
  templateUrl: './invite-sent-snackbar.component.html',
  styleUrls: ['./invite-sent-snackbar.component.scss']
})
export class InviteSentSnackbarComponent {

  snackBarRef = inject(MatSnackBarRef);

}
