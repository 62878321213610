import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BehavioursCategoriesTypes } from 'src/app/core/constants/mood-check-behaviour';
import { FeelingType } from 'src/app/core/constants/mood-check-feelings';
import { generateShadesOfOrange } from 'src/app/core/utils/color-utils';
import { ClientProfile } from 'src/app/models/client-profile';
import { MoodCheck } from 'src/app/models/mood-check';

@Component({
  selector: 'app-behaviour-tab',
  templateUrl: './behaviour-tab.component.html',
  styleUrls: ['./behaviour-tab.component.scss']
})
export class BehaviourTabComponent {

  @Input() clientProfile!: ClientProfile;
  @Input() moodChecks!: Observable<MoodCheck[]>;

  adaptivePositiveOptions: echarts.EChartsOption = {
    grid: {
      top: 80
    },
    legend: {
      top: 30,
      left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 1048, name: 'Envy' },
          { value: 735, name: 'Fury' },
          { value: 580, name: 'Anxiety' },
          { value: 484, name: 'Concern' },
          { value: 300, name: 'Jealousy' },
          { value: 100, name: 'Others' },
        ]
      }
    ],
    color: generateShadesOfOrange('#FFaa55', 6)
  };

  adaptiveNegativeOptions: echarts.EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    grid: {
      top: 80
    },
    legend: {
      top: 30,
      left: 'center'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 1048, name: 'Envy' },
          { value: 735, name: 'Fury' },
          { value: 580, name: 'Anxiety' },
          { value: 484, name: 'Concern' },
          { value: 300, name: 'Jealousy' },
          { value: 100, name: 'Others' },
        ]
      }
    ],
    color: generateShadesOfOrange('#FFaa55', 6)
  };


  inadaptivePositiveOptions: echarts.EChartsOption = {
    tooltip: {
      trigger: 'item'
    },grid: {
      top: 80
    },
    legend: {
      top: 30,
      left: 'center'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 1048, name: 'Envy' },
          { value: 735, name: 'Fury' },
          { value: 580, name: 'Anxiety' },
          { value: 484, name: 'Concern' },
          { value: 300, name: 'Jealousy' },
          { value: 100, name: 'Others' },
        ]
      }
    ],
    color: generateShadesOfOrange('#FFaa55', 6)
  };


  inadaptiveNegativeOptions: echarts.EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    grid: {
      top: 80
    },
    legend: {
      top: 30,
      left: 'center'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 1048, name: 'Envy' },
          { value: 735, name: 'Fury' },
          { value: 580, name: 'Anxiety' },
          { value: 484, name: 'Concern' },
          { value: 300, name: 'Jealousy' },
          { value: 100, name: 'Others' },
        ]
      }
    ],
    color: generateShadesOfOrange('#FFaa55', 6)
  };

  mergeAdaptivePositive: any;
  mergeAdaptiveNegative: any;
  mergeInadaptivePositive: any;
  mergeInadaptiveNegative: any;


  ngOnInit() {
    this.moodChecks.subscribe((data) => {
      this.computeFeelingsData(data);
    })
  }

  computeFeelingsData(data: MoodCheck[]) {
    if (this.moodChecks) {
      let adaptivePositiveMap: Map<string, number> = new Map();
      let adaptiveNegativeMap: Map<string, number> = new Map();
      let inadaptivePositiveMap: Map<string, number> = new Map();
      let inadaptiveNegativeMap: Map<string, number> = new Map();
      for (let moodCheck of data) {
        for (let behaviour of moodCheck.behaviours) {
          if (behaviour.type == BehavioursCategoriesTypes.ADAPTIVE) {
            if (moodCheck.feeling.feelingType == FeelingType.POSITIVE) {
              const currentFeelingCount = adaptivePositiveMap.get(behaviour.category);
              if (!!currentFeelingCount) {
                adaptivePositiveMap.set(behaviour.category, currentFeelingCount + 1);
              } else {
                adaptivePositiveMap.set(behaviour.category, 1);
              }
            } else {
              const currentFeelingCount = adaptiveNegativeMap.get(behaviour.category);
              if (!!currentFeelingCount) {
                adaptiveNegativeMap.set(behaviour.category, currentFeelingCount + 1);
              } else {
                adaptiveNegativeMap.set(behaviour.category, 1);
              }
            }
          } else {
            if (moodCheck.feeling.feelingType == FeelingType.POSITIVE) {
              const currentFeelingCount = inadaptivePositiveMap.get(behaviour.category);
              if (!!currentFeelingCount) {
                inadaptivePositiveMap.set(behaviour.category, currentFeelingCount + 1);
              } else {
                inadaptivePositiveMap.set(behaviour.category, 1);
              }
            } else {
              const currentFeelingCount = inadaptiveNegativeMap.get(behaviour.category);
              if (!!currentFeelingCount) {
                inadaptiveNegativeMap.set(behaviour.category, currentFeelingCount + 1);
              } else {
                inadaptiveNegativeMap.set(behaviour.category, 1);
              }
            }
          }
        }
      }

      let adaptivePositiveArray = Array.from(adaptivePositiveMap, ([name, value]) => ({ name, value }));
      let adaptiveNegativeArray = Array.from(adaptiveNegativeMap, ([name, value]) => ({ name, value }));
      let inadaptivePositiveArray = Array.from(inadaptivePositiveMap, ([name, value]) => ({ name, value }));
      let inadaptiveNegativeArray = Array.from(inadaptiveNegativeMap, ([name, value]) => ({ name, value }));

      this.mergeInadaptiveNegative = {
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: inadaptiveNegativeArray
          }
        ]
      };

      this.mergeInadaptivePositive = {
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: inadaptivePositiveArray
          }
        ]
      };

      this.mergeAdaptiveNegative = {
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: adaptiveNegativeArray
          }
        ]
      };

      this.mergeAdaptivePositive = {
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: adaptivePositiveArray
          }
        ]
      };
    }
  }

}
