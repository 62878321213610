import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, finalize, of } from 'rxjs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { FirestoreFacadeService } from './firestore-facade.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageFacadeService {

  downloadURL: Observable<string> = of('');

  // firebaseApp = getApp();
  // storage = getStorage(this.firebaseApp, "gs://respiro-client-documents");

  constructor(private storage: AngularFireStorage, private firestoreFacade: FirestoreFacadeService) { }

  getClientDocs(clientId: string | null): Observable<any[]> {
    return this.firestoreFacade.getClientDocs(clientId);
  }

  deleteFile(pathToFile: string) {
    let ind = pathToFile.indexOf('client-profile');
    let path = pathToFile.slice(ind);
    ind = path.indexOf('?alt=');
    path = path.slice(0, ind);
    const customBucketRef = this.storage.storage.refFromURL(`gs://${environment.bucketName}`);
    const fileRef = customBucketRef.child(decodeURIComponent(path));

    // Delete the file
    return fileRef.delete();
  }

  uploadFile(event: any, clientProfileId: string) {
    const file = event.target.files[0];
    const fileSize = file.size;
    const name = file.name;
    const type = file.type;
    const filePath = `client-profile-${clientProfileId}/` + file.name;

    // Specify your custom bucket here
    const customBucketRef = this.storage.storage.refFromURL(`gs://${environment.bucketName}`);

    // Upload the file to the custom bucket
    const task = customBucketRef.child(filePath).put(file);

    task.on('state_changed', 
      (snapshot) => {
        // Handle upload progress here, like displaying a progress bar
        // console.log(snapshot);
      }, 
      (error) => {
        // Handle unsuccessful uploads
        console.error('Upload failed:', error);
      }, 
      () => {
        // Handle successful uploads on complete
        task.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.firestoreFacade.uploadClientDoc({
            downloadURL: downloadURL,
            name: name,
            size: fileSize,
            type: type,
            date: new Date()
          }, clientProfileId);
        });
      }
    );
  }

  uploadTherapistProfilePicture(file: Blob, therapistId: string): Observable<string> {
    return new Observable<string>((observer) => {
      const fileSize = file.size;
      const name = file.name;
      const type = file.type;
      const filePath = `therapist-profile-${therapistId}/` + 'profile-image';
      const customBucketRef = this.storage.storage.refFromURL(`gs://${environment.bucketName}`);

      const task = customBucketRef.child(filePath).put(file);

      task.on('state_changed', 
        (snapshot) => {
        }, 
        (error) => {
          console.error('Upload failed:', error);
          observer.error(error);
        }, 
        () => {
          task.snapshot.ref.getDownloadURL().then((downloadURL) => {
            observer.next(downloadURL);
            observer.complete();
          });
        }
      );
  });
  }
}
