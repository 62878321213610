<div class="main-container">
    <div class="button-row">
        <button class="generate-report-button accent-button-outline" mat-button (click)="addNewHomework()" [routerLink]="['../../create-homework']">
            <div class="flex-container align-center">
                <span class="s-font-size">{{'clientProfile.homework.add' | translate}}</span>
            </div>
        </button>
    </div>

    <div class="homeworks-container">
        
        <div class="homework-list">
            <div class="homework-container" *ngFor="let homework of homeworks">
                <div class="homework-title-container">
                    <span class="homework-title">{{homework.title}}</span>
                    <div class="icons-container">
                        <mat-icon class="view-homework-icon" aria-hidden="false" aria-label="View icon" fontIcon="visibility" (click)="viewHomework(homework.id)"></mat-icon>
                        <mat-icon class="edit-homework-icon" *ngIf="!homework.isShared" aria-hidden="false" aria-label="Edit icon" fontIcon="edit" (click)="editHomework(homework)" [routerLink]="['../../create-homework']"></mat-icon>
                    </div>
                </div>
                <div class="homework-info-container">
                    <span *ngIf="homework.deadline">
                        {{'clientProfile.homework.deadline' | translate}}: {{getDate(homework.deadline) | date: 'dd-MM-yyyy'}}
                        <div class="danger-text" *ngIf="getDeadlineStatus(homework.deadline) === 'Deadline Passed' && getCompletedPercent(homework.exercises) < 100">
                            {{ getDeadlineStatus(homework.deadline) }}
                        </div>
                    </span>
                    <div class="shared-status-container">
                        <span *ngIf="homework.isShared" class="shared-status">{{'clientProfile.homework.sent' | translate}}</span>
                        <span *ngIf="!homework.isShared" class="not-shared-status" (click)="sendHomework(homework, clientProfile.email)">{{'clientProfile.homework.send' | translate}}</span>
                    </div>
                </div>
                <div class="homework-progress-container" *ngIf="homework.isShared">

                    <div class="">
                        <!-- {{ getCompletedPercent(homework.exercises) + '%'}} -->
                        <circle-progress
                            [percent]="getCompletedPercent(homework.exercises)"
                            [radius]="30"
                            [outerStrokeWidth]="8"
                            [innerStrokeWidth]="4"
                            [outerStrokeColor]="'#0077b6'"
                            [innerStrokeColor]="'#0096C7'"
                            [animation]="false"
                            [animationDuration]="300"
                            [showBackground]="false"
                            [titleFontSize]="'10'"
                        ></circle-progress>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>