<div class="main-container">
    <div class="welcome-div" *ngIf="user$ | async as user">
        <p class="title">{{ 'home.title' | translate }}</p>
        <p class="welcome-text">Bun venit, {{ user.firstName }}</p>
    </div>
    <div class="clients-and-feelings-summary-section">
        <div class="clients-homeworks-container">
            <app-clients-summary [clientProfiles$]="clientProfiles$" class="w-50 h-100"></app-clients-summary>
            <app-emotions-summary [homeworks$]="homeworks$" [clientProfiles]="clientProfiles$ | async" class="w-50 h-100"></app-emotions-summary>
        </div>
    </div>
    <div class="stats-container">
        <div class="stat">
            <div class="stat-title">
                {{ 'home.totalClients' | translate }}
            </div>
            <div class="stat-value">
                {{ totalClients }}
            </div>
        </div>

        <div class="stat">
            <div class="stat-title">
                {{ 'home.totalHomeworks' | translate }}
            </div>
            <div class="stat-value">
                {{ totalHomeworks }}
            </div>
        </div>

        <div class="stat">
            <div class="stat-title">
                {{ 'home.completedHomeworks' | translate }}
            </div>
            <div class="stat-value">
                {{ homeworksCompleted }}
            </div>
        </div>

        <div class="stat">
            <div class="stat-title">
                {{ 'home.inProgressHomeworks' | translate }}
            </div>
            <div class="stat-value">
                {{ homeworksInProgress }}
            </div>
        </div>
</div>
    <div class="clients-and-feelings-summary-section">
        <div class="clients-homeworks-container">
            <app-activity-summary [clientProfiles]="clientProfiles$ | async" [activities]="last7DaysActivity$ | async" class="w-50 h-100"></app-activity-summary>
        
            <div class="exercises-done w-50 h-100">
                <div class="main-chart-container">
                    <p class="chart-title">{{ 'past7Exercises' | translate }}</p>
                    <div echarts [options]="option" class="last-7-days-activity-chart"></div>
                </div>
            </div>
        </div>
    </div>
</div>