import { ComponentStore } from '@ngrx/component-store';
import { tap, from, catchError, of, switchMap, Observable, pipe, switchMapTo, withLatestFrom, take, forkJoin, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { DashboardState } from './dashboard.interface';
import { AuthStore } from 'src/app/auth/store/auth.store';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { ClientProfile } from 'src/app/models/client-profile';
import { HistoryItem } from 'src/app/models/history-item';
import { Homework } from 'src/app/models/homework';

@Injectable()
export class DashboardStore extends ComponentStore<DashboardState> {

    constructor(
        private authStore: AuthStore,
        private firestoreFacade: FirestoreFacadeService
    ) {
        super({ clientProfiles: [], activity: [], last7DaysActivity: [], homeworks: [], selectedClientProfile: null, error: null, loading: false });
        this.initData();
    }

    readonly clientProfiles$ = this.select(state => state.clientProfiles);
    readonly activity$ = this.select(state => state.activity);
    readonly last7DaysActivity$ = this.select(state => state.last7DaysActivity);
    readonly homeworks$ = this.select(state => state.homeworks);
    readonly selectedClientProfile$ = this.select(state => state.selectedClientProfile);

    initializeSelectedClientProfile(clientId: string) {
        this.firestoreFacade.getClientProfileByClientId(clientId).pipe(take(1)).subscribe(
            clientProfiles => {
                this.patchState({ selectedClientProfile: clientProfiles[0] })
            }
        );
    }

    initData() {
        this.authStore.initData().subscribe(respiroUser => {
            if (respiroUser) {
                return this.firestoreFacade.getClientProfiles(respiroUser.id).pipe(
                    tap(data => this.patchState({ clientProfiles: data })),
                    catchError(error => {
                        this.patchState({ error: error.message, loading: false });
                        return of(null);
                    })
                )
            } else {
                return of(null);
            }
        }),
            catchError(error => {
                this.patchState({ error: error.message, loading: false });
                return of(null);
            })
    }

    readonly changeSelectedClientProfile = this.effect((clientProfile$: Observable<ClientProfile>) => {
        return clientProfile$.pipe(
            tap(clientProfile => this.patchState({ selectedClientProfile: clientProfile }))
        );
    }
    )

    readonly updateSelectedUserProfileEffect = this.effect((clientProfile$: Observable<ClientProfile>) => {
        return clientProfile$.pipe(
            tap(clientProfile => {
                this.firestoreFacade.updateClientProfile(clientProfile);
                this.patchState({ selectedClientProfile: clientProfile });
            }),
            catchError(error => of(null))
        )
    }
    )

    readonly fetchClientProfiles = this.effect<void>(

        switchMap(() => this.authStore.respiroUser$.pipe(
            switchMap(respiroUser => {
                if (respiroUser) {
                    return this.firestoreFacade.getClientProfiles(respiroUser.id).pipe(
                        map(clientProfiles => clientProfiles.filter(clientProfile => !clientProfile.deleted)),
                        map(clientProfiles => this.patchState({ clientProfiles: clientProfiles }))
                    );
                } else {
                    return of(null);
                }
            }),
            catchError(error => {
                this.patchState({ error: error.message, loading: false });
                return of(null);
            })
        ))
    );

    readonly fetchActivity = this.effect<void>(
        switchMap(() =>
            this.authStore.respiroUser$.pipe(
                switchMap(respiroUser => {
                    if (respiroUser) {
                        return this.firestoreFacade.getClientProfiles(respiroUser.id).pipe(
                            switchMap(clientProfiles =>
                                forkJoin(
                                    clientProfiles.map(clientProfile =>
                                        this.firestoreFacade.getTodayHistoryForClient(clientProfile.clientId).pipe(
                                            take(1)
                                        )
                                    )
                                ).pipe(
                                    map(historyDataArray => {
                                        // Flatten the array of arrays into a single array
                                        const activity = ([] as HistoryItem[]).concat(...historyDataArray);
                                        // Update the state once with the collected data
                                        this.patchState({ activity: activity });
                                    }),
                                    catchError(error => {
                                        this.patchState({ error: error.message, loading: false });
                                        return of(null);
                                    })
                                )
                            )
                        );
                    } else {
                        return of([]);
                    }
                })
            )
        )
    );

    readonly fetchLast7DaysActivity = this.effect<void>(
        switchMap(() =>
            this.authStore.respiroUser$.pipe(
                switchMap(respiroUser => {
                    if (respiroUser) {
                        return this.firestoreFacade.getClientProfiles(respiroUser.id).pipe(
                            switchMap(clientProfiles =>
                                forkJoin(
                                    clientProfiles.map(clientProfile =>
                                        this.firestoreFacade.getLast7DaysHistoryForClient(clientProfile.clientId).pipe(
                                            take(1)
                                        )
                                    )
                                ).pipe(
                                    map(historyDataArray => {
                                        // Flatten the array of arrays into a single array
                                        const activity = ([] as HistoryItem[]).concat(...historyDataArray);
                                        // Update the state once with the collected data
                                        this.patchState({ last7DaysActivity: activity });
                                    }),
                                    catchError(error => {
                                        this.patchState({ error: error.message, loading: false });
                                        return of(null);
                                    })
                                )
                            )
                        );
                    } else {
                        return of([]);
                    }
                })
            )
        )
    );


    readonly fetchHomeworks = this.effect<void>(
        switchMap(() =>
            this.authStore.respiroUser$.pipe(
                switchMap(respiroUser => {
                    if (respiroUser) {
                        return this.firestoreFacade.getTherapistHomeworks(respiroUser.id).pipe(
                            map(homeworks => {
                                this.patchState({ homeworks: homeworks })
                            }),
                            catchError(error => {
                                this.patchState({ error: error.message, loading: false });
                                return of(null);
                            })
                        )
                    } else {
                        return of([]);
                    }
                }
                )
            )
        )
    )

}