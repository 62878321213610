import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, catchError, filter, from, map, of, switchMap, take, tap, throwError } from 'rxjs';
import { RespiroUser } from '../models/respiro-user';
import { ClientProfile } from '../models/client-profile';
import { ClientInvite } from '../dashboard/store/clientInvites.interface';
import { ClientNote } from '../models/client-note';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MoodCheck } from '../models/mood-check';
import { Meditation } from '../models/meditation';
import { BreathingExercise } from '../models/breathing_exercise';
import { ExerciseType, Homework, JournalTemplate } from '../models/homework';
import { HistoryItem } from '../models/history-item';
import { Timestamp } from '@firebase/firestore';
import { MixpanelService } from '../core/services/mixpanel.service';
import { IJournalTemplate } from '../core/constants/journal-templates';

@Injectable({
  providedIn: 'root'
})
export class FirestoreFacadeService {

  constructor(private afs: AngularFirestore, private mixpanel: MixpanelService) { }

  getJournalTemplates(): Observable<IJournalTemplate[]> {
    return this.afs.collection<IJournalTemplate>('journal-templates')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const jt = a.payload.doc.data() as IJournalTemplate;
            return jt;
          })
        })
      )
  }

  getCurrentUser(email: string): Observable<RespiroUser> {
    return this.afs.collection<RespiroUser>('therapists', ref => ref.where('email', '==', email))
      .snapshotChanges()
      .pipe(
        map(actions => {
          const user = actions[0]?.payload.doc.data() as RespiroUser;
          return user;
        })
      );
  }

  removeClientProfile(clientProfileId: string) {
    this.afs.collection('client-profiles').doc(clientProfileId).delete().then(
      () => this.afs.collection<Homework>('homeworks', ref => ref.where('clientId', '==', clientProfileId)).valueChanges().subscribe(
        homeworks => {
          homeworks.forEach(homework => this.afs.collection('homeworks').doc(homework.id).delete());
        }
      )
    )
  }

  getClientNameById(clientId: string): Observable<string> {
    return this.afs.collection<RespiroUser>('users').doc(clientId).valueChanges().pipe(
      switchMap(data => {
        if (data)
          return data.firstName + " " + data.lastName;
        return "";
      })
    );
  }

  createTherapist(respiroUser: RespiroUser) {
    const documentRef = this.afs.collection('therapists').doc(respiroUser.id);
    const pendingAccountDocumentRef = this.afs.collection('pending-therapists').doc(respiroUser.id);
    pendingAccountDocumentRef.set(respiroUser);
    return from(documentRef.set(respiroUser));
  }

  addClientNote(note: ClientNote, clientProfileId: string): Observable<any> {
    if (note.id && note.id != '') {
      return of(this.afs.collection(`client-profiles/${clientProfileId}/notes`).doc(note.id).update(note));
    }

    return of(this.afs.collection(`client-profiles/${clientProfileId}/notes`).add(note)).pipe(
      switchMap(_ => of(note)),
      catchError(error => throwError(() => new Error(`Failed to add note. Error: ${error.message}`)))
    );
  }

  uploadClientDoc(doc: any, clientProfileId: string) {
    return of(this.afs.collection(`client-profiles/${clientProfileId}/docs`).add(doc)).pipe(
      switchMap(_ => of({
        doc
      })),
      catchError(error => throwError(() => new Error(`Failed to upload document. Error: ${error.message}`)))
    );
  }

  deleteClientDoc(clientProfileId: string | null, doc: any) {
    const documentRef = this.afs.collection(`client-profiles/${clientProfileId}/docs`).doc(doc.id);
    return documentRef.delete();
  }

  getClientDocs(clientId: string | null): Observable<any[]> {
    // return this.afs.collection<ClientNote>('client-profiles', ref => ref.where('clientId', '==', clientId)).snapshotChanges()
    //   .pipe(
    //     map(actions => actions[0].payload.doc.id),
    //     switchMap(clientProfileId => {
    //       return this.afs.collection<any>(`client-profiles/${clientProfileId}/docs`).snapshotChanges()
    //         .pipe(
    //           map(cpActions => {
    //             return cpActions.map(a => {
    //               const data = a.payload.doc.data();
    //               const id = a.payload.doc.id;
    //               return {
    //                 id: id,
    //                 size: data.size,
    //                 name: data.name,
    //                 type: data.type,
    //                 date: data.date,
    //                 downloadURL: data.downloadURL
    //               };
    //             });
    //           }),
    //           catchError(error => {
    //             console.error(error);
    //             return of([]);
    //           })
    //         )
    //     }),
    //     catchError(error => {
    //       console.error(error);
    //       return of([]);
    //     })
    //   );

    return this.afs.collection<any>(`client-profiles/${clientId}/docs`).snapshotChanges()
            .pipe(
              map(cpActions => {
                return cpActions.map(a => {
                  const data = a.payload.doc.data();
                  const id = a.payload.doc.id;
                  return {
                    id: id,
                    size: data.size,
                    name: data.name,
                    type: data.type,
                    date: data.date,
                    downloadURL: data.downloadURL
                  };
                });
              }),
              catchError(error => {
                console.error(error);
                return of([]);
              })
            )
  }

  getClientNotes(clientId: string | null, therapistId: string | undefined): Observable<ClientNote[]> {
    return this.afs.collection<ClientNote>('client-profiles', ref => ref.where('clientId', '==', clientId)
      .where('therapistId', '==', therapistId))
      .snapshotChanges()
      .pipe(
        map(actions => actions[0].payload.doc.id),
        switchMap(clientProfileId => {
          return this.afs.collection<ClientNote>(`client-profiles/${clientProfileId}/notes`).snapshotChanges()
            .pipe(
              map(cpActions => {
                return cpActions.map(a => {
                  const data = a.payload.doc.data() as ClientNote;
                  const id = a.payload.doc.id;
                  return {
                    id: id,
                    note: data.note,
                    date: data.date
                  };
                });
              }),
              catchError(error => {
                console.error(error);
                return of([]);
              })
            );
        }),
        catchError(error => {
          console.error(error);
          return of([]);
        })
      );
  }

  getClientHomeworks(clientId: string | null): Observable<Homework[]> {
    return this.afs.collection<Homework>('homeworks', ref => ref.where('clientId', '==', clientId)).snapshotChanges()
      .pipe(
        map(cpActions => {
          return cpActions.map(a => {
            const data = a.payload.doc.data() as Homework;
            const id = a.payload.doc.id;
            const homework = data;
            homework.id = id;
            return homework;
          });
        })
      );
  }

  getTherapistHomeworks(therapistId: string | null): Observable<Homework[]> {
    return this.afs.collection<Homework>('homeworks', ref => ref.where('therapistId', '==', therapistId)).snapshotChanges()
      .pipe(
        map(cpActions => {
          return cpActions.map(a => {
            const data = a.payload.doc.data() as Homework;
            const id = a.payload.doc.id;
            const homework = data;
            homework.id = id;
            return homework;
          });
        })
      );
  }



  getClientProfiles(therapistId: string): Observable<ClientProfile[]> {
    return this.afs.collection<ClientProfile>('client-profiles', ref => ref.where('therapistId', '==', therapistId))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as ClientProfile;
          const id = a.payload.doc.id;
          const clientProfile = data;
          clientProfile.id = id;
          return clientProfile;
        })),
      );
  }

  getUserData(userId: string): Observable<RespiroUser> {
    return this.afs.collection<RespiroUser>('users', ref => ref.where('id', '==', userId))
      .snapshotChanges()
      .pipe(
        map(actions => {
          const user = actions[0]?.payload.doc.data() as RespiroUser;
          return user;
        })
      );
  }

  getMoodCheckById(moodCheckId: string | null) {
    if (moodCheckId)
      return this.afs.collection<MoodCheck>('check-ups').doc(moodCheckId).valueChanges();
    return of();
  }

  getMoodChecksByClientId(clientId: string | null): Observable<MoodCheck[]> {
    if (clientId) {
      const clientProfileDocRef = this.afs.collection('client-profiles').doc(clientId);
      return clientProfileDocRef.valueChanges().pipe(
        take(1),
        switchMap((clientProfile: any) => {
          if (!clientProfile.moodsShared) return of([]);
          return this.afs.collection<MoodCheck>('check-ups', ref => ref.where('userId', '==', clientId))
            .snapshotChanges()
            .pipe(
              map((actions) => {
                return actions.map((a) => {
                  const data = a.payload.doc.data() as MoodCheck;
                  const id = a.payload.doc.id;
                  const moodCheck = data;
                  moodCheck.id = id;
                  return moodCheck;
                })
              })
            );
        })
      );
    }

    return of([]);
  }

  getHomeworkById(homeworkId: string | null): Observable<Homework | undefined> {
    if (homeworkId) {
      const homeworkDocRef = this.afs.collection<Homework>('homeworks').doc(homeworkId);
      return homeworkDocRef.valueChanges();
    }

    return of();
  }

  getClientProfileByClientId(clientId: string): Observable<ClientProfile[]> {
    // return this.afs.collection<ClientProfile>('client-profiles', ref => ref.where('clientId', '==', clientId))
    //   .valueChanges();

    return this.afs.collection<ClientProfile>('client-profiles', ref => ref.where('clientId', '==', clientId))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as ClientProfile;
            const id = a.payload.doc.id;
            const clientProfile = data;
            clientProfile.id = id;
            return clientProfile;
          });
        })
      );
  }

  getMeditations(): Observable<Meditation[]> {
    return this.afs.collection<Meditation>('meditations')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Meditation;
            const id = a.payload.doc.id;
            const meditation = data;
            meditation.id = id;
            return meditation;
          });
        })
      );
  }

  getBreathingExercises(): Observable<BreathingExercise[]> {
    return this.afs.collection<BreathingExercise>('breathings')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as BreathingExercise;
            const id = a.payload.doc.id;
            const breathing = data;
            breathing.id = id;
            return breathing;
          });
        })
      );
  }

  getClientInvites(therapistId: string) {
    return this.afs.collection<ClientInvite>('client-invites', ref => ref.where('therapistId', '==', therapistId))
      .valueChanges();
  }

  saveHomework(homework: Homework) {

    for (let exercise of homework.exercises) {

      let exerciseReference;

      switch (exercise.type) {
        case ExerciseType.BREATHING_EXERCISE:
          exerciseReference = exercise.breathingExercise;
          break;
        case ExerciseType.MEDITATION:
          exerciseReference = exercise.meditation;
          break;
        case ExerciseType.JOURNAL:
          exerciseReference = exercise.journalTemplate;
          break;
        default:
          exerciseReference = undefined;
          break;
      }

      this.mixpanel.track('homework-exercise-added', {
        type: exercise.type,
        title: exerciseReference != undefined ? exerciseReference.title : '',
        id: exerciseReference != undefined ? exerciseReference.id : ''
      });
    }

    return this.afs.collection('homeworks').add(homework).then(docRef => {
      homework.id = docRef.id;
    }).catch(
      error => throwError(() => new Error(`Failed to add client invite. Error: ${error.message}`))
    );
  }

  inviteClient(clientEmail: string, therapist: RespiroUser, message: string): Observable<ClientInvite> {

    const clientInvite: ClientInvite = {
      sentOn: new Date(),  // current date
      status: 'Pending',
      therapistId: therapist.id,
      therapistImage: therapist.profilePicture != undefined ? therapist.profilePicture : '',
      therapistFirstName: therapist.firstName,
      therapistLastName: therapist.lastName,
      therapistEmail: therapist.email,
      clientEmail: clientEmail,
      message: message,
      title: 'Invitatie'
    }

    return of(this.afs.collection('client-invites').add(clientInvite)).pipe(
      switchMap(_ => {
        clientInvite.sentOn = Timestamp.fromDate(clientInvite.sentOn);
        return of(clientInvite)
      }),
      catchError(error => throwError(() => new Error(`Failed to add client invite. Error: ${error.message}`)))
    );
  }

  updateTherapistProfile(therapist: RespiroUser): Observable<any> {
    return from(this.afs.collection('therapists').doc(therapist.id).update(therapist));
  }

  updateHomework(homework: Homework): Observable<any> {
    if (homework.isShared) {
      this.mixpanel.track('homework-sent', {
        therapistId: homework.therapistId,
        clientId: homework.clientId
      });
    }

    return from(this.afs.collection('homeworks').doc(homework.id).update(homework));
  }

  updateClientProfile(clientProfile: ClientProfile) {
    return this.afs.collection('client-profiles',
      ref => ref.where('clientId', '==', clientProfile.clientId).limit(1)).snapshotChanges()
      .pipe(
        take(1),
        switchMap(changes => {
          if (changes.length > 0) {
            const docId = changes[0].payload.doc.id;
            return this.afs.collection('client-profiles').doc(docId).update({
              description: clientProfile.description,
              symptoms: clientProfile.symptoms,
              objectives: clientProfile.objectives,
              birthday: clientProfile.birthday,
              employment: clientProfile.employment,
              gender: clientProfile.gender
            });
          } else {
            throw new Error("No matching profile found");
          }
        })
      ).subscribe();
  }

  getTodayHistoryForClient(clientId: string): Observable<HistoryItem[]> {
    const { start, end } = this.getTodayTimestamps();
    return this.afs.collection<HistoryItem>('history',
      ref => ref.where('userId', '==', clientId)
        .where('timestamp', '>=', start)
        .where('timestamp', '<=', end)).valueChanges()
      .pipe(
        switchMap(values => {
          return of(values);
        })
      );
  }

  getLast7DaysHistoryForClient(clientId: string): Observable<HistoryItem[]> {
    const { start, end } = this.getLast7DaysTimestamps();
    return this.afs.collection<HistoryItem>('history',
      ref => ref.where('userId', '==', clientId)
        .where('timestamp', '>=', start)
        .where('timestamp', '<=', end)).valueChanges()
      .pipe(
        switchMap(values => {
          return of(values);
        })
      );
  }

  private getLast7DaysTimestamps() {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6);
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, -1);
    return {
      start: startOfDay.getTime(),
      end: endOfDay.getTime(),
    };
  }

  private getTodayTimestamps() {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, -1);
    return {
      start: startOfDay.getTime(),
      end: endOfDay.getTime(),
    };
  }
}
