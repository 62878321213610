import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest, map, of, startWith } from 'rxjs';
import { templates } from 'src/app/core/constants/journal-templates';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { BreathingExercise } from 'src/app/models/breathing_exercise';

@Component({
  selector: 'app-journal-templates',
  templateUrl: './journal-templates.component.html',
  styleUrls: ['./journal-templates.component.scss']
})
export class JournalTemplatesComponent {

  searchControl = new FormControl('');
  categoryControl = new FormControl([]);

  templates$ = this.firestoreFacade.getJournalTemplates();
  filteretTemplates$;

  constructor(private firestoreFacade: FirestoreFacadeService) {

    this.filteretTemplates$ = combineLatest([
      this.templates$,
      this.searchControl.valueChanges.pipe(startWith(this.searchControl.value))
    ]).pipe(
      map(([tmplts, searchText]) => {

        if (searchText != null) {
          return tmplts.filter(template =>
            template.title.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        return [];
      })
    );
  }

}
