import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthPageComponent } from './auth/auth-page/auth-page.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthStore } from './auth/store/auth.store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivityComponent } from './dashboard/pages/activity/activity.component';
import { ClientComponent } from './dashboard/pages/client/client.component';
import { ClientsComponent } from './dashboard/pages/clients/clients.component';
import { ClientCardComponent } from './dashboard/components/client-card/client-card.component';
import { PanelComponent } from './dashboard/panel/panel.component';
import { EssentialsTabComponent } from './dashboard/components/essentials-tab/essentials-tab.component';
import { NotesTabComponent } from './dashboard/components/notes-tab/notes-tab.component';
import { DocsTabComponent } from './dashboard/components/docs-tab/docs-tab.component';
import { HomeworkTabComponent } from './dashboard/components/homework-tab/homework-tab.component';

import { AngularFireModule, FirebaseApp } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';

import { environment } from '../environments/environment';
import { DashboardStore } from './dashboard/store/dashboard.store';
import { EditEssentialsDialogComponent } from './dashboard/components/edit-essentials-dialog/edit-essentials-dialog.component';
import { InvitesComponent } from './dashboard/pages/invites/invites.component';
import { InviteClientDialogComponent } from './dashboard/components/invite-client-dialog/invite-client-dialog.component';
import { InviteSentSnackbarComponent } from './dashboard/components/invite-sent-snackbar/invite-sent-snackbar.component';
import { ClientInvitesStore } from './dashboard/store/clientInvites.store';
import { ProfileComponent } from './dashboard/pages/profile/profile.component';
import { GeneralInfoComponent } from './dashboard/pages/profile/steps/general-info/general-info.component';
import { ContactDataComponent } from './dashboard/pages/profile/steps/contact-data/contact-data.component';
import { EducationAndTrainingComponent } from './dashboard/pages/profile/steps/education-and-training/education-and-training.component';
import { ServicesComponent } from './dashboard/pages/profile/steps/services/services.component';
import { AddNoteDialogComponent } from './dashboard/components/add-note-dialog/add-note-dialog.component';
import { ClientsSummaryComponent } from './dashboard/pages/activity/components/clients-summary/clients-summary.component';
import { EmotionsSummaryComponent } from './dashboard/pages/activity/components/emotions-summary/emotions-summary.component';
import { ActivitySummaryComponent } from './dashboard/pages/activity/components/activity-summary/activity-summary.component';
import { MoodTabComponent } from './dashboard/components/mood-tab/mood-tab.component';
import { MoodChecksComponent } from './dashboard/components/mood-tab/components/mood-checks/mood-checks.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { MoodCheckPageComponent } from './dashboard/pages/mood-check-page/mood-check-page.component';
import { BehaviourTabComponent } from './dashboard/components/behaviour-tab/behaviour-tab.component';
import { CreateHomeworkComponent } from './dashboard/pages/create-homework/create-homework.component';
import { HomeworkStatus } from './dashboard/store/homework.state';
import { HomeworkStore } from './dashboard/store/homework.store';
import { SaveActionStatusSnackbarComponent } from './dashboard/pages/create-homework/components/save-action-status-snackbar/save-action-status-snackbar.component';
import { EditNoteDialogComponent } from './dashboard/components/edit-note-dialog/edit-note-dialog.component';
import { ChangesSavedSnackbarComponent } from './dashboard/pages/profile/components/changes-saved-snackbar/changes-saved-snackbar.component';
import { BreathingExercisesComponent } from './dashboard/pages/breathing-exercises/breathing-exercises.component';
import { MeditationsComponent } from './dashboard/pages/meditations/meditations.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { ViewHomeworkComponent } from './dashboard/pages/view-homework/view-homework.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperDialogComponent } from './dashboard/pages/profile/components/image-cropper-dialog/image-cropper-dialog.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { JournalTemplatesComponent } from './dashboard/pages/journal-templates/journal-templates.component';
import { RemoveClientDialogComponent } from './dashboard/components/remove-client-dialog/remove-client-dialog.component';
import { ActivationMessageComponent } from './dashboard/components/activation-message/activation-message.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    AuthPageComponent,
    ActivityComponent,
    ClientComponent,
    ClientsComponent,
    ClientCardComponent,
    PanelComponent,
    EssentialsTabComponent,
    NotesTabComponent,
    DocsTabComponent,
    HomeworkTabComponent,
    EditEssentialsDialogComponent,
    InvitesComponent,
    InviteClientDialogComponent,
    InviteSentSnackbarComponent,
    ProfileComponent,
    GeneralInfoComponent,
    ContactDataComponent,
    EducationAndTrainingComponent,
    ServicesComponent,
    AddNoteDialogComponent,
    ClientsSummaryComponent,
    EmotionsSummaryComponent,
    ActivitySummaryComponent,
    MoodTabComponent,
    MoodChecksComponent,
    MoodCheckPageComponent,
    BehaviourTabComponent,
    CreateHomeworkComponent,
    SaveActionStatusSnackbarComponent,
    EditNoteDialogComponent,
    ChangesSavedSnackbarComponent,
    BreathingExercisesComponent,
    MeditationsComponent,
    ViewHomeworkComponent,
    ImageCropperDialogComponent,
    JournalTemplatesComponent,
    RemoveClientDialogComponent,
    ActivationMessageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatGridListModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatStepperModule,
    MatDatepickerModule,
    MatSelectModule,
    MatChipsModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    FormsModule,
    HttpClientModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ImageCropperModule,
    NgCircleProgressModule.forRoot({
      "toFixed": 0,
      "maxPercent": 100,
      "showSubtitle": false,
      "showBackground": false})
  ],
  providers: [AuthStore, DashboardStore, ClientInvitesStore, HomeworkStore, HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule { }
