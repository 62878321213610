import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { DashboardStore } from '../../store/dashboard.store';
import { Observable } from 'rxjs';
import { Exercise, Homework } from 'src/app/models/homework';

@Component({
  selector: 'app-view-homework',
  templateUrl: './view-homework.component.html',
  styleUrls: ['./view-homework.component.scss']
})
export class ViewHomeworkComponent {

  homework$!: Observable<Homework | undefined>;

  constructor(private route: ActivatedRoute, private firestoreFacade: FirestoreFacadeService) {
      const homeworkId = this.route.snapshot.paramMap.get('homeworkId');
    
      this.homework$ = this.firestoreFacade.getHomeworkById(homeworkId);
  }

  getCompletedPercent(exercises: Exercise[]) {
    let totalExercises = exercises.length;
    let completedExercises = 0;

    for (let exercise of exercises) {
      if (exercise.done) completedExercises++;
    }

    return Math.round((completedExercises/totalExercises) * 100);
  }

}
