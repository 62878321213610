<div class="main-container">
<div class="message-title">Activarea contului este necesară</div>
        <p>Stimate Terapeut,</p>
        <p>
            Vă mulțumim că v-ați înregistrat pe portalul nostru. Pentru securitatea și integritatea comunității noastre, solicităm un proces unic de verificare a identității pentru toți terapeuții noștri.
        </p>

        <p>
            Contul dvs. este în prezent în așteptare de activare, deoarece lucrăm pentru a vă verifica identitatea. Acest proces ajută la asigurarea fiabilității platformei noastre, permițând pacienților și terapeuților să interacționeze cu încredere.
        </p>

        <p>
            Apreciem răbdarea și înțelegerea dumneavoastră. Odată ce identitatea dvs. este confirmată, veți primi o notificare prin e-mail și veți putea accesa toate funcțiile platformei noastre.
        </p>

        <p>
            Dacă aveți întrebări sau aveți nevoie de asistență, vă rugăm să contactați <a href="mailto:contact@respirotechnologies.com">echipa noastră de asistență</a>.
        </p>

        <p>
            Vă mulțumim pentru că ne-ați ales și pentru angajamentul dumneavoastră de a oferi asistență medicală de calitate.
        </p>

        <p>
            Cu respect,
        </p>

        <p>
            Echipa Respiria ❤️
        </p>
</div>