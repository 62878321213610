import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthPageComponent } from './auth/auth-page/auth-page.component';
import { PanelComponent } from './dashboard/panel/panel.component';
import { ActivityComponent } from './dashboard/pages/activity/activity.component';
import { ClientsComponent } from './dashboard/pages/clients/clients.component';
import { ClientComponent } from './dashboard/pages/client/client.component';
import { InvitesComponent } from './dashboard/pages/invites/invites.component';
import { ProfileComponent } from './dashboard/pages/profile/profile.component';
import { MoodCheckPageComponent } from './dashboard/pages/mood-check-page/mood-check-page.component';
import { CreateHomeworkComponent } from './dashboard/pages/create-homework/create-homework.component';
import { authGuard } from './auth/guards/auth.guard';
import { MeditationsComponent } from './dashboard/pages/meditations/meditations.component';
import { ViewHomeworkComponent } from './dashboard/pages/view-homework/view-homework.component';
import { BreathingExercisesComponent } from './dashboard/pages/breathing-exercises/breathing-exercises.component';
import { JournalTemplatesComponent } from './dashboard/pages/journal-templates/journal-templates.component';

const routes: Routes = [
  { path: '', redirectTo: '/panel', pathMatch: 'full' },
  { path: 'login', component: AuthPageComponent },
  {
    path: 'panel',
    component: PanelComponent,
    canActivate: [authGuard],
    children: [
      { path: 'home', component: ActivityComponent },
      { path: 'create-homework', component: CreateHomeworkComponent },
      { path: 'clients', component: ClientsComponent },
      { path: 'breathing-exercises', component: BreathingExercisesComponent },
      { path: 'guided-meditations', component: MeditationsComponent },
      { path: 'journal-templates', component: JournalTemplatesComponent },
      { 
        path: 'client/:clientId', 
        component: ClientComponent,
      },
      { path: 'invites', component: InvitesComponent},
      { path: 'profile', component: ProfileComponent},
      { path: 'client/:clientId/mood-check/:moodCheckId', component:MoodCheckPageComponent },
      { 
        path: 'homework/:homeworkId', 
        component: ViewHomeworkComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
