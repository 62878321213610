import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { BreathingExercise } from 'src/app/models/breathing_exercise';

@Component({
  selector: 'app-breathing-exercises',
  templateUrl: './breathing-exercises.component.html',
  styleUrls: ['./breathing-exercises.component.scss']
})
export class BreathingExercisesComponent {

  searchControl = new FormControl('');
  categoryControl = new FormControl([]);

  breathings$: Observable<BreathingExercise[]> = this.firestoreFacade.getBreathingExercises();
  filteredBreathings$;
  allCategories$: Observable<string[]>;

  constructor(private firestoreFacade: FirestoreFacadeService) {
    this.allCategories$ = this.breathings$.pipe(
      map(breathings =>
        breathings.reduce<string[]>((acc, breathing) =>
          [...acc, ...breathing.categories.filter(category => !acc.includes(category))], [])
      )
    );

    this.filteredBreathings$ = combineLatest([
      this.breathings$,
      this.searchControl.valueChanges.pipe(startWith(this.searchControl.value)),
      this.categoryControl.valueChanges.pipe(startWith(this.categoryControl.value))
    ]).pipe(
      map(([breathings, searchText, selectedCategories]) => {

        if (searchText != null && selectedCategories != null) {
          return breathings.filter(breathing =>
            breathing.title.toLowerCase().includes(searchText.toLowerCase()) &&
            (!selectedCategories.length ||
              selectedCategories.every(category => breathing.categories.includes(category)))
          );
        }
        return [];
      })
    );
  }

}
