import { Injectable } from '@angular/core';
import { UserCredentials } from '../store/auth.interface';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Auth, signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { User } from 'firebase/auth';
import { getAuth, getRedirectResult, signInWithPopup, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<firebase.default.User | null> = this.afAuth.user;
  accountActive: boolean = false;

  constructor(private afAuth: AngularFireAuth) { 
  }

  // async signUp(credentials: UserCredentials) {
  //   try {
  //     const result = await this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password);
  //     return result;
  //   } catch (error) {
  //     if (typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string') {
  //       console.log(error);
  //       throw new Error(error.message);
  //     } else {
  //       throw new Error('Unknown authentication error');
  //     }
  //   }
  // }

  signUp(email: string, password: string): Promise<firebase.default.auth.UserCredential> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  loginWithGoogle(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    return signInWithPopup(auth, provider);
  }

  signIn(credentials: UserCredentials): Promise<firebase.default.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password)
      .catch(error => {
        if (typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string') {
          console.error(error);
          throw new Error(error.message);
        } else {
          throw new Error('Unknown authentication error');
        }
      });
  }


  getCurrentUser(email: string): Promise<firebase.default.User | null> {
    return this.afAuth.currentUser;
  }

  // Sign out 
  signOut(): Promise<void> {
    return this.afAuth.signOut();
  }

  // login(credentials: UserCredentials): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     if (credentials.email === 'test@test.com' && credentials.password === 'password') {
  //       resolve({ success: true });
  //     } else {
  //       reject({ error: 'Invalid credentials' });
  //     }
  //   });
  // }

  // register(credentials: UserCredentials): Promise<any> {
  //   return new Promise((resolve) => resolve({ success: true }));
  // }
}
