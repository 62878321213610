<div class="main-container">
    <div class="title-row">
        <span class="l-font-size bold">{{'home.homeworks' | translate}}</span>

        <div class="button-container">
            <button class="secondary-button" mat-button>
                <div class="flex-container align-center">
                    <span class="s-font-size" (click)="onAddHomework()" [routerLink]="['../create-homework']">{{'home.addHomework' | translate}}</span>
                </div>
            </button>
        </div>
    </div>

    <div class="stats-row">

    </div>


    <div class="feelings-container">
        <div class="feeling-row" *ngFor="let homework of sortedHomeworks$ | async">
            <div class="feeling-title m-font-size bold primary-color">
                <span>{{ homework.title }}</span>
            </div>
            <div class="feeling-details">
                <div class="user-info flex-container s-font-size  align-center">
                    <mat-icon aria-hidden="false" aria-label="User icon" fontIcon="person_outline"></mat-icon>
                    <span>{{ getClientName(homework.clientId) }}</span>
                </div>

                <div class="time-info flex-container s-font-size align-center">
                    <mat-icon aria-hidden="false" aria-label="User icon" fontIcon="calendar_month"></mat-icon>
                    <span>{{ homework.deadline.toDate() | date: 'dd-MM-yyyy' }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
