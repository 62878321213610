<div class="main-container">
    <div class="filters-container">
        <!-- Search bar -->
        <div class="search-bar">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Search</mat-label>
                <input matInput [formControl]="searchControl" placeholder="Search templates...">
            </mat-form-field>
        </div>
    </div>
    
    <div class="meditation-container" *ngFor="let template of filteretTemplates$ | async">
        <div class="meditation-title">
            {{template.title}}
        </div>

        <div class="description">
            {{template.description}}
        </div>

        <div class="sections-container">
            <div class="section-title">
                {{ 'sections'  | translate }}
            </div>

            <div *ngFor="let section of template.sections">
                <li class="section">
                    {{ section }}
                </li>
            </div>
        </div>

    </div>
</div>