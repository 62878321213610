<div class="main-container">
    <div class="title-row">
        <span class="l-font-size bold">{{'clientProfile.mood.title' | translate}}</span>
    </div>

    <div class="stats-row">
        <span>
            <span class="positive">{{moodChecks?.length}}</span>
            {{'clientProfile.mood.done' | translate}}
        </span>

    </div>


    <div class="feelings-container">
        <div class="feeling-row" *ngFor="let moodCheck of moodChecks">
            <div class="feeling-details-container">
                <div class="feeling-title l-font-size bold fury" [ngStyle]="{ color: feelingToColor.get(moodCheck.feeling.feeling) }">
                    <span>{{ 'mood.' + moodCheck.feeling.feeling | translate }}</span>
                </div>
                <div class="feeling-details">
                    <div class="user-info flex-container s-font-size  align-center">
                        <mat-icon aria-hidden="false" aria-label="User icon" fontIcon="calendar_month"></mat-icon>
                        <span>{{getDate(moodCheck.date) | date: 'dd-MM-yyyy'}}</span>
                    </div>

                    <div class="time-info flex-container s-font-size align-center">
                        <mat-icon aria-hidden="false" aria-label="User icon" fontIcon="access_time"></mat-icon>
                        <span>{{getDate(moodCheck.date) | date: 'HH:mm'}}</span>
                    </div>
                </div>
            </div>
            <div class="view-more-button-container">
                <button class="accent-button-outline" mat-button [routerLink]="['mood-check', moodCheck.id]">
                    <div class="flex-container align-center">
                        <span class="s-font-size">{{'clientProfile.mood.view' | translate}}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>