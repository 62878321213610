import { ChangeDetectorRef, Component } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { DashboardStore } from '../store/dashboard.store';
import { InviteClientDialogComponent } from '../components/invite-client-dialog/invite-client-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthFacadeService } from 'src/app/auth/facades/auth-facade.service';
import { catchError, take, tap, throwError } from 'rxjs';
import { AuthStore } from 'src/app/auth/store/auth.store';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {

  loading = true;
  mobileQuery: MediaQueryList;
  opened = true;
  accountActive = false;

  respiroUser$ = this.authStore.respiroUser$;

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private dashboardStore: DashboardStore, private dialog: MatDialog, private router: Router,
    private authStore: AuthStore, private authService: AuthService) {
    this.mobileQuery = media.matchMedia('(max-width: 820px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.respiroUser$.pipe(
      tap(data => {
        if (data) {
          this.authService.accountActive = data ? data.active : false;
          this.loading = false;
        }
      })
    ).subscribe();

    this.dashboardStore.fetchClientProfiles();

    if (this.router.url.endsWith('panel'))
      this.router.navigate(['/panel/home']);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  openInviteDialog(): void {
    const dialogRef = this.dialog.open(InviteClientDialogComponent);
  
    dialogRef.afterClosed().subscribe(result => {});
  }

  logOut(): void {
    this.authStore.logOut();
  }

  currentPageName() {
    if (this.router.url.includes('homework')) return 'Homework';
    if (this.router.url.includes('home')) return 'Home';
    if (this.router.url.includes('mood-check')) return 'Mood Check';
    if (this.router.url.includes('clients')) return 'Clients';
    if (this.router.url.includes('client')) return 'Client';
    if (this.router.url.includes('invites')) return 'Invites';
    return '';
  }

}
