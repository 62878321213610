<h2 mat-dialog-title>{{'clientProfile.essentials.editProfile' | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="editForm">

    <div class="basic-attributes">
      <mat-form-field appearance="outline">
        <mat-label>{{'clientProfile.essentials.gender' | translate}}</mat-label>
        <input matInput formControlName="gender">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'clientProfile.essentials.birthday' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="birthday">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'clientProfile.essentials.employment' | translate}}</mat-label>
        <input matInput formControlName="employment">
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline" style="width: 100%; margin-top: 2rem">
      <mat-label>{{'clientProfile.essentials.description' | translate}}</mat-label>
      <textarea matInput formControlName="description" rows="4"></textarea>
    </mat-form-field>


    <div class="objectives-symptoms-container">

      <div class="objectives-form">
        <div formArrayName="objectives">
          <div class="section-title">
            {{'clientProfile.essentials.objectives' | translate}}
          </div>
    
          <div>
            <div *ngFor="let objective of objectives.controls; let i = index" [formGroupName]="i">
              <div class="objective-container">
                <div class="remove-objective-button">
                  <mat-icon class="delete-icon" aria-hidden="false" aria-label="Delete icon" fontIcon="delete" (click)="removeObjective(i)"></mat-icon>
                  <!-- <button class="danger-button" mat-button (click)="removeObjective(i)">
                    <div class="flex-container align-center">
                      <span class="s-font-size">-</span>
                    </div>
                  </button> -->
                </div>
                <div class="objective-title"> {{ objective.value }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="new-objective-container objective-form-container">
          <div class="add-objective-button">
            <mat-icon class="add-icon" aria-hidden="false" aria-label="Add icon" fontIcon="add_circle" (click)="addObjective()"></mat-icon>
            <!-- <button class="primary-button" mat-button (click)="addObjective()">
              <div class="flex-container align-center">
                <span class="s-font-size">{{'clientProfile.editEssentials.add' | translate}}</span>
              </div>
            </button> -->
          </div>
          <div class="new-objective-title objective-form-title">
            <mat-form-field class="objective-form-field" appearance="outline">
              <mat-label>{{'clientProfile.essentials.objective' | translate}}</mat-label>
              <input matInput [formControl]="objectiveControl">
            </mat-form-field>
          </div>
        </div>
      </div>
  
      <div class="symptoms-form">
        <div formArrayName="symptoms">
          <div class="section-title">
            {{'clientProfile.essentials.symptoms' | translate}}
          </div>
    
          <div>
            <div *ngFor="let symptom of symptoms.controls; let i = index" [formGroupName]="i">
              <div class="objective-container">
                <div class="remove-objective-button">
                  <mat-icon class="delete-icon" aria-hidden="false" aria-label="Delete icon" fontIcon="delete" (click)="removeSymptom(i)"></mat-icon>
                </div>
                <div class="objective-title">{{ symptom.value }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="new-objective-container objective-form-container">
          <div class="add-objective-button">
            <mat-icon class="add-icon" aria-hidden="false" aria-label="Add icon" fontIcon="add_circle" (click)="addSymptom()"></mat-icon>
          </div>
          <div class="new-objective-title objective-form-title">
            <mat-form-field class="objective-form-field" appearance="outline">
              <mat-label>{{'clientProfile.essentials.symptom' | translate}}</mat-label>
              <input matInput [formControl]="symptomControl">
            </mat-form-field>
          </div>
        </div>
      </div>

    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="action-btn" mat-button (click)="dialogRef.close()">{{'clientProfile.essentials.cancel' | translate}}</button>
  <button class="action-btn" mat-button (click)="save()">{{'clientProfile.essentials.save' | translate}}</button>
</mat-dialog-actions>