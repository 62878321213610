<div class="main-container">
    <h2 class="title">{{'register.welcome' | translate}}</h2>

    <button class="gsi-material-button" (click)="onLoginWithGoogle()">
        <div class="gsi-material-button-state"></div>
        <div class="gsi-material-button-content-wrapper">
          <div class="gsi-material-button-icon">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
              <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
              <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
              <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
              <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span class="gsi-material-button-contents">{{'register.googleSignUp' | translate}}</span>
          <span style="display: none;">{{'register.googleSignUp' | translate}}</span>
        </div>
      </button>

    <div class="divider">
        <span class="divider-content">{{'register.or' | translate}}</span>
    </div>

    <form [formGroup]="registerForm" class="login-form">
        
        <div class="name-container">
            <mat-form-field appearance="outline" class="mr-1">
                <mat-label>{{'register.firstName' | translate}}</mat-label>
                <input matInput formControlName="firstName" type="text">
                <mat-error *ngIf="registerForm.get('firstName')?.hasError('required')">
                    {{'register.firstNameRequired' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'register.lastName' | translate}}</mat-label>
                <input matInput formControlName="lastName" type="text">
                <mat-error *ngIf="registerForm.get('lastName')?.hasError('required')">
                    {{'register.lastNameRequired' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        
        <mat-form-field appearance="outline">
            <mat-label>{{'register.email' | translate}}</mat-label>
            <input matInput formControlName="email" type="email">
            <mat-error *ngIf="registerForm.get('email')?.hasError('required')">
                {{'register.emailRequired' | translate}}
            </mat-error>
            <mat-error *ngIf="registerForm.get('email')?.hasError('email')">
                {{'register.emailInvalid' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'register.password' | translate}}</mat-label>
            <input matInput formControlName="password" type="password">
            <mat-error *ngIf="registerForm.get('password')?.hasError('required')">
                {{'register.passwordRequired' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'register.confirmPassword' | translate}}</mat-label>
            <input matInput formControlName="confirmPassword" type="password">
            <mat-error *ngIf="registerForm.get('confirmPassword')?.hasError('required')">
                {{'register.confirmPasswordRequired' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="termsAccepted">{{'agree' | translate}}<a target="_blank" href="https://www.respirotechnologies.com/terms-and-conditions">{{'terms' | translate}}</a></mat-checkbox>

        <!-- <div class="error-container"> -->
        <div *ngIf="registerForm.errors && registerForm.errors['mismatch']" class="error-message">{{'register.passwordsDontMatch' | translate}}</div>
        <div *ngIf="error$ | async as error" class="error-message">{{error}}</div>
        <!-- </div> -->
        <button mat-raised-button [disabled]="!registerForm.get('termsAccepted')?.value" color="primary" type="submit" (click)="onLogin()">{{'register.signUp' | translate}}</button>
    </form>
    <p class="no-account-text">{{'register.haveAccount' | translate}} <a (click)="onSwitchView()" class="sign-up-text">{{'register.signIn' | translate}}</a></p>
</div>