import { Component } from '@angular/core';

@Component({
  selector: 'app-education-and-training',
  templateUrl: './education-and-training.component.html',
  styleUrls: ['./education-and-training.component.scss']
})
export class EducationAndTrainingComponent {

}
