<div class="main-container">
    <div class="page-title-container">
        <span class="title-text" *ngIf="!editModeEnabled">{{'homework.createTitle' | translate}}</span>
        <span class="title-text" *ngIf="editModeEnabled">{{'homework.editTitle' | translate}}</span>
        <div class="save-buttons-container">
            <button *ngIf="!editModeEnabled" class="accent-button-outline" mat-button (click)="save()">
                <div class="flex-container align-center">
                    <span class="s-font-size">{{'homework.save' | translate}}</span>
                </div>
            </button>

            <button *ngIf="!editModeEnabled" class="secondary-button save-and-send-button" mat-button (click)="saveAndSend()">
                <div class="flex-container align-center">
                    <span class="s-font-size">{{'homework.saveAndSend' | translate}}</span>
                </div>
            </button>

            <button *ngIf="editModeEnabled" class="primary-button" mat-button (click)="edit()">
                <div class="flex-container align-center">
                    <span class="s-font-size">{{'homework.saveChanges' | translate}}</span>
                </div>
            </button>

            <button *ngIf="editModeEnabled" class="primary-button edit-and-send-button" mat-button (click)="editAndSend()">
                <div class="flex-container align-center">
                    <span class="s-font-size">{{'homework.saveChangesAndSend' | translate}}</span>
                </div>
            </button>

            <button *ngIf="editModeEnabled" class="exit-edit-mode-button danger-button" mat-button (click)="exitEditMode()">
                <div class="flex-container align-center">
                    <span class="s-font-size">{{'homework.createNew' | translate}}</span>
                </div>
            </button>
            <!-- <button mat-raised-button>Save and Send</button> -->
        </div>
    </div>
    <div class="homework-form-container" *ngIf="homework$ | async as homework">
        <form [formGroup]="homeworkForm">

            <!-- Basic section for title and description -->
            <div class="basic-section">

                <div class="basic-info-container">
                    <mat-form-field appearance="outline" class="basic-info-field">
                        <mat-label>{{'homework.client' | translate}}</mat-label>
                        <input matInput placeholder="Search..." formControlName="client" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClients">
                            <mat-option *ngFor="let client of filteredClients$ | async" [value]="client">
                                {{client.firstName + ' ' + client.lastName }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="basic-info-field">
                        <mat-label>{{'homework.title' | translate}}</mat-label>
                        <input matInput formControlName="title">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="basic-info-field">
                        <mat-label>{{'homework.deadline' | translate}}</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="deadline">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            

                <mat-form-field appearance="outline">
                    <mat-label>{{'homework.description' | translate}}</mat-label>
                    <textarea matInput formControlName="description"></textarea>
                </mat-form-field>

            </div>

            <!-- Morning Exercises -->
            <div formArrayName="morningExercises">

                <div class="section-title">
                    {{'homework.exercises' | translate}}
                </div>

                <div>
                    <div *ngFor="let exercise of morningExercises.controls; let i = index" [formGroupName]="i">
                        <!-- *ngIf="i!=morningExercises.controls.length-1" -->
                        <div class="exercises-container">

                            <div class="added-exercise-container">
                                <div class="top-section-container">

                                    <div [ngSwitch]="exercise.get('type')?.value">
                                        <div *ngSwitchCase="'Meditation'" class="exercise-title-container">
                                            {{exercise.get('meditation')?.value.title}}
                                        </div>
                                        <div *ngSwitchCase="'Breathing Exercise'" class="exercise-title-container">
                                            {{exercise.get('breathingExercise')?.value.title}}
                                        </div>
                                        <div *ngSwitchCase="'Journal'" class="exercise-title-container">
                                            {{exercise.get('journalTemplate')?.value.title}}
                                        </div>
                                        <div *ngSwitchCase="'Mood Check'" class="exercise-title-container">
                                            {{'homework.moodCheck' | translate}}
                                        </div>
                                        <div *ngSwitchCase="'Other'" class="exercise-title-container preserve-newlines">
                                            {{exercise.get('description')?.value}}
                                        </div>
                                    </div>

                                    <div class="buttons-container">
                                        <mat-icon aria-hidden="false" aria-label="Delete icon" fontIcon="delete"
                                            class="delete-exercise-icon"
                                            (click)="cancelExercise(i, 'morning')"></mat-icon>
                                    </div>
                                </div>
                                <div class="bottom-section-container">
                                    <div class="flex-row type-container">
                                        <mat-icon aria-hidden="false" aria-label="Type icon"
                                            fontIcon="adjust"></mat-icon>
                                        &nbsp;{{exercise.get('type')?.value | translate}}
                                    </div>
                                    <div *ngIf="exercise.get('type')?.value == 'Meditation'"
                                        class="flex-row duration-container">
                                        <mat-icon aria-hidden="false" aria-label="Duration icon"
                                            fontIcon="access_time"></mat-icon>
                                        &nbsp;{{getMinutesAndSeconds(exercise.get('meditation')?.value.seconds)}}
                                    </div>
                                    <div *ngIf="exercise.get('type')?.value == 'Meditation'"
                                        class="flex-row narrator-container">
                                        <mat-icon aria-hidden="false" aria-label="Duration icon"
                                            fontIcon="person_outline"></mat-icon>
                                        &nbsp;{{exercise.get('meditation')?.value.narrator}}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- <div *ngIf="i==morningExercises.controls.length-1" class="new-exercise-container"> -->

                            <!-- <div class="exercise-select-container">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'homework.exerciseType' | translate}}</mat-label>
                                    <mat-select formControlName="type">
                                        <mat-option *ngFor="let type of exerciseTypes"
                                            [value]="type">{{ type | translate }}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div [ngSwitch]="exercise.get('type')?.value">

                                    <div *ngSwitchCase="'Meditation'">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>{{'homework.selectOption' | translate}}</mat-label>
                                            <input matInput placeholder="Search..." formControlName="meditation"
                                                [matAutocomplete]="auto">
                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                <mat-option *ngFor="let meditation of filteredMeditations$ | async"
                                                    [value]="meditation" class="meditation-container">
                                                    <div class="meditation-title">{{meditation.title}}</div>
                                                    <div class="meditation-categories-container">
                                                        <div class="meditation-category-chip" *ngFor="let category of meditation.categories">
                                                            {{ category }}
                                                        </div>
                                                    </div>
                                                    <div class="meditation-description">
                                                        {{ meditation.description }}
                                                    </div>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div *ngSwitchCase="'Breathing Exercise'">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>{{'homework.selectOption' | translate}}</mat-label>
                                            <input matInput placeholder="Search..." formControlName="breathingExercise"
                                                [matAutocomplete]="auto">
                                            <mat-autocomplete #auto="matAutocomplete"
                                                [displayWith]="displayFnBreathingExercises">
                                                <mat-option
                                                    *ngFor="let breathingExercise of filteredBreathingExercises$ | async"
                                                    [value]="breathingExercise" class="meditation-container">
                                                    <div class="meditation-title">{{breathingExercise.title}}</div>
                                                    <div class="meditation-categories-container">
                                                        <div class="meditation-category-chip" *ngFor="let category of breathingExercise.categories">
                                                            {{ category }}
                                                        </div>
                                                    </div>
                                                    <div class="meditation-description">
                                                        {{ breathingExercise.description }}
                                                    </div>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>

                                    <div *ngSwitchCase="'Journal'">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'homework.exerciseType' | translate}}</mat-label>
                                            <mat-select formControlName="journalTemplate">
                                                <mat-option *ngFor="let journalTemplate of journalTemplates"
                                                    [value]="journalTemplate">{{journalTemplate.title}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                    </div>

                                    <div *ngSwitchCase="'Mood Check'"></div>
                                    <div *ngSwitchDefault>{{exercise.get('type')?.value}}</div>

                                    <div *ngSwitchCase="'Other'">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'homework.description' | translate}}</mat-label>
                                            <textarea matInput placeholder="" formControlName="description"></textarea>
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="exercise-buttons-container">
                                <button class="generate-report-button primary-button" mat-button
                                    (click)="addExercise('morning')">
                                    <div class="flex-container align-center">
                                        <span class="s-font-size">{{'homework.newExercise' | translate}}</span>
                                    </div>
                                </button>

                                <button class="generate-report-button primary-button" mat-button
                                    (click)="cancelExercise(i, 'morning')">
                                    <div class="flex-container align-center">
                                        <span class="s-font-size">{{'homework.cancel' | translate}}</span>
                                    </div>
                                </button>
                            </div> -->

                        <!-- </div> -->
                    </div>

                    <!-- <button *ngIf="morningExercises.length==0" class="generate-report-button primary-button mb-1"
                        mat-button (click)="addExercise('morning')">
                        <div class="flex-container align-center">
                            <span class="s-font-size">{{'homework.newExercise' | translate}}</span>
                        </div>
                    </button> -->
                </div>

                <!-- <div class="morning-error-message-container" *ngIf="isLastMorningExerciseInvalid">Test</div> -->

            </div>

            <!-- Similarly add sections for noonExercises and eveningExercises -->

            <!-- Morning Exercises -->
            <!-- <div formArrayName="noonExercises">

                <div class="section-title">
                    Noon Plan
                </div>

                <div>
                    <div *ngFor="let exercise of noonExercises.controls; let i = index" [formGroupName]="i">
                        <div *ngIf="i!=noonExercises.controls.length-1" class="new-exercise-container">

                            <div class="added-exercise-container">
                                <div class="top-section-container">
                                    <div class="exercise-title-container">
                                        {{exercise.get('meditation')?.value.title}}
                                    </div>
                                    <div class="buttons-container">
                                        <mat-icon aria-hidden="false" aria-label="Delete icon" fontIcon="delete"
                                            class="delete-exercise-icon" (click)="cancelExercise(i, 'noon')"></mat-icon>
                                    </div>
                                </div>
                                <div class="bottom-section-container">
                                    <div class="flex-row type-container">
                                        <mat-icon aria-hidden="false" aria-label="Type icon"
                                            fontIcon="adjust"></mat-icon>
                                        {{exercise.get('type')?.value}}
                                    </div>
                                    <div class="flex-row duration-container">
                                        <mat-icon aria-hidden="false" aria-label="Duration icon"
                                            fontIcon="access_time"></mat-icon>
                                            {{getMinutesAndSeconds(exercise.get('meditation')?.value.seconds)}}
                                    </div>
                                    <div class="flex-row narrator-container">
                                        <mat-icon aria-hidden="false" aria-label="Duration icon"
                                            fontIcon="person_outline"></mat-icon>
                                        {{exercise.get('meditation')?.value.narrator}}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="i==noonExercises.controls.length-1" class="new-exercise-container">

                            <div class="exercise-select-container">
                                <mat-form-field appearance="outline">
                                    <mat-label>Exercise Type</mat-label>
                                    <mat-select formControlName="type">
                                        <mat-option *ngFor="let type of exerciseTypes"
                                            [value]="type">{{type}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div [ngSwitch]="exercise.get('type')?.value">

                                    <div *ngSwitchCase="'Meditation'">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Select an option</mat-label>
                                            <input matInput placeholder="Search..." formControlName="meditation"
                                                [matAutocomplete]="auto">
                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                <mat-option *ngFor="let meditation of filteredMeditations$ | async"
                                                    [value]="meditation">
                                                    {{meditation.title}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div *ngSwitchCase="'Breathing Exercise'">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Select a breathing exercise</mat-label>
                                            <input matInput placeholder="Search..." formControlName="breathingExercise"
                                                [matAutocomplete]="auto">
                                            <mat-autocomplete #auto="matAutocomplete"
                                                [displayWith]="displayFnBreathingExercises">
                                                <mat-option
                                                    *ngFor="let breathingExercise of filteredBreathingExercises$ | async"
                                                    [value]="breathingExercise">
                                                    {{breathingExercise.title}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div *ngSwitchCase="'Journal'">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Exercise Type</mat-label>
                                            <mat-select formControlName="journalTemplate">
                                                <mat-option *ngFor="let journalTemplate of journalTemplates"
                                                    [value]="journalTemplate">{{journalTemplate}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                    </div>
                                    <div *ngSwitchCase="'Mood Check'"></div>
                                    <div *ngSwitchDefault>{{exercise.get('type')?.value}}</div>
                                </div>
                            </div>

                            <div class="exercise-buttons-container">
                                <button class="generate-report-button primary-button" mat-button
                                    (click)="addExercise('noon')">
                                    <div class="flex-container align-center">
                                        <span class="s-font-size">Add New Exercise</span>
                                    </div>
                                </button>

                                <button class="generate-report-button primary-button" mat-button
                                    (click)="cancelExercise(i, 'noon')">
                                    <div class="flex-container align-center">
                                        <span class="s-font-size">Cancel</span>
                                    </div>
                                </button>
                            </div>

                        </div>
                    </div>

                    <button *ngIf="noonExercises.length==0" class="generate-report-button primary-button mb-1"
                        mat-button (click)="addExercise('noon')">
                        <div class="flex-container align-center">
                            <span class="s-font-size">Add New Exercise</span>
                        </div>
                    </button>
                </div>

            </div> -->


            <!-- Morning Exercises -->
            <!-- <div formArrayName="eveningExercises">

                <div class="section-title">
                    Evening Plan
                </div>

                <div>
                    <div *ngFor="let exercise of eveningExercises.controls; let i = index" [formGroupName]="i">
                        <div *ngIf="i!=eveningExercises.controls.length-1" class="new-exercise-container">

                            <div class="added-exercise-container">
                                <div class="top-section-container">
                                    <div class="exercise-title-container">
                                        {{exercise.get('meditation')?.value.title}}
                                    </div>
                                    <div class="buttons-container">
                                        <mat-icon aria-hidden="false" aria-label="Delete icon" fontIcon="delete"
                                            class="delete-exercise-icon"
                                            (click)="cancelExercise(i, 'evening')"></mat-icon>
                                    </div>
                                </div>
                                <div class="bottom-section-container">
                                    <div class="flex-row type-container">
                                        <mat-icon aria-hidden="false" aria-label="Type icon"
                                            fontIcon="adjust"></mat-icon>
                                        {{exercise.get('type')?.value}}
                                    </div>
                                    <div class="flex-row duration-container">
                                        <mat-icon aria-hidden="false" aria-label="Duration icon"
                                            fontIcon="access_time"></mat-icon>
                                            {{getMinutesAndSeconds(exercise.get('meditation')?.value.seconds)}}
                                    </div>
                                    <div class="flex-row narrator-container">
                                        <mat-icon aria-hidden="false" aria-label="Duration icon"
                                            fontIcon="person_outline"></mat-icon>
                                        {{exercise.get('meditation')?.value.narrator}}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="i==eveningExercises.controls.length-1" class="new-exercise-container">

                            <div class="exercise-select-container">
                                <mat-form-field appearance="outline">
                                    <mat-label>Exercise Type</mat-label>
                                    <mat-select formControlName="type">
                                        <mat-option *ngFor="let type of exerciseTypes"
                                            [value]="type">{{type}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div [ngSwitch]="exercise.get('type')?.value">

                                    <div *ngSwitchCase="'Meditation'">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Select an option</mat-label>
                                            <input matInput placeholder="Search..." formControlName="meditation"
                                                [matAutocomplete]="auto">
                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                <mat-option *ngFor="let meditation of filteredMeditations$ | async"
                                                    [value]="meditation">
                                                    {{meditation.title}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div *ngSwitchCase="'Breathing Exercise'">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Select a breathing exercise</mat-label>
                                            <input matInput placeholder="Search..." formControlName="breathingExercise"
                                                [matAutocomplete]="auto">
                                            <mat-autocomplete #auto="matAutocomplete"
                                                [displayWith]="displayFnBreathingExercises">
                                                <mat-option
                                                    *ngFor="let breathingExercise of filteredBreathingExercises$ | async"
                                                    [value]="breathingExercise">
                                                    {{breathingExercise.title}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div *ngSwitchCase="'Journal'">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Exercise Type</mat-label>
                                            <mat-select formControlName="journalTemplate">
                                                <mat-option *ngFor="let journalTemplate of journalTemplates"
                                                    [value]="journalTemplate">{{journalTemplate}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                    </div>
                                    <div *ngSwitchCase="'Mood Check'"></div>
                                    <div *ngSwitchDefault>{{exercise.get('type')?.value}}</div>
                                </div>
                            </div>

                            <div class="exercise-buttons-container">
                                <button class="generate-report-button primary-button" mat-button
                                    (click)="cancelExercise(i, 'evening')">
                                    <div class="flex-container align-center">
                                        <span class="s-font-size">Cancel</span>
                                    </div>
                                </button>

                                <button class="generate-report-button primary-button" mat-button
                                    (click)="addExercise('evening')">
                                    <div class="flex-container align-center">
                                        <span class="s-font-size">Add New Exercise</span>
                                    </div>
                                </button>
                            </div>

                        </div>
                    </div>

                    <button *ngIf="eveningExercises.length==0" class="generate-report-button primary-button mb-1"
                        mat-button (click)="addExercise('evening')">
                        <div class="flex-container align-center">
                            <span class="s-font-size">Add New Exercise</span>
                        </div>
                    </button>

                </div>
            </div> -->

        </form>

        <div class="separator"></div>

        <form [formGroup]="newExerciseGroup">
            <div class="new-exercise-container">

                <div class="exercise-buttons-container">
                    <button class="generate-report-button secondary-button" mat-button
                        (click)="addExercise('morning')">
                        <div class="flex-container align-center">
                            <span class="s-font-size">{{'homework.newExercise' | translate}}</span>
                        </div>
                    </button>
                </div>

                <div class="exercise-select-container">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'homework.exerciseType' | translate}}</mat-label>
                        <mat-select formControlName="type">
                            <mat-option *ngFor="let type of exerciseTypes"
                                [value]="type">{{ type | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div [ngSwitch]="newExerciseGroup.get('type')?.value">

                        <div *ngSwitchCase="'Meditation'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{'homework.selectOption' | translate}}</mat-label>
                                <input matInput placeholder="Search..." formControlName="meditation"
                                    [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngFor="let meditation of filteredMeditations$ | async"
                                        [value]="meditation" class="meditation-container">
                                        <div class="meditation-title">{{meditation.title}}</div>
                                        <div class="meditation-categories-container">
                                            <div class="meditation-category-chip" *ngFor="let category of meditation.categories">
                                                {{ category }}
                                            </div>
                                        </div>
                                        <div class="meditation-description">
                                            {{ meditation.description }}
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div *ngSwitchCase="'Breathing Exercise'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{'homework.selectOption' | translate}}</mat-label>
                                <input matInput placeholder="Search..." formControlName="breathingExercise"
                                    [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete"
                                    [displayWith]="displayFnBreathingExercises">
                                    <mat-option
                                        *ngFor="let breathingExercise of filteredBreathingExercises$ | async"
                                        [value]="breathingExercise" class="meditation-container">
                                        <div class="meditation-title">{{breathingExercise.title}}</div>
                                        <div class="meditation-categories-container">
                                            <div class="meditation-category-chip" *ngFor="let category of breathingExercise.categories">
                                                {{ category }}
                                            </div>
                                        </div>
                                        <div class="meditation-description">
                                            {{ breathingExercise.description }}
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div *ngSwitchCase="'Journal'">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'homework.exerciseType' | translate}}</mat-label>
                                <mat-select formControlName="journalTemplate">
                                    <mat-option *ngFor="let journalTemplate of journalTemplates"
                                        [value]="journalTemplate">{{journalTemplate.title}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>

                        <div *ngSwitchCase="'Mood Check'"></div>
                        <div *ngSwitchDefault>{{newExerciseGroup.get('type')?.value}}</div>

                        <div *ngSwitchCase="'Other'">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'homework.description' | translate}}</mat-label>
                                <textarea matInput placeholder="" formControlName="description"></textarea>
                            </mat-form-field>

                        </div>
                    </div>
                </div>

            </div>
        </form>


    </div>
</div>