export enum Feelings {
    HAPPINESS = "happiness",
    ENTHUSIASM = "enthusiasm",
    PRIDE = "pride",
    CALMNESS = "calmness",
    OPTIMISM = "optimism",
    LOVE = "love",
    TRUST = "trust",
    GRATITUDE = "gratitude",
    SADNESS = "sadness",
    ANGER = "anger",
    FEAR = "fear",
    WORRY = "worry",
    REGRET = "regret",
    FRUSTRATION = "frustration",
    GUILT = "guilt",
    SHAME = "shame",
    ENVY = "envy",
    BOREDOM = "boredom",
    SURPRISE = "surprise"
}

export enum FeelingType {
    POSITIVE = "positive",
    NEGATIVE = "negative",
}

export type Feeling = {
    feeling: Feelings;
    feelingType: FeelingType;
};

export const feelingToColor: Map<Feelings, string> = new Map([
    [Feelings.HAPPINESS, "#FFD700"],
    [Feelings.ENTHUSIASM, "#FFA500"],
    [Feelings.PRIDE, "#800080"],
    [Feelings.CALMNESS, "#00CED1"],
    [Feelings.OPTIMISM, "#32CD32"],
    [Feelings.LOVE, "#FF69B4"],
    [Feelings.TRUST, "#00FF00"],
    [Feelings.GRATITUDE, "#FFD700"],
    [Feelings.SADNESS, "#0000CD"],
    [Feelings.ANGER, "#FF0000"],
    [Feelings.FEAR, "#8B0000"],
    [Feelings.WORRY, "#FFA500"],
    [Feelings.REGRET, "#8A2BE2"],
    [Feelings.FRUSTRATION, "#DC143C"],
    [Feelings.GUILT, "#8B4513"],
    [Feelings.SHAME, "#A9A9A9"],
    [Feelings.BOREDOM, "#A9A9A9"],
    [Feelings.SURPRISE, "#800080"],
    [Feelings.ENVY, "#228B22"]
]);

export const feelings: Feeling[] = [
    {
        feeling: Feelings.HAPPINESS,
        feelingType: FeelingType.POSITIVE,
    },
    {
        feeling: Feelings.ENTHUSIASM,
        feelingType: FeelingType.POSITIVE,
    },
    {
        feeling: Feelings.PRIDE,
        feelingType: FeelingType.POSITIVE,
    },
    {
        feeling: Feelings.CALMNESS,
        feelingType: FeelingType.POSITIVE,
    },
    {
        feeling: Feelings.OPTIMISM,
        feelingType: FeelingType.POSITIVE,
    },
    {
        feeling: Feelings.LOVE,
        feelingType: FeelingType.POSITIVE,
    },
    {
        feeling: Feelings.TRUST,
        feelingType: FeelingType.POSITIVE,
    },
    {
        feeling: Feelings.GRATITUDE,
        feelingType: FeelingType.POSITIVE,
    },
    {
        feeling: Feelings.SADNESS,
        feelingType: FeelingType.NEGATIVE,
    },
    {
        feeling: Feelings.ANGER,
        feelingType: FeelingType.NEGATIVE,
    },
    {
        feeling: Feelings.FEAR,
        feelingType: FeelingType.NEGATIVE,
    },
    {
        feeling: Feelings.WORRY,
        feelingType: FeelingType.NEGATIVE,
    },
    {
        feeling: Feelings.REGRET,
        feelingType: FeelingType.NEGATIVE,
    },
    {
        feeling: Feelings.FRUSTRATION,
        feelingType: FeelingType.NEGATIVE,
    },
    {
        feeling: Feelings.GUILT,
        feelingType: FeelingType.NEGATIVE,
    },
    {
        feeling: Feelings.SHAME,
        feelingType: FeelingType.NEGATIVE,
    },
    {
        feeling: Feelings.ENVY,
        feelingType: FeelingType.NEGATIVE,
    },
    {
        feeling: Feelings.BOREDOM,
        feelingType: FeelingType.NEGATIVE,
    },
    {
        feeling: Feelings.SURPRISE,
        feelingType: FeelingType.POSITIVE,
    },
];
