import { ComponentStore } from '@ngrx/component-store';
import { AuthState, UserCredentials } from './auth.interface';
import { AuthService } from '../services/auth.service';
import { tap, from, catchError, of, switchMap, Observable, throwError, take, finalize, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { RespiroUser } from 'src/app/models/respiro-user';
import { AuthFacadeService } from '../facades/auth-facade.service';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { Router } from '@angular/router';
import { EmailFacadeService } from 'src/app/facades/email-facade.service';
import { MixpanelService } from 'src/app/core/services/mixpanel.service';

@Injectable()
export class AuthStore extends ComponentStore<AuthState> {
  constructor(private authFacade: AuthFacadeService,
    private firestoreFacade: FirestoreFacadeService,
    private mixpanelService: MixpanelService,
    private router: Router,
    private emailFacade: EmailFacadeService) {
    super({ user: null, respiroUser: null, loading: false, error: null });
  }

  readonly user$ = this.select(state => state.user);
  readonly respiroUser$ = this.select(state => state.respiroUser);
  readonly loading$ = this.select(state => state.loading);
  readonly error$ = this.select(state => state.error);

  initData(): Observable<RespiroUser | null> {
    return this.authFacade.isAuthenticated().pipe(
        switchMap(user => {
            if (user && user.email) {
                return this.firestoreFacade.getCurrentUser(user.email).pipe(
                    tap(userData => {
                        if(userData && userData.id) {
                            userData.id = userData.id.trim();
                            this.patchState({ respiroUser: userData, loading: false, error: null });
                        }
                    }),
                    catchError(error => {
                        this.patchState({ error: error.message, loading: false });
                        return of(null);
                    })
                );
            }
            return EMPTY;
        })
    );
};

  readonly loginUser = this.effect((credentials$: Observable<UserCredentials>) => {
    return credentials$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(credentials =>
        this.authFacade.signIn(credentials).pipe(
          switchMap(user =>
            this.firestoreFacade.getCurrentUser(credentials.email).pipe(
              tap(userData => {
                userData.id = userData.id.trim();
                this.patchState({ user: user, respiroUser: userData, loading: false, error: null });
                this.mixpanelService.identify(userData.id);
                this.router.navigate(['/panel']);
              }),
              catchError(error => {
                this.patchState({ error: error.message, loading: false });
                return of(null);
              })
            )
          ),
          catchError(error => {
            this.patchState({ error: error.message, loading: false });
            return of(null);
          }),

        )
      )
    );
  });

  signUp(email: string, password: string, firstName: string, lastName: string) {
    return this.authFacade.signUp(email, password).then(data => {
      const email = data.user?.email;
      const uid = data.user?.uid;
      const user = data.user;
      
      if (uid && email) {
        const respiroUser: RespiroUser = {
          id: uid,
          email: email,
          firstName: firstName,
          lastName: lastName,
          appLanguage: 'ro',
          values: [],
          active: false
        }

        this.firestoreFacade.createTherapist(respiroUser).pipe(
          tap(data => {
            this.patchState({ user: user, respiroUser: respiroUser, loading: false, error: null });
            this.emailFacade.sendNewAccountEmail(respiroUser.email).subscribe();
            this.mixpanelService.identify(respiroUser.id);
            this.router.navigate(['/panel']);
          }),
          catchError(e => throwError(() => new Error(`Failed to create user. Error: ${e.message}`)))
        ).subscribe();
      }
    });
  }

  loginUserWithGoogle() {
    return this.authFacade.loginWithGoogle().then(data =>
        {
          const email = data.user?.email;
          const uid = data.user?.uid;
          const displayName = data.user?.displayName;
          const user = data.user;
          if (email && uid && displayName) {
            this.firestoreFacade.getCurrentUser(email).pipe(
              take(1),
              tap(userData => {
                if (userData === undefined) {

                  const respiroUser: RespiroUser = {
                    id: uid,
                    email: email,
                    firstName: displayName.split(' ')[0],
                    lastName: displayName.split(' ')[1],
                    appLanguage: 'ro',
                    values: [],
                    active: false
                  }

                  this.firestoreFacade.createTherapist(respiroUser).pipe(
                    tap(data => {
                      this.patchState({ user: user, respiroUser: respiroUser, loading: false, error: null });
                      this.emailFacade.sendNewAccountEmail(respiroUser.email).subscribe();
                      this.mixpanelService.identify(respiroUser.id);
                      this.router.navigate(['/panel']);
                    }),
                    catchError(e => throwError(() => new Error(`Failed to create user. Error: ${e.message}`)))
                  )
                } else {
                  this.patchState({ user: userData, respiroUser: userData, loading: false, error: null });
                  this.router.navigate(['/panel']);
                }
              }),
              catchError(error => {
                this.patchState({ error: error.message, loading: false });
                return of(null);
              })
            ).subscribe();
          }
        }
        
      );
  }

  logOut(): void {
    this.authFacade.logOut().pipe(
      take(1),
      tap(() => {
        this.patchState({ user: null, respiroUser: null });
        this.mixpanelService.reset();
        this.router.navigate(['/login']);
      }),
      catchError(error => {
        console.error("Error during logout:", error);
        return EMPTY;
      })
    ).subscribe();
  }

  clearError() {
    this.patchState({ error: null });
  }
}