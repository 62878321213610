import { Component } from '@angular/core';
import { DashboardStore } from '../../store/dashboard.store';
import { filter, map, take, tap } from 'rxjs';
import { EChartsOption } from 'echarts';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { AuthFacadeService } from 'src/app/auth/facades/auth-facade.service';
import { AuthStore } from 'src/app/auth/store/auth.store';
import { ExerciseType } from 'src/app/models/homework';
import { ContentType } from 'src/app/core/constants/content-type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent {

  clientProfiles$ = this.dashboardStore.clientProfiles$;
  activity$ = this.dashboardStore.activity$;
  last7DaysActivity$ = this.dashboardStore.last7DaysActivity$;
  homeworks$ = this.dashboardStore.homeworks$;
  user$ = this.authStore.respiroUser$;

  totalClients = 0;
  totalHomeworks = 0;
  homeworksCompleted = 0;
  homeworksInProgress = 0;

  activitiesMap = new Map<number, number>([
    [ContentType['Meditation'], 0],
    [ContentType['Breathing Exercise'], 0],
    [ContentType['Journal'], 0],
    [ContentType['Mood Check'], 0],
  ]);

  option: EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: []
      }
    ]
  };

  constructor(private dashboardStore: DashboardStore,
              private translate: TranslateService,
              private authStore: AuthStore) {
    this.dashboardStore.fetchActivity();
    this.dashboardStore.fetchHomeworks();
    this.dashboardStore.fetchLast7DaysActivity();

    this.initChart();
    this.initStats();
  }

  initStats() {
    this.totalHomeworks = 0;
    this.homeworksCompleted = 0;
    this.homeworksInProgress = 0;
    this.homeworks$.pipe(
      tap(homeworks => {
        this.totalHomeworks = 0;
        this.homeworksCompleted = 0;
        this.homeworksInProgress = 0;
        for(let homework of homeworks) {
          this.totalHomeworks ++;

          let done = true; 
          for (let ex of homework.exercises) {
            if (!ex.done) {
              done = false;
              this.homeworksInProgress ++;
              break;
            }
          }

          if (done) {
            this.homeworksCompleted ++;
          }
        }
      })
    ).subscribe();

    this.clientProfiles$.pipe(
      tap(data => this.totalClients = data.length)
    ).subscribe();

  }

  initChart() {
    this.last7DaysActivity$.pipe(
      tap(activities => {

        const chartData = new Map<string, number>();

        for (let activity of activities) {
          const curr = chartData.get(ContentType[activity.contentType]);
          if (curr) {
            chartData.set(ContentType[activity.contentType], curr + 1);
          } else {
            chartData.set(ContentType[activity.contentType], 1);
          }
        }

        const data = []
        for (let [name, value] of chartData.entries()) {
          data.push({'value': value, 'name': this.translate.instant(name)});
        }

        this.option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: 'Past 7 Days Exercises',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: data,
              color: ['#0077b6', '#7678ED', '#0096C7']
            }
          ]
        };
      })
    ).subscribe();

  }

}
