import { Component, ViewEncapsulation } from '@angular/core';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { ClientProfile } from 'src/app/models/client-profile';
import { DashboardStore } from '../../store/dashboard.store';
import { ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, map, of } from 'rxjs';
import { take, tap, timeout } from 'rxjs/operators';
import { ClientNote } from 'src/app/models/client-note';
import { FirebaseStorageFacadeService } from 'src/app/facades/firebase-storage-facade.service';
import { MoodCheck } from 'src/app/models/mood-check';
import { Homework } from 'src/app/models/homework';
import { PdfGeneratorService } from 'src/app/core/services/pdf-generator.service';
import { AuthFacadeService } from 'src/app/auth/facades/auth-facade.service';
import { AuthStore } from 'src/app/auth/store/auth.store';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent {

  clientProfile$ = this.dashboardStore.selectedClientProfile$;
  notes$!: Observable<ClientNote[]>;
  homeworks$!: Observable<Homework[]>;
  docs$!: Observable<any[]>;
  moodChecks$!: Observable<MoodCheck[]>;

  generateReportText = 'clientProfile.report';
  reportGenerating = false;

  selectedTabIndex = 0;

  constructor(private dashboardStore: DashboardStore,
    private route: ActivatedRoute, private firestoreFacade: FirestoreFacadeService,
    private firebaseStorageFacade: FirebaseStorageFacadeService,
    private pdfGenerator: PdfGeneratorService, private authStore: AuthStore) {
    
  }

  generateClientReport() {
    this.reportGenerating = true;
    this.generateReportText = 'clientProfile.generating';
    const combined$ = forkJoin({
      notes: this.notes$.pipe(take(1)),
      homeworks: this.homeworks$.pipe(take(1)),
      moodChecks: this.moodChecks$.pipe(take(1)),
      clientProfile: this.clientProfile$.pipe(take(1))
    });

    combined$.pipe(
      tap(({ notes, homeworks, moodChecks, clientProfile }) => {
        this.pdfGenerator.generateClientReport(clientProfile, moodChecks, notes, homeworks);
      })).subscribe(_ => {
        setTimeout(() => {
          this.reportGenerating = false;
          this.generateReportText = 'clientProfile.report';
        }, 1000);
        
      });
  }


  ngOnInit() {
    const clientId = this.route.snapshot.paramMap.get('clientId');

    this.firestoreFacade.getClientProfileByClientId(clientId!).pipe(take(1)).subscribe(clientProfile => {
      if (clientId) {
        this.initializeClientProfile(clientId);
      }
    });

    this.authStore.respiroUser$.pipe(
      take(1),
      tap(respiroUser => {
        this.notes$ = this.firestoreFacade.getClientNotes(clientId, respiroUser?.id).pipe(
          map(notes => notes.sort((a, b) => b.date.seconds - a.date.seconds))
        );
        this.docs$ = this.firebaseStorageFacade.getClientDocs(clientId);
      })
    ).subscribe();

    this.homeworks$ = this.firestoreFacade.getClientHomeworks(clientId).pipe(
      map(homeworks => homeworks.sort((a: any, b: any) => b.deadline.seconds - a.deadline.seconds))
    );


    
    // this.clientProfile$.pipe(take(1)).subscribe(clientProfile => {
    //   if (!clientProfile && clientId) {
    //     this.initializeClientProfile(clientId);
    //   }
    // });

    this.moodChecks$ = this.firestoreFacade.getMoodChecksByClientId(clientId).pipe(
      map(moodChecks => moodChecks.sort((a, b) => b.date - a.date))
    );


    const lastStoredTab = localStorage.getItem('lastTab');
    this.selectedTabIndex = lastStoredTab != null ? +lastStoredTab : 0;
  }

  initializeClientProfile(clientId: string) {
    this.dashboardStore.initializeSelectedClientProfile(clientId);
  }

  activateTab(tabInd: number) {
    this.selectedTabIndex = tabInd;
  }

  onTabChange(tabInd: number): void {
    localStorage.setItem('lastTab', '' + tabInd);
  }

}
