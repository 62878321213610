import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientNote } from 'src/app/models/client-note';
import { ClientProfile } from 'src/app/models/client-profile';
import { AddNoteDialogComponent } from '../add-note-dialog/add-note-dialog.component';

@Component({
  selector: 'app-notes-tab',
  templateUrl: './notes-tab.component.html',
  styleUrls: ['./notes-tab.component.scss']
})
export class NotesTabComponent {

  @Input() clientProfile!: ClientProfile;
  @Input() notes: ClientNote[] | null = [];

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  openDialog(): void {
    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      width: '80vw',
      data: {
        clientProfileId: this.clientProfile.id
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialogInEditMode(note: ClientNote): void {
    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      width: '80vw',
      data: {
        clientProfileId: this.clientProfile.id,
        note: note
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
