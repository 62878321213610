import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { ClientProfile } from 'src/app/models/client-profile';

@Component({
  selector: 'app-remove-client-dialog',
  templateUrl: './remove-client-dialog.component.html',
  styleUrls: ['./remove-client-dialog.component.scss']
})
export class RemoveClientDialogComponent {

  clientProfile?: ClientProfile;

  constructor(
    public dialogRef: MatDialogRef<RemoveClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private firestoreFacade: FirestoreFacadeService
  ) {
    this.clientProfile = data;
  }

  removeClient() {
    if (this.clientProfile)
      this.firestoreFacade.removeClientProfile(this.clientProfile?.id);
      this.dialogRef.close({ deleted: true }); 
  }

}
