import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, map, of, startWith } from 'rxjs';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss']
})
export class GeneralInfoComponent {
  form: FormGroup;
  dropdownList = ['Option 1', 'Option 2', 'Option 3']; // Example data for dropdown
  selectedItems: string[] = []; // For storing chips
  filteredDropdownList: Observable<string[]>;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      firstName: '',
      lastName: '',
      birthdate: '',
      dropdown: '',
      yearsOfExperience: ''
    });

    this.filteredDropdownList = this.form.get('dropdown')!.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? this._filter(value) : [])
    );
    
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.dropdownList.filter(option => option.toLowerCase().includes(filterValue));
  }

  optionSelected(event: any): void {
    const value = event.option.viewValue;
    if (this.selectedItems.indexOf(value) < 0) {
      this.selectedItems.push(value);
    }
    this.form.get('dropdown')!.setValue(''); // Reset the input value
  }

  removeItem(item: string): void {
    const index = this.selectedItems.indexOf(item);
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
    }
  }

  isSelected(item: string): boolean {
    return this.selectedItems.includes(item);
  }
}
