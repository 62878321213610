import { Component, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { ClientProfile } from 'src/app/models/client-profile';
import { RespiroUser } from 'src/app/models/respiro-user';

@Component({
  selector: 'app-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss']
})
export class ClientCardComponent {

  @Input("clientProfile") clientProfile!: ClientProfile;

  respiroUser$: Observable<RespiroUser> | undefined;

  constructor(private firestoreFacade: FirestoreFacadeService) {
  }

  ngOnInit(): void {
    this.respiroUser$ = this.firestoreFacade.getUserData(this.clientProfile.clientId);
  }

}
