import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { ClientNote } from 'src/app/models/client-note';
import { ClientProfile } from 'src/app/models/client-profile';
import { Homework } from 'src/app/models/homework';
import { MoodCheck } from 'src/app/models/mood-check';
import { FeelingType } from '../constants/mood-check-feelings';

@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {

  constructor() { }


  async generateClientReport(clientProfile: ClientProfile | null, moodChecks: MoodCheck[], notes: ClientNote[], homeworks: Homework[]) {

    if (clientProfile == null)
      return;

    const moodCheckStatsMap: Map<string, any> = this.getMoodChecksStats(moodChecks);
    const moodChecksFromPast7Days: string[] = this.getPast7DaysMoodCheckReports(moodChecks);
    const notesReport: string[] = this.getPast7DaysNotes(notes);

    let logoBase64 = '';
    await this.getBase64ImageFromURL('../../../assets/icons/logo_no_bg.png').then(
      data => {
        logoBase64 = data; // Remove the prefix part of the Base64 string
      }
    );

    const docDefinition: TDocumentDefinitions = {
      footer: function (currentPage, pageCount) {
        const totalWidth = 595.28; // Width of an A4 page in points
        const lineWidth = totalWidth * 0.9; // 90% of total width
        const marginLeft = (totalWidth - lineWidth) / 2; // Centering the line

        return [
          {
            canvas: [
              {
                type: 'line',
                x1: marginLeft,
                y1: 5,
                x2: marginLeft + lineWidth,
                y2: 5,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                text: currentPage + ' of ' + pageCount,
                margin: [10, 5, 0, 0]
              },
              { 
                image: logoBase64,
                width: 24,
                alignment: 'right',
                margin: [0, 5, 10, 0]
              },
              
            ],
          }
        ];
      },
      content: [
        {
          image: logoBase64,
          width: 48,
          alignment: `center`
        },
        {
          text: `Raport ${(new Date()).toISOString().slice(0, 10)}`,
          style: `title`,
          alignment: `center`
        },
        {
          text: ``,
          margin: [0, 20]
        },
        {
          text: `Informatii de baza`,
          style: `header`
        },
        {
          text: ``,
          margin: [0, 5]
        },
        {
          text: [
            { text: `Nume: `, bold: true },
            `${clientProfile.firstName} ${clientProfile.lastName}`
          ]
        },
        {
          text: [
            { text: `Descriere: `, bold: true },
            `${clientProfile.description}`
          ]
        },
        {
          text: [
            { text: `Zi de nastere: `, bold: true },
            `${clientProfile.birthday.toDate().toISOString().slice(0, 10)}`
          ]
        },
        {
          text: [
            { text: `Loc de munca: `, bold: true },
            `${clientProfile.employment}`
          ]
        },
        {
          text: [
            { text: `Gen: `, bold: true },
            `${clientProfile.gender}`
          ]
        },
        {
          text: ``,
          margin: [0, 20]
        },
        {
          text: `Simptome`,
          style: `header`
        },
        {
          text: ``,
          margin: [0, 5]
        },
        {
          ul: [...clientProfile.symptoms]
        },
        {
          text: ``,
          margin: [0, 20]
        },
        {
          text: `Obiective`,
          style: `header`
        },
        {
          text: ``,
          margin: [0, 5]
        },
        {
          ul: [...clientProfile.objectives]
        },
        {
          text: ``,
          margin: [0, 20]
        },
        {
          text: `Mood Checks`,
          style: `header`
        },
        {
          text: ``,
          margin: [0, 5]
        },
        {
          style: 'tableStyle',
          color: '#444',
          table: {
            dontBreakRows: true,
            widths: ['auto', 'auto', 'auto'],
            headerRows: 2,
            // keepWithHeaderRows: 1,
            body: [
              [
                { text: 'Total', style: 'tableHeader', colSpan: 3, alignment: 'center' }, {}, {}
              ],
              [
                { text: 'Total', style: 'tableHeader', alignment: 'center' },
                { text: 'Positive', style: 'tableHeader', alignment: 'center' },
                { text: 'Negative', style: 'tableHeader', alignment: 'center' },
              ],
              [
                `${moodCheckStatsMap.get('total').total}`, `${moodCheckStatsMap.get('total').positive}`, `${moodCheckStatsMap.get('total').negative}`,
              ],
            ]
          }
        },
        {
          style: 'tableStyle',
          color: '#444',
          table: {
            dontBreakRows: true,
            widths: ['auto', 'auto', 'auto'],
            headerRows: 2,
            // keepWithHeaderRows: 1,
            body: [
              [
                { text: 'Last 60 days', style: 'tableHeader', colSpan: 3, alignment: 'center' }, {}, {},
              ],
              [
                { text: 'Total', style: 'tableHeader', alignment: 'center' },
                { text: 'Positive', style: 'tableHeader', alignment: 'center' },
                { text: 'Negative', style: 'tableHeader', alignment: 'center' },
              ],
              [
                `${moodCheckStatsMap.get('last60').total}`, `${moodCheckStatsMap.get('last60').positive}`, `${moodCheckStatsMap.get('last60').negative}`,
              ],
            ]
          }
        },
        {
          style: 'tableStyle',
          color: '#444',
          table: {
            dontBreakRows: true,
            widths: ['auto', 'auto', 'auto',],
            headerRows: 2,
            // keepWithHeaderRows: 1,
            body: [
              [
                { text: 'Last 30 days', style: 'tableHeader', colSpan: 3, alignment: 'center' }, {}, {},
              ],
              [
                { text: 'Total', style: 'tableHeader', alignment: 'center' },
                { text: 'Positive', style: 'tableHeader', alignment: 'center' },
                { text: 'Negative', style: 'tableHeader', alignment: 'center' },
              ],
              [
                `${moodCheckStatsMap.get('last30').total}`, `${moodCheckStatsMap.get('last30').positive}`, `${moodCheckStatsMap.get('last30').negative}`,
              ],
            ]
          }
        },
        {
          style: 'tableStyle',
          color: '#444',
          table: {
            dontBreakRows: true,
            widths: ['auto', 'auto', 'auto',],
            headerRows: 2,
            // keepWithHeaderRows: 1,
            body: [
              [
                { text: 'Last 7 days', style: 'tableHeader', colSpan: 3, alignment: 'center' }, {}, {},
              ],
              [
                { text: 'Total', style: 'tableHeader', alignment: 'center' },
                { text: 'Positive', style: 'tableHeader', alignment: 'center' },
                { text: 'Negative', style: 'tableHeader', alignment: 'center' },
              ],
              [
                `${moodCheckStatsMap.get('last7').total}`, `${moodCheckStatsMap.get('last7').positive}`, `${moodCheckStatsMap.get('last7').negative}`,
              ],
            ]
          }
        },
        {
          text: ``,
          margin: [0, 20]
        },
        {
          text: `Mood Checks Efectuate in Ultimele 7 Zile`,
          style: `header`
        },
        {
          text: ``,
          margin: [0, 5]
        },
        {
          ul: [...moodChecksFromPast7Days],
          preserveLeadingSpaces: true,
          margin: [0, 5]
        },
        {
          text: ``,
          margin: [0, 20]
        },
        {
          text: `Note Luate in Ultimele 7 Zile`,
          style: `header`
        },
        {
          text: ``,
          margin: [0, 5]
        },
        {
          text: notesReport.join(''),
          preserveLeadingSpaces: true,
          margin: [0, 5]
        },
        // {
        //   image: 'data:image/png;base64,' + firstChartBase64, // the base64 of your first chart
        //   width: 500 // adjust as needed
        // },
        // {
        //   image: 'data:image/png;base64,' + secondChartBase64, // the base64 of your second chart
        //   width: 500 // adjust as needed
        // }
      ],
      styles: {
        header: {
          bold: true,
          fontSize: 15
        },
        title: {
          bold: true,
          fontSize: 24
        },
        tableStyle: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black'
        }
      },
      defaultStyle: {
        fontSize: 12
      }
    };

    pdfMake.createPdf(
      docDefinition,
      {},
      {
        // Default font should still be available
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-Italic.ttf'
        },
        // Make sure you define all 4 components - normal, bold, italics, bolditalics - (even if they all point to the same font file)
        TimesNewRoman: {
          normal: 'Times-New-Roman-Regular.ttf',
          bold: 'Times-New-Roman-Bold.ttf',
          italics: 'Times-New-Roman-Italics.ttf',
          bolditalics: 'Times-New-Roman-Italics.ttf'
        }
      },
      pdfFonts.pdfMake.vfs).download();
  }

  getBase64ImageFromURL(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');  // This enables CORS
      img.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  getMoodChecksStats(moodChecks: MoodCheck[]): Map<string, any> {

    const moodCheckStatsMap: Map<string, any> = new Map([
      ['total', {
        total: 0,
        positive: 0,
        negative: 0
      }],
      ['last60', {
        total: 0,
        positive: 0,
        negative: 0
      }],
      ['last30', {
        total: 0,
        positive: 0,
        negative: 0
      }],
      ['last7', {
        total: 0,
        positive: 0,
        negative: 0
      }]
    ])

    const today = new Date();
    const sixtyDaysAgo = new Date(today);
    sixtyDaysAgo.setDate(today.getDate() - 60);
    sixtyDaysAgo.setHours(0, 0, 0, 0);
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);
    thirtyDaysAgo.setHours(0, 0, 0, 0);
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    for (let moodCheck of moodChecks) {
      moodCheckStatsMap.set('total', {
        total: moodCheckStatsMap.get('total').total + 1,
        positive: moodCheck.feeling.feelingType == FeelingType.POSITIVE ? moodCheckStatsMap.get('total').positive + 1 : moodCheckStatsMap.get('total').positive,
        negative: moodCheck.feeling.feelingType == FeelingType.NEGATIVE ? moodCheckStatsMap.get('total').negative + 1 : moodCheckStatsMap.get('total').negative
      });
      if (new Date(moodCheck.date) >= sixtyDaysAgo) {
        moodCheckStatsMap.set('last60', {
          total: moodCheckStatsMap.get('last60').total + 1,
          positive: moodCheck.feeling.feelingType == FeelingType.POSITIVE ? moodCheckStatsMap.get('last60').positive + 1 : moodCheckStatsMap.get('last60').positive,
          negative: moodCheck.feeling.feelingType == FeelingType.NEGATIVE ? moodCheckStatsMap.get('last60').negative + 1 : moodCheckStatsMap.get('last60').negative
        });
      }
      if (new Date(moodCheck.date) >= thirtyDaysAgo) {
        moodCheckStatsMap.set('last30', {
          total: moodCheckStatsMap.get('last30').total + 1,
          positive: moodCheck.feeling.feelingType == FeelingType.POSITIVE ? moodCheckStatsMap.get('last30').positive + 1 : moodCheckStatsMap.get('last30').positive,
          negative: moodCheck.feeling.feelingType == FeelingType.NEGATIVE ? moodCheckStatsMap.get('last30').negative + 1 : moodCheckStatsMap.get('last30').negative
        });
      }
      if (new Date(moodCheck.date) >= sevenDaysAgo) {
        moodCheckStatsMap.set('last7', {
          total: moodCheckStatsMap.get('last7').total + 1,
          positive: moodCheck.feeling.feelingType == FeelingType.POSITIVE ? moodCheckStatsMap.get('last7').positive + 1 : moodCheckStatsMap.get('last7').positive,
          negative: moodCheck.feeling.feelingType == FeelingType.NEGATIVE ? moodCheckStatsMap.get('last7').negative + 1 : moodCheckStatsMap.get('last7').negative
        });
      }

    }

    return moodCheckStatsMap;
  }


  getPast7DaysMoodCheckReports(moodChecks: MoodCheck[]): string[] {

    const moodCheckReports: string[] = [];

    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 30);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    for (let moodCheck of moodChecks) {
      if (new Date(moodCheck.date) >= sevenDaysAgo) {
        let moodCheckReport = '';
        moodCheckReport = moodCheckReport + `Efectuat la: ${(new Date(moodCheck.date)).toISOString().slice(0, 10)}\n`;
        moodCheckReport = moodCheckReport + `${moodCheck.feeling.feeling.charAt(0).toUpperCase() + moodCheck.feeling.feeling.slice(1)} - ${moodCheck.feeling.feelingType}\n`;
        moodCheckReport = moodCheckReport + `Factori\n\t\t\t`;
        for (let factor of moodCheck.factors) {
          moodCheckReport = moodCheckReport + '\u200B\t' + `${factor.charAt(0).toUpperCase() + factor.slice(1)}\n`;
        }
        moodCheckReport = moodCheckReport + '\n\n';
        moodCheckReports.push(moodCheckReport);
      }

    }

    return moodCheckReports;
  }

  getPast7DaysNotes(notes: ClientNote[]): string[] {
    const noteReports: string[] = [];

    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    for (let note of notes) {
      if (note.date.toDate() >= sevenDaysAgo) {
        let noteReport = '';
        noteReport = noteReport + `Data: ${note.date.toDate().toISOString().slice(0, 10)}\n`;
        noteReport = noteReport + note.note;
        noteReport = noteReport + '\n\n\n';
        noteReports.push(noteReport);
      }

    }

    return noteReports;
  }

}
