import { Component } from '@angular/core';
import { DashboardState } from '../../store/dashboard.interface';
import { DashboardStore } from '../../store/dashboard.store';
import { ClientProfile } from 'src/app/models/client-profile';
import { FormControl } from '@angular/forms';
import { combineLatest, map, startWith } from 'rxjs';
import { InviteClientDialogComponent } from '../../components/invite-client-dialog/invite-client-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AuthStore } from 'src/app/auth/store/auth.store';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent {

  clientProfiles$ = this.dashboardStore.clientProfiles$;
  filteredClientProfiles$;
  searchControl = new FormControl('');
  accountActive: boolean | undefined = false;

  constructor(
    private dashboardStore: DashboardStore, private dialog: MatDialog,
    private authStore: AuthStore
  ) {
    this.authStore.respiroUser$.subscribe(respiroUser => {
      this.accountActive = respiroUser?.active;
    });
    this.filteredClientProfiles$ = combineLatest([
      this.clientProfiles$,
      this.searchControl.valueChanges.pipe(startWith(this.searchControl.value))
    ]).pipe(
      map(([clientProfiles, searchText]) => {
        if (searchText != null) {
          return clientProfiles.filter(clientProfile =>
            (clientProfile.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
            clientProfile.lastName?.toLowerCase().includes(searchText.toLowerCase()))
          );
        }
        return [];
      })
    );
  }

  onClientCardClick(clientProfile: ClientProfile) {
    this.dashboardStore.changeSelectedClientProfile(clientProfile);
  }

  openInviteDialog(): void {
    const dialogRef = this.dialog.open(InviteClientDialogComponent);
  
    dialogRef.afterClosed().subscribe(result => {});
  }

}
