import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { UserCredentials } from '../store/auth.interface';
import { Observable, from, tap } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { RespiroUser } from 'src/app/models/respiro-user';

@Injectable({
  providedIn: 'root'
})
export class AuthFacadeService {

  constructor(private authService: AuthService) { }

  signIn(credentials: UserCredentials): Observable<firebase.default.auth.UserCredential> {
    return from(this.authService.signIn(credentials));
  }

  isAuthenticated(): Observable<firebase.default.User | null> {
    return this.authService.user$;
  }

  logOut(): Observable<void> {
    return from(this.authService.signOut());
  }

  loginWithGoogle() {
    return this.authService.loginWithGoogle();
  }

  signUp(email: string, password: string): Promise<firebase.default.auth.UserCredential> {
    return this.authService.signUp(email, password);
  }
}
