import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseStorageFacadeService } from 'src/app/facades/firebase-storage-facade.service';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { ClientProfile } from 'src/app/models/client-profile';

@Component({
  selector: 'app-docs-tab',
  templateUrl: './docs-tab.component.html',
  styleUrls: ['./docs-tab.component.scss']
})
export class DocsTabComponent {

  @Input() clientProfile!: ClientProfile;
  @Input() docs: any[] | null = [];
  selectedFile: any = null;

  constructor(private firebaseStorageFacade: FirebaseStorageFacadeService,
    private firestoreFacade: FirestoreFacadeService) {}

  ngOnInit() {
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] ?? null;
    if (this.selectedFile.size < 25000000)
      this.firebaseStorageFacade.uploadFile(event, this.clientProfile.id);
    else
      alert('Dimensiunea fisierului depaseste 25MB');
  }

  downloadDoc(doc: any) {
    const downloadURL = doc.downloadURL; 
    const a = document.createElement('a');
    a.href = downloadURL;
    a.target = '_blank';
    a.download = doc.name; // Use the name from the doc object as the default filename
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  deleteDoc(doc: any, event: Event) {
    event.stopPropagation();
    this.firebaseStorageFacade.deleteFile(doc.downloadURL).then(() => {
      console.log('File deleted successfully');
      this.firestoreFacade.deleteClientDoc(this.clientProfile.id, doc).then(
        () => {

        }
      ).catch((error) => {
        console.error('Error deleting file:', error);
    });
    }).catch((error) => {
        console.error('Error deleting file:', error);
    });
  }

}
