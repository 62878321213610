<form [formGroup]="form">
    <div class="name-container">
        <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName">
        </mat-form-field>
    </div>

    <mat-form-field appearance="outline">
        <mat-label>Birthdate</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="birthdate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Options</mat-label>
        <input matInput formControlName="dropdown" [matAutocomplete]="auto">

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
            <mat-option *ngFor="let item of filteredDropdownList | async" [value]="item" [ngClass]="{'highlighted-option': isSelected(item)}">
                {{ item }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <!-- Display selected items as chips -->
    <mat-chip-listbox>
        <mat-chip *ngFor="let item of selectedItems" (removed)="removeItem(item)">
            {{ item }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
    </mat-chip-listbox>

    <mat-form-field appearance="outline">
        <mat-label>Years of Experience</mat-label>
        <input matInput formControlName="yearsOfExperience">
    </mat-form-field>
</form>