<div class="main-container">
    <div class="client-details-container">
        <div class="top-row-container m-0">
            <div class="about">
                <div class="m-font-size bold">Despre {{ clientProfile.firstName }}</div>
                <div class="disclaimer">
                    <i class="material-icons">info_outline</i>
                    Aceste date au rolul de a te ajuta în procesul terapeutic și nu vor fi vizibile clientului
                </div>
            </div>
            <div class="icon-col">
                <button class="generate-report-button accent-button-outline" mat-button (click)="openDialog()">
                    <div class="flex-container align-center">
                        <!-- <img src="../../../assets/icons/plus_icon.svg" alt="Guided Meditations Icon" class="menu-icon plus-icon"> -->
                        <svg class="edit-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1080" zoomAndPan="magnify" viewBox="0 0 810 809.999993" height="1080" preserveAspectRatio="xMidYMid meet" version="1.0">
                            <path class="fill-accent" fill="#000000" d="M 709.402344 11.496094 C 702.085938 4.179688 691.894531 0 681.707031 0 C 671.253906 0 661.324219 4.179688 654.011719 11.496094 L 354.308594 310.933594 C 347.515625 317.726562 343.597656 326.875 343.074219 336.542969 L 337.847656 430.867188 C 337.324219 441.839844 341.507812 452.816406 349.34375 460.65625 C 356.660156 467.96875 366.589844 472.152344 377.042969 472.152344 L 379.132812 472.152344 L 473.457031 466.925781 C 482.863281 466.402344 492.011719 462.222656 498.804688 455.429688 L 798.503906 156.25 C 805.820312 148.675781 810 138.746094 810 128.554688 C 810 118.101562 805.820312 108.175781 798.503906 100.597656 Z M 709.402344 11.496094 " fill-opacity="1" fill-rule="nonzero"/>
                            <path class="fill-accent" fill="#000000" d="M 132.328125 810 L 566.980469 810 C 639.957031 810 699.308594 750.636719 699.308594 677.671875 L 699.308594 409.734375 C 699.308594 388.082031 681.753906 370.539062 660.113281 370.539062 C 638.476562 370.539062 620.921875 388.082031 620.921875 409.734375 L 620.921875 677.671875 C 620.921875 707.410156 596.730469 731.613281 566.980469 731.613281 L 132.328125 731.613281 C 102.578125 731.613281 78.386719 707.410156 78.386719 677.671875 L 78.386719 243.019531 C 78.386719 213.28125 102.578125 189.078125 132.328125 189.078125 L 400.277344 189.078125 C 421.917969 189.078125 439.472656 171.535156 439.472656 149.886719 C 439.472656 128.234375 421.917969 110.691406 400.277344 110.691406 L 132.328125 110.691406 C 59.351562 110.691406 0 170.054688 0 243.019531 L 0 677.671875 C 0 750.636719 59.351562 810 132.328125 810 Z M 132.328125 810 " fill-opacity="1" fill-rule="nonzero"/>
                        </svg>
        
        
        
                        <span class="s-font-size">{{ 'edit' | translate }}</span>
                    </div>
                </button>
            </div>
            <div class="content-col">
                <!-- <div class="content-title extra-bold xl-font-size">
                    Description
                </div>
                <div class="content-text large">
                    {{clientProfile.description}}
                </div> -->
            </div>
        </div>

        <div class="client-info-row flex-container">
            <div class="client-info-attr">
                <span class="attr-value bold m-font-size">{{'clientProfile.essentials.gender' | translate}}</span>
                <span class="attr-title bold m-font-size" *ngIf="clientProfile.gender">{{clientProfile.gender}}</span>
            </div>
            <div class="client-info-attr ms-2">
                <span class="attr-value bold m-font-size">{{'clientProfile.essentials.birthday' | translate}}</span>
                <span class="attr-title bold m-font-size" *ngIf="clientProfile.birthday">{{clientProfile.birthday.toDate() | date: 'dd-MM-yyyy'}}</span>
            </div>
            <div class="client-info-attr ms-2">
                <span class="attr-value bold m-font-size">{{'clientProfile.essentials.employment' | translate}}</span>
                <span class="attr-title bold m-font-size" *ngIf="clientProfile.employment">{{clientProfile.employment}}</span>
            </div>
        </div>

        <div class="description-container">
            <!-- <div class="icon-col">
                <img src="../../../assets/icons/document_icon.svg" alt="Person Icon" class="menu-icon">
            </div> -->
            <div class="content-col">
                <div class="content-title bold m-font-size">
                    <!-- <img src="../../../assets/icons/document_icon.svg" alt="Person Icon" class="menu-icon"> -->
                    {{'client.description' | translate}}
                </div>
                <div class="content-text m-font-size preserve-newlines ">
                    {{clientProfile.description}}
                </div>
            </div>
        </div>

        <div class="objectives-symptoms-container">
            
            <div class="description-container objectives-container">
                <div class="content-col">
                    <div class="content-title bold m-font-size">
                        <!-- <mat-icon class="icon" aria-hidden="false" aria-label="Objectives icon" fontIcon="adjust"></mat-icon> -->
                        {{'client.objectives' | translate}}
                    </div>
                    <div class="content-text m-font-size preserve-newlines">
                        <div *ngFor="let objective of clientProfile.objectives" class="objective-container">
                            &#8226; {{objective}}
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="description-container sympotms-container">
                <div class="content-col">
                    <div class="content-title bold m-font-size">
                        <!-- <mat-icon class="icon" aria-hidden="false" aria-label="Symptoms icon" fontIcon="crisis_alert"></mat-icon> -->
                        {{'client.symptoms' | translate}}
                    </div>
                    <div class="content-text m-font-size preserve-newlines">
                        <div *ngFor="let symptom of clientProfile.symptoms" class="objective-container">
                            &#8226; {{symptom}}
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="mood-charts-container">
        <div echarts [options]="chartOption" [merge]="mergeOptionNegative" class="demo-chart"></div>
        <div echarts [options]="chartOption2" [merge]="mergeOptionPositive" class="demo-chart"></div>
    </div>

    <button class="alert-button-outline" mat-button (click)="openRemoveClientDialog()">
        <div class="flex-container align-center">
            <span class="s-font-size">{{'clientProfile.essentials.remove' | translate}}</span>
        </div>
    </button>
</div>
