<div class="main-container">
    <div class="button-row">
        <button class="generate-report-button accent-button-outline" mat-button (click)="fileInput.click()">
            <div class="flex-container align-center">
                <span class="s-font-size">{{'clientProfile.docs.upload' | translate}}</span>
            </div>
        </button>
        <input hidden (change)="onFileSelected($event)" #fileInput type="file">
    </div>

    <div class="notes-container" *ngIf="docs">
        <!-- <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
<input hidden (change)="onFileSelected($event)" #fileInput type="file"> -->
        <!-- <mat-card class="note-card upload-card">
            <mat-card-header>
                <mat-card-title>
                    <div class="flex-container align-center">
                        <span class="m-font-size bold">Upload File</span>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="upload-document-content">
                <svg class="upload-document-icon m-auto" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1080" zoomAndPan="magnify" viewBox="0 0 810 809.999993" height="1080" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="4ad3fe4024">
                    <path d="M 111.925781 0 L 698.425781 0 L 698.425781 810 L 111.925781 810 Z M 111.925781 0 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#4ad3fe4024)">
                    <path class="primary-fill" fill="#000000" d="M 680.273438 199.851562 L 498.738281 18.429688 C 487.566406 7.265625 470.808594 0.285156 454.050781 0.285156 L 174.765625 0.285156 C 139.855469 0.285156 111.925781 28.199219 111.925781 63.085938 L 111.925781 746.914062 C 111.925781 781.800781 139.855469 809.714844 174.765625 809.714844 L 635.589844 809.714844 C 670.5 809.714844 698.425781 781.800781 698.425781 746.914062 L 698.425781 243.113281 C 698.425781 226.367188 691.445312 211.015625 680.273438 199.851562 Z M 519.683594 204.039062 C 505.71875 204.039062 494.546875 192.875 494.546875 178.917969 L 494.546875 50.527344 L 648.15625 204.039062 Z M 519.683594 204.039062 " fill-opacity="1" fill-rule="nonzero"/></g>
                </svg>
            </mat-card-content>
        </mat-card> -->
        <mat-card *ngFor="let doc of docs" class="note-card" (click)="downloadDoc(doc)">
            <mat-card-header>
                <mat-card-title>
                    <div class="flex-container">
                        <!-- <img src="../../../assets/icons/plus_icon.svg" alt="Guided Meditations Icon" class="menu-icon plus-icon"> -->
                        <svg class="document-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="720" zoomAndPan="magnify" viewBox="0 0 540 809.999993" height="1080" preserveAspectRatio="xMidYMid meet" version="1.0"><defs>
                            <clipPath id="c3cd13d372"><path d="M 0 31.636719 L 540 31.636719 L 540 778.636719 L 0 778.636719 Z M 0 31.636719 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#c3cd13d372)">
                                <path class="primary-fill" fill="#0096c7" d="M 524 215.945312 L 356.585938 48.632812 C 346.28125 38.335938 330.828125 31.898438 315.371094 31.898438 L 57.808594 31.898438 C 25.613281 31.898438 -0.144531 57.640625 -0.144531 89.816406 L -0.144531 720.457031 C -0.144531 752.628906 25.613281 778.375 57.808594 778.375 L 482.792969 778.375 C 514.988281 778.375 540.742188 752.628906 540.742188 720.457031 L 540.742188 255.839844 C 540.742188 240.398438 534.304688 226.238281 524 215.945312 Z M 375.898438 219.804688 C 363.023438 219.804688 352.71875 209.511719 352.71875 196.640625 L 352.71875 78.234375 L 494.378906 219.804688 Z M 375.898438 219.804688 " fill-opacity="1" fill-rule="nonzero"/></g>
                        </svg>
        
                        <span class="m-font-size bold">{{doc.name}}</span>
                        
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="document-info s-font-size">
                <div class="document-details-container">
                    <span style="display: block;" *ngIf="doc.date">{{doc.date.toDate() | date: 'dd-mm-yyyy'}}</span>
                    <!-- <span style="display: block;">{{doc.type}}</span> -->
                    <span>{{doc.size}}b</span>
                </div>

                <mat-icon class="delete-icon" aria-hidden="false" aria-label="Delete icon" fontIcon="delete" (click)="deleteDoc(doc, $event)"></mat-icon>
            </mat-card-content>
        </mat-card>
    </div>
</div>
