<div class="main-container">
    <!-- <app-client-card routerLink="../client"></app-client-card> -->
    <div class="activation-message" *ngIf="!accountActive">
        <app-activation-message></app-activation-message>
    </div>
    <div *ngIf="accountActive" class="filters-container">
        <!-- Search bar -->
        <div class="search-bar">
            <mat-form-field appearance="outline" class="search-field">
                <mat-label>Search</mat-label>
                <input matInput [formControl]="searchControl" placeholder="Search clients...">
            </mat-form-field>
        </div>

        <button class="add-client-button accent-button-outline" mat-button (click)="openInviteDialog()">
            <div class="flex-container align-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="add-client-icon padding-small" xmlns:xlink="http://www.w3.org/1999/xlink" width="1080" zoomAndPan="magnify" viewBox="0 0 810 809.999993" height="1080" preserveAspectRatio="xMidYMid meet" version="1.0">
                    <path class="secondary-accent-color-fill" fill="#ffffff" d="M 405 0 C 181.597656 0 0 181.597656 0 405 C 0 628.402344 181.597656 810 405 810 C 628.402344 810 810 628.402344 810 405 C 810 181.597656 628.402344 0 405 0 Z M 632.585938 444.195312 L 444.195312 444.195312 L 444.195312 632.582031 C 444.195312 654.007812 426.6875 671.777344 405 671.777344 C 383.3125 671.777344 365.804688 654.007812 365.804688 632.582031 L 365.804688 444.195312 L 177.414062 444.195312 C 155.988281 444.195312 138.222656 426.6875 138.222656 405 C 138.222656 383.3125 155.988281 365.804688 177.414062 365.804688 L 365.804688 365.804688 L 365.804688 177.414062 C 365.804688 155.988281 383.3125 138.222656 405 138.222656 C 426.6875 138.222656 444.195312 155.988281 444.195312 177.414062 L 444.195312 365.804688 L 632.585938 365.804688 C 654.011719 365.804688 671.777344 383.3125 671.777344 405 C 671.777344 426.6875 654.011719 444.195312 632.585938 444.195312 Z M 632.585938 444.195312 " fill-opacity="1" fill-rule="nonzero"/>
                </svg>
                {{'menu.addClient' | translate}}
            </div>
        </button>
    </div>

    <div *ngIf="accountActive" class="client-cards-container">
        <app-client-card class="client-card" *ngFor="let clientProfile of filteredClientProfiles$ | async" [routerLink]="['../client', clientProfile.clientId]" [clientProfile]="clientProfile" (click)="onClientCardClick(clientProfile)"></app-client-card>
    </div>
    
</div>