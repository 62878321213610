import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Homework } from '../models/homework';

@Injectable({
  providedIn: 'root'
})
export class EmailFacadeService {

  constructor(private fns: AngularFireFunctions) {
    // this.fns.useFunctionsEmulator('http://localhost:5001');
  }

  sendEmail(to: string | undefined, homework: Homework) {
    const callable = this.fns.httpsCallable('sendEmail');
    return callable({ to, homework });
  }

  sendInviteEmail(to: string, therapistName: string) {
    const callable = this.fns.httpsCallable('sendInviteEmail');
    return callable({ to, therapistName });
  }

  sendNewAccountEmail(to: string) {
    const callable = this.fns.httpsCallable('sendNewAccountEmail');
    return callable({ to });
  }
}
