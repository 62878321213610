import { Component, EventEmitter, Output } from '@angular/core';
import { AuthStore } from '../store/auth.store';
import { UserCredentials } from '../store/auth.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthFacadeService } from '../facades/auth-facade.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  @Output() loginMode = new EventEmitter<boolean>();

  loginForm: FormGroup;
  user$ = this.authStore.user$;
  loading$ = this.authStore.loading$;
  error$ = this.authStore.error$;

  constructor(private authStore: AuthStore,
    private fb: FormBuilder, private authFacade: AuthFacadeService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onLogin() {
    const credentials: UserCredentials = this.loginForm.value;
    this.authStore.loginUser(credentials);
  }

  onLoginWithGoogle() {
    this.authStore.loginUserWithGoogle();
  }

  onSwitchView() {
    this.loginMode.emit(false);
  }

}
