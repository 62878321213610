export function generateShadesOfOrange(baseColor: any, numShades: number) {
    const shades = [];
    const baseRed = parseInt(baseColor.slice(1, 3), 16);   // Extract the red component
    const baseGreen = parseInt(baseColor.slice(3, 5), 16); // Extract the green component
    const baseBlue = parseInt(baseColor.slice(5, 7), 16);  // Extract the blue component

    for (let i = 1; i <= numShades; i++) {
        const blue = Math.min(baseBlue + i * 15, 255);   // Increase red (adjust increment as needed)
        const green = Math.min(baseGreen + i * 15, 255); // Increase green (adjust increment as needed)

        const shade = `#FF${green.toString(16)}${blue.toString(16)}`;
        shades.push(shade);
    }

    return shades;
}

export function generateShadesOfGreen(baseColor: any, numShades: number) {
    const shades = [];
    const baseRed = parseInt(baseColor.slice(1, 3), 16);   // Extract the red component
    const baseGreen = parseInt(baseColor.slice(3, 5), 16); // Extract the green component
    const baseBlue = parseInt(baseColor.slice(5, 7), 16);  // Extract the blue component

    for (let i = 1; i <= numShades; i++) {
        const red = Math.min(baseRed + i * 15, 255);   // Increase red (adjust increment as needed)
        const blue = Math.min(baseBlue + i * 15, 255); // Increase green (adjust increment as needed)

        const shade = `#${red.toString(16)}FF${blue.toString(16)}`;
        shades.push(shade);
    }

    return shades;
}
