import { Timestamp } from "@firebase/firestore";
import { IJournalTemplate } from "../core/constants/journal-templates";
import { Meditation } from "./meditation";
import { BreathingExercise } from "./breathing_exercise";

export interface Homework {
    deadline?: Date | Timestamp | any;
    title: string;
    clientId: string;
    therapistId: string;
    description: string;
    exercises: Exercise[];
    isShared: boolean;
    id?: string;
}


export interface Exercise {
    type: ExerciseType;
    journalTemplate: IJournalTemplate;
    meditation: Meditation;
    breathingExercise: BreathingExercise;
    description: string;
    done: boolean;
}

export enum ExerciseType {
    MEDITATION="Meditation",
    BREATHING_EXERCISE="Breathing Exercise",
    JOURNAL="Journal",
    MOOD_CHECK="Mood Check",
    OTHER="Other"
}

export enum JournalTemplate {
    TEMPLATE1="Template 1",
    TEMPLATE2="Template 2",
    NO_TEMPLATE="No Template"
}