<div class="main-container">
    <div class="title-row">
        <span class="l-font-size bold">{{'home.yourClients' | translate}}</span>
    </div>

    <div class="stats-row">
        <!-- <span>
            <span class="positive">+3</span>
            New clients this month
        </span>

        <span>
            <span class="negative">-0</span>
            Clients lost this month
        </span> -->
    </div>

    <div class="clients-list">
        <div class="client-row flex-container align-center" *ngFor="let clientProfile of clientProfiles$ | async">
            <div class="icon-name-container">
                <img src="../../../assets/icons/person_icon.svg" alt="Person Icon" class="client-icon">
                <div class="name-email-container">
                    <span class="m-font-size bold client-name"
                     [routerLink]="['../client', clientProfile.clientId]" (click)="onClientCardClick(clientProfile)">{{ clientProfile.firstName + ' ' + clientProfile.lastName }}</span>
                    <span class="s-font-size">{{ clientProfile.joinedOn.toDate() | date: 'dd-MM-yyyy' }}</span>
                </div>
            </div>
            <div class="tag-container">
                <button class="accent-button-outline" mat-button>
                    <div class="flex-container align-center">
                        <span class="s-font-size" (click)="onAddHomework(clientProfile.clientId)" [routerLink]="['../create-homework']">{{'home.addHomework' | translate}}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>

</div>
