import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { HomeworkState, HomeworkStatus } from "./homework.state";
import { FirestoreFacadeService } from "src/app/facades/firestore-facade.service";
import { map, take } from "rxjs";
import { Homework } from "src/app/models/homework";



@Injectable()
export class HomeworkStore extends ComponentStore<HomeworkState> {
    
    constructor(private firestoreFacade: FirestoreFacadeService) {
        super({homework: null, status: HomeworkStatus.DRAFT})
    }

    readonly homework$ = this.select(state => state.homework);
    readonly status$ = this.select(state => state.status);

    currentState() {
        return this.state;
    }

    init(homework: Homework) {
        this.patchState({
            homework: homework
        });
    }

    createUnassignedHomework() {
        localStorage.setItem('homework', JSON.stringify({
                title: 'New Homework',
                description: '',
                isShared: false,
                morningExercises: [],
                noonExercises: [],
                eveningExercises: []
            }));
    }

    createHomework(clientId: string) {
        localStorage.setItem('homework', JSON.stringify({
                clientId: clientId,
                title: 'New Homework',
                description: '',
                isShared: false,
                morningExercises: [],
                noonExercises: [],
                eveningExercises: []
            }));
    }

    createHomeworkNoClient() {
        localStorage.setItem('homework', JSON.stringify({
                clientId: null,
                title: 'New Homework',
                description: '',
                isShared: false,
                morningExercises: [],
                noonExercises: [],
                eveningExercises: []
            }));
    }

    editHomework(homework: Homework) {
        const deadline = (homework.deadline as any).toDate();
        homework.deadline = deadline;
        localStorage.setItem('homework', JSON.stringify(homework));
    }

    saveHomework() {
        this.patchState({status: HomeworkStatus.SAVED})
    }

    shareHomework() {
        this.setState((state) => {
            const homework = state.homework;
            if (homework)
                homework.isShared = true;
            return {
            ...state,
            homework: homework
            }
        });
    }

    updateHomework(homework: Homework) {
        this.setState((state) => ({
          ...state,
          homework: { ...state.homework, ...homework },
        }));
      }

}