import { Component } from '@angular/core';
import { AuthStore } from '../store/auth.store';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent {
  
  loginMode: boolean = true;

  constructor(private authStore: AuthStore) {}

  switchMode(loginMode: boolean) {
    this.loginMode = loginMode;
  }

}
