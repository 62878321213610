import { Component } from '@angular/core';
import { ClientInvitesStore } from '../../store/clientInvites.store';
import { ClientInvite } from '../../store/clientInvites.interface';
import { Observable, map } from 'rxjs';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-invites',
  templateUrl: './invites.component.html',
  styleUrls: ['./invites.component.scss']
})
export class InvitesComponent {

  displayedColumns: string[] = ['clientEmail', 'status', 'sentOn'];

  clientInvites: ClientInvite[] = [];

  constructor(private clientInvitesStore: ClientInvitesStore) {
    this.clientInvitesStore.initData();
    this.clientInvitesStore.clientInvites$
      .pipe(map(data => data || []))
      .subscribe(data => this.clientInvites = data);
  }

}
