import { Component, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { Observable, takeLast, tap } from 'rxjs';
import { ContentType } from 'src/app/core/constants/content-type';
import { ClientProfile } from 'src/app/models/client-profile';
import { HistoryItem } from 'src/app/models/history-item';

@Component({
  selector: 'app-activity-summary',
  templateUrl: './activity-summary.component.html',
  styleUrls: ['./activity-summary.component.scss']
})
export class ActivitySummaryComponent {

  @Input() activities: HistoryItem[] | null | undefined;
  @Input() clientProfiles!: ClientProfile[] | null;

  sharedActivities: HistoryItem[] | undefined;

  activitiesMap = new Map<number, number>([
    [ContentType['Meditation'], 0],
    [ContentType['Breathing Exercise'], 0],
    [ContentType['Journal'], 0],
    [ContentType['Mood Check'], 0],
  ]);

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activities'] && changes['activities'].currentValue !== null) {
      // activities has a non-null value, so do something with it
      this.sharedActivities = this.activities?.filter(activity => {
        const userSharesHistory = this.clientProfiles?.find(clientProfile => clientProfile.clientId === activity.userId)?.exerciseShared;
        return userSharesHistory;
      })
      .sort((a, b) => b.timestamp - a.timestamp)
      this.countActivities();
    }
  }

  getActivityTitle(contentType: number): string {
    return ContentType[contentType];
  }

  getTimestampDate(timestamp: number): Date {
    return new Date(timestamp);
  }

  getClientName(clientId: string): string | undefined {
    const clientProfile = this.clientProfiles!.find(clientProfile => clientProfile.clientId === clientId);
    return clientProfile?.firstName + ' ' + clientProfile?.lastName;
  }

  countActivities() {
    if (this.sharedActivities && this.sharedActivities.length > 0) {
      this.activitiesMap = new Map<number, number>([
        [ContentType['Meditation'], 0],
        [ContentType['Breathing Exercise'], 0],
        [ContentType['Journal'], 0],
        [ContentType['Mood Check'], 0],
      ]);
      for (let activity of this.sharedActivities) {
        const currVal = this.activitiesMap.get(activity.contentType);
        if (currVal != undefined) {
          this.activitiesMap.set(activity.contentType, currVal + 1);
        }
      }
    }
  }

  getContentType(type: keyof typeof ContentType): number {
    return ContentType[type];
  }

}
