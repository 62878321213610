import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, take, takeLast, takeUntil, takeWhile, tap } from 'rxjs';
import { AuthFacadeService } from 'src/app/auth/facades/auth-facade.service';
import { AuthStore } from 'src/app/auth/store/auth.store';
import { FirebaseStorageFacadeService } from 'src/app/facades/firebase-storage-facade.service';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { RespiroUser } from 'src/app/models/respiro-user';
import { ChangesSavedSnackbarComponent } from './components/changes-saved-snackbar/changes-saved-snackbar.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  imagePreview: ArrayBuffer | string = '';
  loading: boolean = true;

  currentStep: number = 1;
  maxStep: number = 3;  // assuming 3 steps, adjust as needed

  profileForm!: FormGroup;

  therapist!: RespiroUser;

  constructor(private fb: FormBuilder, private authStore: AuthStore,
    private storageFacade: FirebaseStorageFacadeService, private firestoreFacade: FirestoreFacadeService,
    private _snackBar: MatSnackBar, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.authStore.respiroUser$.pipe(
      tap(data => {
        if (data) {
          this.therapist = data;
          let experience = 5;
          let title = '';
          if (data.experience)
            experience = data.experience;
          if (data.title)
            title = data.title;
          if (data.profilePicture)
            this.imagePreview = data.profilePicture;
          this.profileForm = this.fb.group({
            profilePicture: [],
            firstName: [data.firstName, Validators.required],
            lastName: [data.lastName, Validators.required],
            experience: [experience, [Validators.required, Validators.min(0), Validators.max(50)]],
            title: [title],
            city: [data.city],
            description: [data.description]
          });

          this.loading = false;
        }

      })
    ).subscribe();
  }

  onFileSelected(event: any) {
    this.changeProfileImagePreview(event.target.files[0]);
    this.profileForm.patchValue({
      profilePicture: event.target.files[0] ?? null
    });
    this.profileForm.controls['profilePicture'].markAsTouched();
  }

  onSubmit() {
    if (this.profileForm.valid) {
      this.therapist.firstName = this.profileForm.value['firstName'];
      this.therapist.lastName = this.profileForm.value['lastName'];
      this.therapist.experience = this.profileForm.value['experience'];
      this.therapist.title = this.profileForm.value['title'];
      this.therapist.description = this.profileForm.value['description'];
      this.therapist.city = this.profileForm.value['city'];
      if (this.profileForm.controls['profilePicture'].touched) {
        this.profileForm.controls['profilePicture'].markAsUntouched();
        fetch(this.croppedImg).then(res => res.blob())
                                .then(blob => {
                                  this.storageFacade.uploadTherapistProfilePicture(blob, this.therapist.id).subscribe(downloadUrl => {
                                    this.therapist.profilePicture = downloadUrl;
                                    this.firestoreFacade.updateTherapistProfile(this.therapist)
                                      .subscribe(data => this.openSnackBar());
                                  });
                                });
      } else {
        this.firestoreFacade.updateTherapistProfile(this.therapist)
          .subscribe(data => this.openSnackBar());
      }
    }

  }

  changeProfileImagePreview(file: File) {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e && e.target && e.target.result)
          this.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  nextStep() {
    if (this.currentStep < this.maxStep) {
      this.currentStep++;
    }
  }

  prevStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  submit() {
    // handle form submission
  }

  openSnackBar() {
    this._snackBar.openFromComponent(ChangesSavedSnackbarComponent, {
      duration: 3 * 1000,
    });
  }

  imgChangeEvt: any = '';
  croppedImg: any = '';

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
    this.openImageCropDialog(event);
    // event.target.value = '';
  }

  openImageCropDialog(event: any) {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '80vw',
      data: this.imgChangeEvt
    });

    dialogRef.afterClosed().pipe(
      tap(result => {
      if (result != undefined && result.length > 1) {
        this.croppedImg = result;
        this.imagePreview = this.croppedImg;
        this.profileForm.patchValue({
          profilePicture: this.croppedImg ?? null
        });
        this.profileForm.controls['profilePicture'].markAsTouched();
      }
      event.target.value = '';
    })).subscribe();
  }
}
