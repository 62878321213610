<div class="main-container">

    <div class="homework-container" *ngIf="homework$ | async as homework">
        <div class="basic-details-container">

            <div class="title-description-container">
                <div class="title">{{ homework.title }}</div>
                <div class="deadline">
                    <mat-icon class="deadline-icon" aria-hidden="false" aria-label="Deadline icon" fontIcon="today"></mat-icon>
                    {{ homework.deadline.toDate() | date: 'dd MMM yyyy' }}
                </div> 
                <div class="description preserve-newlines">{{ homework.description }}</div>
            </div>

            <div class="deadline-progress-container">
                <div class="progress">
                    <circle-progress
                            [percent]="getCompletedPercent(homework.exercises)"
                            [radius]="30"
                            [outerStrokeWidth]="8"
                            [innerStrokeWidth]="4"
                            [outerStrokeColor]="'#0077b6'"
                            [innerStrokeColor]="'#0096C7'"
                            [animation]="false"
                            [animationDuration]="300"
                            [showBackground]="false"
                            [titleFontSize]="'10'"
                        ></circle-progress>
                    <!-- <mat-icon class="percent-icon" aria-hidden="false" aria-label="Percent icon" fontIcon="percent"></mat-icon>
                    {{ getCompletedPercent(homework.exercises) }} {{ 'homework.done' | translate}} -->
                </div>
            </div>

        </div>

        <div class="exercises-container">
            <div class="exercises-title">{{ 'homework.exercises' | translate }}</div>
            <div class="exercise-container" *ngFor="let exercise of homework.exercises">
                <!-- <div class="exercise-type">{{ exercise.type | translate }}</div> -->
                <div [ngSwitch]="exercise.type">
                    <div class="meditation-exercise-container" *ngSwitchCase="'Meditation'">
                        <div class="exercise-type meditation-title">{{ exercise.type | translate }} - {{ exercise.meditation.title }}</div>
                        <div class="meditation-categories-container">
                            <div class="meditation-category-chip"
                                *ngFor="let category of exercise.meditation.categories">
                                {{ category }}
                            </div>
                        </div>
                        <div class="meditation-description">
                            {{ exercise.meditation.description }}
                        </div>
                    </div>

                    <div class="exercise-type breathing-exercise" *ngSwitchCase="'Breathing Exercise'">
                        <div class="meditation-title">{{ exercise.type | translate }} - {{ exercise.breathingExercise.title }}</div>
                        <div class="meditation-categories-container">
                            <div class="meditation-category-chip" *ngFor="let category of exercise.breathingExercise.categories">
                                {{ category }}
                            </div>
                        </div>
                        <div class="meditation-description">
                            {{ exercise.breathingExercise.description }}
                        </div>
                    </div>

                    <div class="journal-exercise" *ngSwitchCase="'Journal'">
                        <div class="meditation-title">{{ exercise.type | translate }} - {{ exercise.journalTemplate.title }}</div>
                        <div class="meditation-description">
                            {{ exercise.journalTemplate.description }}
                        </div>

                        <div class="journal-sections" *ngIf="exercise.journalTemplate.sections.length > 0">
                            <div class="meditation-description">
                                {{ 'homework.sections' | translate }}
                            </div>
                            <div class="journal-section" *ngFor="let journalSection of exercise.journalTemplate.sections">
                                {{ journalSection }}
                            </div>
                        </div>
                    </div>

                    <div class="exercise-type mood-check-exercise" *ngSwitchCase="'Mood Check'">
                        <!-- {{ exercise.meditation }} -->
                        {{ exercise.type | translate }}
                    </div>

                    <div class="exercise-type other-exercise" *ngSwitchCase="'Other'">
                        {{ exercise.type | translate }}
                        <div class="preserve-newlines">{{ exercise.description }}</div>
                    </div>
                </div>

                <div *ngIf="exercise.done != undefined">
                    <div *ngIf="exercise.done" class="done-container">
                        Done
                        <mat-icon class="done-icon" aria-hidden="false" aria-label="Not done icon" fontIcon="done"></mat-icon>
                    </div>

                    <div *ngIf="!exercise.done" class="done-container">
                        Not Done
                        <mat-icon class="not-done-icon" aria-hidden="false" aria-label="Not done icon" fontIcon="close"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>