import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { HomeworkStore } from '../../store/homework.store';
import { Observable, Subject, catchError, map, of, startWith, switchMap, take, tap } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Exercise, ExerciseType, Homework, JournalTemplate } from 'src/app/models/homework';
import { Meditation } from 'src/app/models/meditation';
import { FirestoreFacadeService } from 'src/app/facades/firestore-facade.service';
import { typeValidator } from './validator/type-validator';
import { BreathingExercise } from 'src/app/models/breathing_exercise';
import { AuthStore } from 'src/app/auth/store/auth.store';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SaveActionStatusSnackbarComponent } from './components/save-action-status-snackbar/save-action-status-snackbar.component';
import { IJournalTemplate, templates } from 'src/app/core/constants/journal-templates';
import { EmailFacadeService } from 'src/app/facades/email-facade.service';

@Component({
  selector: 'app-create-homework',
  templateUrl: './create-homework.component.html',
  styleUrls: ['./create-homework.component.scss']
})
export class CreateHomeworkComponent implements OnDestroy {

  meditationOptions$: Observable<Meditation[]> = this.firestoreFacade.getMeditations();
  filteredMeditations$: Subject<Meditation[]> = new Subject<Meditation[]>();

  breathingExerciseOptions$: Observable<BreathingExercise[]> = this.firestoreFacade.getBreathingExercises();
  filteredBreathingExercises$: Subject<BreathingExercise[]> = new Subject<BreathingExercise[]>();

  clientOptions$: Observable<any[]> = this.getClients();
  filteredClients$: Subject<any[]> = new Subject<any[]>();

  editModeEnabled: boolean = false;
  therapistId: string = '';

  journalTemplates: IJournalTemplate[] = [];

  newExerciseGroup = this.fb.group({
    type: [ExerciseType.MEDITATION, Validators.required],
    journalTemplate: [''],  // Simplified for now.
    meditation: ['', Validators.compose([Validators.required, typeValidator('object')])],  // As string for now
    breathingExercise: [''],  // As string for now
    description: [''],
    searchValue: ['']
  });

  getClients() {
    return this.authStore.respiroUser$.pipe(
      switchMap(respiroUser => {
        if (respiroUser) {
          this.therapistId = respiroUser.id;
          return this.firestoreFacade.getClientProfiles(respiroUser.id);
        }
        return of([]);
      }
      )
    );
  }

  homework$ = this.homeworkStore.homework$;
  homework!: Homework;

  homeworkForm!: FormGroup;

  exerciseTypes = Object.values(ExerciseType);
  isLastMorningExerciseInvalid = false;
  isFormInvalid = false;

  constructor(private homeworkStore: HomeworkStore, private fb: FormBuilder, private firestoreFacade: FirestoreFacadeService,
    private authStore: AuthStore, private router: Router, private emailFacade: EmailFacadeService, private _snackBar: MatSnackBar, private cdRef: ChangeDetectorRef) {
    window.onbeforeunload = () => this.ngOnDestroy();

    this.firestoreFacade.getJournalTemplates().subscribe(data => {
      this.journalTemplates = ([{
        id: "-1",
        title: "Fără Template",
        description: "Fără Template",
        sections: [],
      }]) as IJournalTemplate[];

      this.journalTemplates = this.journalTemplates.concat(data);
    });

    let homeworkJSON = localStorage.getItem('homework');
    if (homeworkJSON) {
      this.homeworkStore.init(JSON.parse(homeworkJSON));
      this.homework = JSON.parse(homeworkJSON);
    }

    this.homeworkForm = this.fb.group({
      client: [null, Validators.required],
      title: ['', Validators.required],
      description: [''],
      deadline: [null, Validators.required],
      morningExercises: this.fb.array([]),
      // noonExercises: this.fb.array([]),
      // eveningExercises: this.fb.array([]),
      searchValue: ['']
    });

    this.homeworkForm?.get('client')?.valueChanges.subscribe(value => {
      this.onClientSearchChange(value);
    });

    this.meditationOptions$.pipe().subscribe(data => this.filteredMeditations$.next(data));
    this.breathingExerciseOptions$.pipe().subscribe(data => this.filteredBreathingExercises$.next(data));
    this.initNewExerciseGroup();
  }

  initHomeworkForm() {
    let homeworkJSON = localStorage.getItem('homework');
    if (homeworkJSON) {
      this.homeworkStore.init(JSON.parse(homeworkJSON));
      this.homework = JSON.parse(homeworkJSON);
    }

    if (this.homework) {

      // this.homeworkForm.patchValue({
      //   title: this.homework.title,
      //   description: this.homework.description,
      //   deadline: this.homework.deadline,
      //   client: null,
      //   morningExercises: this.fb.array([]),
      //   noonExercises: this.fb.array([]),
      //   eveningExercises: this.fb.array([])
      // });

      // console.log(this.homework);
      // this.setExercises('morningExercises', []);
      // this.setExercises('noonExercises', []);
      // this.setExercises('eveningExercises', []);

      this.morningExercises.controls = [];
      // this.noonExercises.controls = [];
      // this.eveningExercises.controls = [];
      this.homeworkForm.reset();

    }
  }

  ngOnInit() {

    if (this.homework) {

      if (!!this.homework.id && this.homework.id != '') {
        this.editModeEnabled = true;
        this.homeworkForm.controls['client'].disable();
      }

      const clientProfile = this.firestoreFacade.getClientProfileByClientId(this.homework.clientId).pipe(
        take(1),
        tap(clientProfile => {
          this.homeworkForm.patchValue({ client: clientProfile[0] })
        })
      ).subscribe();

      this.homeworkForm.patchValue({
        title: this.homework.title,
        description: this.homework.description,
        deadline: this.homework.deadline,
      });

      this.setExercises('morningExercises', this.homework.exercises);
      // this.setExercises('noonExercises', this.homework.noonExercises);
      // this.setExercises('eveningExercises', this.homework.eveningExercises);
    }

    this.checkForExerciseChanges();

  }

  ngAfterViewInit() {

  }

  checkForExerciseChanges() {
    this.morningExercises.valueChanges.subscribe(data => {
      const lastAddedExercise = this.morningExercises.controls.at(-1);
      if (lastAddedExercise?.status === "VALID") {
        this.isLastMorningExerciseInvalid = false;
      }
    })
  }

  get morningExercises(): FormArray {
    return this.homeworkForm.get('morningExercises') as FormArray;
  }

  // get noonExercises(): FormArray {
  //   return this.homeworkForm.get('noonExercises') as FormArray;
  // }

  // get eveningExercises(): FormArray {
  //   return this.homeworkForm.get('eveningExercises') as FormArray;
  // }

  setExercises(fieldName: string, exercises: Exercise[]): void {
    const exerciseFormArray = this.homeworkForm.get(fieldName) as FormArray;

    while (exerciseFormArray.length) {
      exerciseFormArray.removeAt(0);
    }

    exercises.forEach(exercise => {
      const exerciseGroup = this.fb.group({
        type: exercise.type,
        journalTemplate: exercise.journalTemplate,
        meditation: exercise.meditation,
        breathingExercise: exercise.breathingExercise,
        description: exercise.description
      });
      exerciseFormArray.push(exerciseGroup);
    });
  }

  initNewExerciseGroup(): void {
    this.newExerciseGroup = this.fb.group({
      type: [ExerciseType.MEDITATION, Validators.required],
      journalTemplate: [''],  // Simplified for now.
      meditation: ['', Validators.compose([Validators.required, typeValidator('object')])],  // As string for now
      breathingExercise: [''],  // As string for now
      description: [''],
      searchValue: ['']
    });

    this.newExerciseGroup?.get('type')?.valueChanges.subscribe(value => {
      this.newExerciseGroup?.get('meditation')?.clearValidators();
      this.newExerciseGroup?.get('breathingExercise')?.clearValidators();
      this.newExerciseGroup?.get('journalTemplate')?.clearValidators();
      this.newExerciseGroup?.get('description')?.clearValidators();
      switch (value) {
        case ExerciseType.MEDITATION:
          // this.newExerciseGroup?.get('meditation')?.addValidators([Validators.required, typeValidator('object')]);
          break;
        case ExerciseType.BREATHING_EXERCISE:
          // this.newExerciseGroup?.get('breathingExercise')?.addValidators([Validators.required, typeValidator('object')]);
          break;
        case ExerciseType.JOURNAL:
          // this.newExerciseGroup?.get('journalTemplate')?.addValidators([Validators.required, typeValidator('object')]);
          break;
        case ExerciseType.MOOD_CHECK:

          break;
        case ExerciseType.OTHER:
          // this.newExerciseGroup?.get('description')?.addValidators([Validators.required, typeValidator('string')]);
          break;
        default:
          break;
      }

      // exerciseGroup.updateValueAndValidity();
      this.newExerciseGroup?.get('meditation')?.updateValueAndValidity();
      this.newExerciseGroup?.get('breathingExercise')?.updateValueAndValidity();
      this.newExerciseGroup?.get('journalTemplate')?.updateValueAndValidity();
      this.newExerciseGroup?.get('description')?.updateValueAndValidity();
    })

    this.newExerciseGroup?.get('meditation')?.valueChanges.subscribe(value => {
      this.onMeditationSearchChange(value);
    });

    this.newExerciseGroup?.get('breathingExercise')?.valueChanges.subscribe(value => {
      this.onBreathingExerciseSearchChange(value);
    });

    this.newExerciseGroup?.get('type')?.setValue(ExerciseType.MEDITATION);

  }

  addExercise(time: 'morning' | 'noon' | 'evening'): void {
    // const exerciseGroup = this.fb.group({
    //   type: [ExerciseType.MEDITATION, Validators.required],
    //   journalTemplate: [''],  // Simplified for now.
    //   meditation: ['', Validators.compose([Validators.required, typeValidator('object')])],  // As string for now
    //   breathingExercise: [''],  // As string for now
    //   description: [''],
    //   searchValue: ['']
    // });

    // exerciseGroup?.get('type')?.valueChanges.subscribe(value => {
    //   exerciseGroup?.get('meditation')?.clearValidators();
    //   exerciseGroup?.get('breathingExercise')?.clearValidators();
    //   exerciseGroup?.get('journalTemplate')?.clearValidators();
    //   exerciseGroup?.get('description')?.clearValidators();
    //   switch (value) {
    //     case ExerciseType.MEDITATION:
    //       exerciseGroup?.get('meditation')?.addValidators([Validators.required, typeValidator('object')]);
    //       break;
    //     case ExerciseType.BREATHING_EXERCISE:
    //       exerciseGroup?.get('breathingExercise')?.addValidators([Validators.required, typeValidator('object')]);
    //       break;
    //     case ExerciseType.JOURNAL:
    //       exerciseGroup?.get('journalTemplate')?.addValidators([Validators.required, typeValidator('object')]);
    //       break;
    //     case ExerciseType.MOOD_CHECK:

    //       break;
    //     case ExerciseType.OTHER:
    //       exerciseGroup?.get('description')?.addValidators([Validators.required, typeValidator('string')]);
    //       break;
    //     default:
    //       break;
    //   }

    //   // exerciseGroup.updateValueAndValidity();
    //   exerciseGroup?.get('meditation')?.updateValueAndValidity();
    //   exerciseGroup?.get('breathingExercise')?.updateValueAndValidity();
    //   exerciseGroup?.get('journalTemplate')?.updateValueAndValidity();
    //   exerciseGroup?.get('description')?.updateValueAndValidity();
    // })

    // exerciseGroup?.get('meditation')?.valueChanges.subscribe(value => {
    //   this.onMeditationSearchChange(value);
    // });

    // exerciseGroup?.get('breathingExercise')?.valueChanges.subscribe(value => {
    //   this.onBreathingExerciseSearchChange(value);
    // });

    // exerciseGroup?.get('type')?.setValue(ExerciseType.MEDITATION);

    // switch (time) {
    //   case 'morning':
    //     const lastMorningExercise = this.morningExercises.controls.at(-1);
    //     if (lastMorningExercise?.invalid) {
    //       this.isLastMorningExerciseInvalid = true;
    //     } else {
    //       this.morningExercises.push(exerciseGroup);
    //     }
    //     break;
    // }

    if (!this.isNewExerciseValid()) {
      // this.isLastMorningExerciseInvalid = true;
    } else {
      this.morningExercises.push(this.newExerciseGroup);
      this.initNewExerciseGroup();
    }

  }

  isNewExerciseValid() {
    const exerciseType = this.newExerciseGroup.get('type');
    if (exerciseType == undefined) return false;
    switch (exerciseType.value) {
      case ExerciseType.BREATHING_EXERCISE:
        return typeof this.newExerciseGroup.get('breathingExercise')?.value === 'object';
        break;
      case ExerciseType.MEDITATION:
        return typeof this.newExerciseGroup.get('meditation')?.value === 'object';
        break;
      case ExerciseType.JOURNAL:
        return typeof this.newExerciseGroup.get('journalTemplate')?.value === 'object';
        break;
      case ExerciseType.MOOD_CHECK:
        return true;
        break;
      case ExerciseType.OTHER:
        return true;
        break;
      default:
        return false;
    }
  }

  ngOnDestroy(): void {
    this.homework$.pipe(
      take(1),
      tap(homework => {
        const homework2: Homework = this.createHomeworkFromForm();
        localStorage.setItem('homework', JSON.stringify(homework2));
      })
    ).subscribe();
  }

  saveExercise(i: any, time: any) {



  }

  cancelExercise(i: any, time: 'morning' | 'noon' | 'evening') {
    switch (time) {
      case 'morning':
        this.morningExercises.removeAt(i);
        break;
      // case 'noon':
      //   this.noonExercises.removeAt(i);
      //   break;
      // case 'evening':
      //   this.eveningExercises.removeAt(i);
      //   break;
    }
  }

  onMeditationSearchChange(value: any) {
    this.meditationOptions$.pipe(
      tap(data => console.log(data)),
      map(options =>
        options.filter(option => {
          if (value == null || value == undefined) return true;
          if (typeof value === "string") return option.title.toLowerCase().includes(value.toLowerCase());
          return option.title.toLowerCase().includes(value.title.toLowerCase());
        }
        )
      )
    ).subscribe(filtered => this.filteredMeditations$.next(filtered));
  }

  onBreathingExerciseSearchChange(value: any) {
    this.breathingExerciseOptions$.pipe(
      map(options =>
        options.filter(option => {
          if (value == null || value == undefined) return true;
          if (typeof value === "string") return option.title.toLowerCase().includes(value.toLowerCase());
          return option.title.toLowerCase().includes(value.title.toLowerCase());
        }
        )
      )
    ).subscribe(filtered => this.filteredBreathingExercises$.next(filtered));
  }

  onClientSearchChange(value: any) {
    this.clientOptions$.pipe(
      map(options =>
        options.filter(option => {
          if (value == null || value == undefined) return true;
          if (typeof value === "string") return (option.firstName.toLowerCase() + option.lastName.toLowerCase()).includes(value.toLowerCase());
          return (option.firstName.toLowerCase() + option.lastName.toLowerCase()).includes(value.name.toLowerCase());
        }
        )
      )
    ).subscribe(filtered => this.filteredClients$.next(filtered));
  }

  displayFn(meditation: any): string {
    return meditation && meditation.title ? meditation.title : '';
  }

  displayFnBreathingExercises(breathing: any): string {
    return breathing && breathing.title ? breathing.title : '';
  }

  displayFnClients(clientProfile: any): string {
    return clientProfile && clientProfile.firstName && clientProfile.lastName ? clientProfile.firstName + ' ' + clientProfile.lastName : '';
  }

  save() {

    if (this.homeworkForm.status === "INVALID") {
      this.isFormInvalid = true;
      this.homeworkForm.markAllAsTouched();
      this.openSnackBar({ message: "Unable to save homework, form is invalid", color: "#FF0000" });
    } else {
      const homework: Homework = this.createHomeworkFromForm();
      this.firestoreFacade.saveHomework(homework);
      this.router.navigate([`panel/client/${homework.clientId}`]);
    }
  }

  saveAndSend() {
    if (this.homeworkForm.status === "INVALID") {
      this.isFormInvalid = true;
      this.openSnackBar({ message: "Unable to save homework, form is invalid", color: "#FF0000" });
    } else {
      const homework: Homework = this.createHomeworkFromForm();
      homework.isShared = true;
      this.firestoreFacade.saveHomework(homework).then(_d => {
        this.firestoreFacade.updateHomework(homework).pipe(
          tap(data => {
            this.emailFacade.sendEmail(this.homeworkForm.get('client')?.value.email, homework)
              .subscribe(response => {
                console.log('Email sent successfully:', response);
              }, error => {
                console.error('Failed to send email:', error);
              });

          }),
          catchError(error => {
            homework.isShared = false;
            return of(null);
          })
        ).subscribe();
      });
      this.router.navigate([`panel/client/${homework.clientId}`]);
    }
  }

  edit() {

    const homework: Homework = this.createHomeworkFromForm();
    if (this.homeworkForm.status === "INVALID") {
      this.openSnackBar({ message: "Unable to update homework, form is invalid", color: "#FF0000" });
      this.isFormInvalid = true;
    } else {
      this.openSnackBar({ message: "Changes saved", color: "#FFFFFF" });
      this.firestoreFacade.updateHomework(homework);
    }
  }

  editAndSend() {

    const homework: Homework = this.createHomeworkFromForm();
    if (this.homeworkForm.status === "INVALID") {
      this.openSnackBar({ message: "Unable to update homework, form is invalid", color: "#FF0000" });
      this.isFormInvalid = true;
    } else {
      this.openSnackBar({ message: "Changes saved", color: "#FFFFFF" });
      homework.isShared = true;

      this.firestoreFacade.updateHomework(homework).pipe(
        tap(data => {
          this.emailFacade.sendEmail(this.homeworkForm.get('client')?.value.email, homework)
            .subscribe(response => {
              console.log('Email sent successfully:', response);
            }, error => {
              console.error('Failed to send email:', error);
            });

        }),
        catchError(error => {
          homework.isShared = false;
          return of(null);
        })
      ).subscribe();
      this.router.navigate([`panel/client/${homework.clientId}`]);
    }
  }

  exitEditMode() {

    this.homeworkStore.createUnassignedHomework();

    this.editModeEnabled = false;
    this.homeworkForm.controls['client'].enable();

    this.initHomeworkForm();
  }

  createHomeworkFromForm() {
    let morningExercises = this.homeworkForm.get('morningExercises')?.value;

    for (let exercise of morningExercises) {
      exercise['done'] = false;
    }

    const lastMorningExercise = this.morningExercises.controls.at(-1);
    if (lastMorningExercise?.invalid) {
      morningExercises.splice(morningExercises.length - 1, 1);
    }

    // let noonExercises = this.homeworkForm.get('noonExercises')?.value;
    // const lastNoonExercise = this.noonExercises.controls.at(-1);
    // console.log(lastNoonExercise?.invalid);
    // if (lastNoonExercise?.invalid) {
    //   noonExercises.splice(noonExercises.length-1, 1);
    // }

    // let eveningExercises = this.homeworkForm.get('eveningExercises')?.value;
    // const lastEveningExercise = this.eveningExercises.controls.at(-1);
    // console.log(lastEveningExercise?.invalid);
    // if (lastEveningExercise?.invalid) {
    //   eveningExercises.splice(eveningExercises.length-1, 1);
    // }

    const id = (!!this.homework.id && this.homework.id != '') ? this.homework.id : '';

    const homework: Homework = {
      title: this.homeworkForm.get('title')?.value,
      description: this.homeworkForm.get('description')?.value,
      deadline: new Date(Date.parse(this.homeworkForm.get('deadline')?.value)),
      clientId: this.homeworkForm.get('client')?.value.clientId,
      isShared: false,
      exercises: morningExercises,
      id: id,
      therapistId: this.therapistId
    }

    return homework;
  }

  getMinutesAndSeconds(time: number) {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    let minutesStr = minutes + '';
    if (minutes < 10) minutesStr = '0' + minutesStr;
    let secondsStr = seconds + '';
    if (seconds < 10) secondsStr = '0' + secondsStr;
    return minutesStr + ':' + secondsStr;
  }

  openSnackBar(data: { message: string, color: string }) {
    this._snackBar.openFromComponent(SaveActionStatusSnackbarComponent, {
      duration: 2 * 1000,
      data: data
    });
  }

}
