import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthStore } from '../store/auth.store';
import { AuthFacadeService } from '../facades/auth-facade.service';
import { ConfirmPasswordValidator } from 'src/app/validators/confirm-password.validator';
import { of, throwError } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  @Output() loginMode = new EventEmitter<boolean>();

  registerForm: FormGroup;
  user$ = this.authStore.user$;
  loading$ = this.authStore.loading$;
  error$ = of('');

  constructor(private authStore: AuthStore,
    private fb: FormBuilder, private authFacade: AuthFacadeService) {
    this.registerForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', [Validators.required]],
      termsAccepted: new FormControl(false)
    }, {
      validators: [ConfirmPasswordValidator('password', 'confirmPassword')]
    });
  }

  ngOnInit(): void {
  }

  onLogin() {
    this.authStore.signUp(this.registerForm.get('email')?.value, this.registerForm.get('password')?.value,
    this.registerForm.get('firstName')?.value, this.registerForm.get('lastName')?.value).then(data => {
      
    }).catch(e => {
      let prettyError: string = e.message;
      prettyError = prettyError.replace('FirebaseError:', '');
      prettyError = prettyError.replace('Firebase:', '');
      this.error$=of(prettyError);
    });
  }

  onLoginWithGoogle() {
    this.authStore.loginUserWithGoogle();
  }

  onSwitchView() {
    this.loginMode.emit(true);
  }

}
